import { NumberInput } from "@mantine/core"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setAnticipo } from "../../../features/preventivo/preventivoSlice"

const InputAnticipo = ({ isReadOnly }: { isReadOnly: boolean }) => {
  const dispatch = useAppDispatch()
  const anticipoValue = useAppSelector((state) => state.preventivo.anticipo)

  const handleInputChange = (value: string | number) => {
    dispatch(setAnticipo(+value))
  }

  return (
    <NumberInput
      min={0}
      value={anticipoValue}
      label="Anticipo"
      onChange={(value) => handleInputChange(value)}
      allowNegative={false}
      decimalScale={2}
      placeholder="Inserisci l'anticipo"
      rightSection={<span></span>}
      thousandSeparator="."
      decimalSeparator=","
      disabled={isReadOnly}
      className="mb-5"
      styles={{
        input: {
          width: "100%",
          height: 44,
          borderRadius: 12,
        },
      }}
    />
  )
}

export default InputAnticipo
