import { Select } from "@mantine/core"
import { IconCircleCheckFilled } from "@tabler/icons-react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  selectTemperatura,
  setTemperatura,
} from "../../../features/preventivo/preventivoSlice"

const SelectTemperatura = () => {
  const dispatch = useAppDispatch()
  const temperaturaArray = ["Fredda", "Tiepida", "Calda"]
  const defaultTemperatura = useAppSelector(selectTemperatura)

  return (
    <Select
      label="Temperatura"
      placeholder="Seleziona la temperatura"
      value={defaultTemperatura}
      data={temperaturaArray || []}
      onChange={(sel) => {
        sel && dispatch(setTemperatura(sel))
      }}
      rightSection={
        defaultTemperatura && (
          <IconCircleCheckFilled size={18} className="text-cm-blue" />
        )
      }
      radius={8}
      styles={{
        input: { height: 44 },
      }}
    />
  )
}

export default SelectTemperatura
