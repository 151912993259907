import { Container, Textarea } from "@mantine/core"

const AdminMargini = () => {
  return (
    <>
      <Container size="100%" h={76} className="bg-cm-bg"></Container>
      <Container size="100%" className="bg-white px-10 py-5">
        <Textarea
          label="Inserisci un nuovo margine fisso per finanziamento Ford"
          placeholder="Es: 100 : Nome margine fisso, 500 : Altro margine fisso"
          autosize
          minRows={3}
          mb={32}
        />
        <Textarea
          label="Inserisci un nuovo margine fisso per premio permuta"
          placeholder="Es: 100 : Nome margine fisso, 500 : Altro margine fisso"
          autosize
          minRows={3}
        />
      </Container>
    </>
  )
}

export default AdminMargini
