import OptionPrice from "./OptionPrice";
import OptionDelete from "./OptionDelete";
import OptionDescriptionEdit from "./OptionDescriptionEdit";
import { List } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useState } from "react";
import { removeOption } from "../../features/preventivo/preventivoSlice";
import { useAppDispatch } from "../../app/hooks";
import { deleteModalProps } from "../Modal/ModalDelete/ModalDelete";
import { EOptionType, Option } from "../../queries/veicoli";

export interface OptionRowProps {
  type: EOptionType;
  items: Option[];
  isReadOnly?: boolean;
  isOptionReadOnly?: boolean;
  isAftermarket?: boolean;
}

const OptionRow = ({
  items,
  isReadOnly,
  isOptionReadOnly,
  isAftermarket,
}: OptionRowProps) => {
  const dispatch = useAppDispatch();
  const [isInputShown, setIsInputShown] = useState<{
    id: string | null;
    state: boolean;
  }>({
    id: null,
    state: false,
  });

  const handleEditClick = (id: string) => {
    setIsInputShown((prevState) => ({
      id,
      state: prevState.id === id ? !prevState.state : true,
    }));
  };

  const handleConfirmClick = () => {
    setIsInputShown({
      id: null,
      state: false,
    });
  };

  const handleDeleteOption = (id: string) => {
    const optionToDelete = items?.find((opt) => opt.id === id);

    modals.openConfirmModal({
      ...deleteModalProps("elemento", "elementi", [""]),
      onConfirm: () => {
        optionToDelete &&
          dispatch(
            removeOption({
              ...optionToDelete,
            }),
          );
      },
    });
  };

  return (
    <>
      {items?.map((optional, index) => (
        <List.Item
          key={`${optional._id}-${index}`}
          className="relative mb-4 flex items-start justify-between border-b pb-2"
        >
          <span>{optional.label}</span>
          <div className="absolute right-0 top-0 flex items-center gap-1">
            <OptionPrice
              isInputShown={isInputShown}
              optional={optional}
              isOptionReadOnly={isOptionReadOnly}
              onEditPrice={handleEditClick}
              onConfirmPrice={handleConfirmClick}
            />
            {optional.obbligatorio === "false" && !isReadOnly && (
              <OptionDelete
                onOptionDelete={() => handleDeleteOption(optional.id)}
              />
            )}
          </div>
          {isAftermarket && (
            <OptionDescriptionEdit
              isOptionReadOnly={isOptionReadOnly}
              optional={optional}
            />
          )}
        </List.Item>
      ))}
    </>
  );
};

export default OptionRow;
