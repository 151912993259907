import { useGetAnagraficheQuery } from "../features/api/apiSlice";

const useGetCurrentCustomer = ({ customerId }: { customerId?: string }) => {
  const { data, isLoading } = useGetAnagraficheQuery({ limit: 50 });
  let currentCustomer;

  currentCustomer = data?.anagraficas.find(
    (customer) => customer._id === customerId,
  );

  return { currentCustomer, isLoading };
};

export default useGetCurrentCustomer;
