//@ts-nocheck
import React from "react"
import stringTemplates from "../config/stringTemplates"
import { ComboboxItem, OptionsFilter } from "@mantine/core"
import { Label } from "recharts"
import { TS4Display } from "./formatters"
import { Notification } from "../queries/notification"
import { EScontoType, Preventivo, PrintOptions } from "../queries/preventivi"
import { EOptionType, Modello, Option, Veicolo } from "../queries/veicoli"

export const findModelById = (
  id: string | undefined,
  modelliList: Modello[],
) => {
  const model = modelliList.find((model) => model.value === id)
  return model?.label
}

export const transformTimestampToLocaleDateAndTime = (
  timestamp: number,
  withTime = true,
  isCompact = false,
  isMonthCompact = true,
) => {
  const _date = new Date(TS4Display(+timestamp))
  const date = _date.toLocaleDateString("it-IT", {
    day: "2-digit",
    month: isMonthCompact ? "long" : "short",
    year: isCompact ? undefined : "numeric",
  })
  const time = withTime
    ? _date.toLocaleTimeString("it-IT", {
        hour: "2-digit",
        minute: "2-digit",
      })
    : ""
  return `${date} ${time}`
}

export const getColorFromTemperature = (
  temperature: "Fredda" | "Tiepida" | "Calda",
): string | undefined => {
  const temperatureColorMap: {
    [key in "Fredda" | "Tiepida" | "Calda"]: string | undefined
  } = {
    Fredda: "bg-cold",
    Tiepida: "bg-warm",
    Calda: "bg-hot",
  }
  return temperatureColorMap[temperature]
}

export const getLast30DaysPreventivi = (
  preventivi: Preventivo[] | undefined,
): number | undefined => {
  const today = new Date()
  const _30DaysAgo = today.setDate(today.getDate() - 30)
  const _30DaysArray = preventivi?.filter(
    (preventivo) => preventivo.started_at <= _30DaysAgo,
  )
  return _30DaysArray?.length
}

export const getNotificationPriority = (notification: Notification): string => {
  const currentTime = new Date().getTime()
  const alertTime = notification.alert_at * 1000

  if (notification.dismissed_at !== null) {
    return "text-slate-400"
  }

  if (currentTime > alertTime && notification.dismissed_at === null) {
    return "text-hot"
  }

  if (alertTime - currentTime < 60 * 60 * 1000) {
    return "text-warm"
  }

  return "text-[#228be6]"
}

export const optionsFilter: OptionsFilter = ({ options, search }) => {
  const splittedSearch = search.toLowerCase().trim().split(" ")
  return (options as ComboboxItem[]).filter((option) => {
    const words = option.label.toLowerCase().trim().split(" ")
    return splittedSearch.every((searchWord) =>
      words.some((word) => word.includes(searchWord)),
    )
  })
}

export const convertDiscountType = (input: string): string => {
  const enumKey = Object.keys(EScontoType).find(
    (key) => EScontoType[key as keyof typeof EScontoType] === input,
  )
  if (enumKey) {
    const description = enumKey.replace(/_/g, " ").toLowerCase()
    return description.charAt(0).toUpperCase() + description.slice(1)
  } else {
    return "Tipo sconto non valido"
  }
}

export const decodeTesti = (testiEncoded: string): string => {
  try {
    const retval = decodeURI(atob(testiEncoded))
    return retval
  } catch (error) {
    console.error("Error decoding testi", error)
    return ""
  }
}

export const estraiContenutoParentesi = (
  carattereParentesi: "{" | "[",
  str: string,
): string[] | false => {
  // Cerca tutte le stringhe all'interno delle parentesi graffe
  const match: RegExpMatchArray | null =
    carattereParentesi === "{"
      ? str.match(/{([^}]*)}/g)
      : str.match(/\[([^\]]*)\]/g)

  // Se non ci sono parentesi graffe, restituisce false
  if (!match) {
    return false
  }

  // Rimuove le graffe dai risultati e restituisce solo il contenuto
  return match.map((s: string) => s.slice(1, -1))
}

function extractTestoWithLabel(testi: any, label: string): string {
  return testi.find((testo: any) => testo.label === label)?.testo || null
}

export const getTemplateString = (
  key: string,
  veicolo: Veicolo | undefined,
  strings: any,
): string => {
  //@ts-ignore
  let template: string =
    extractTestoWithLabel(strings, key) ||
    veicolo?.[key.toLowerCase()] ||
    `<strong>VALORE_MANCANTE_${key}</strong>`
  let variables_graffe = estraiContenutoParentesi("{", template)

  if (variables_graffe) {
    variables_graffe.forEach((variable: string) => {
      const value =
        veicolo?.[variable.toLowerCase()] ||
        `<strong>VALORE_MANCANTE_${variable}</strong>`
      template = template.replace(`{${variable}}`, value)
    })
  }

  let variables_quadre = estraiContenutoParentesi("[", template)

  if (variables_quadre) {
    variables_quadre.forEach((variable: string) => {
      //@ts-ignore
      const value =
        extractTestoWithLabel(strings, variable) ||
        `<strong>VALORE_MANCANTE_${variable}</strong>`
      template = template.replace(`[${variable}]`, value)
    })
  }

  variables_graffe = estraiContenutoParentesi("{", template)

  if (variables_graffe) {
    //@ts-ignore
    variables_graffe.forEach((variable: string) => {
      //@ts-ignore
      const value =
        veicolo?.[variable.toLowerCase()] ||
        `<strong>VALORE_MANCANTE_${variable}</strong>`
      template = template.replace(`{${variable}}`, value)
    })
  }

  variables_quadre = estraiContenutoParentesi("[", template)

  if (variables_quadre) {
    variables_quadre.forEach((variable: string) => {
      //@ts-ignore
      const value =
        strings[variable] || `<strong>VALORE_MANCANTE_${variable}</strong>`
      template = template.replace(`[${variable}]`, value)
    })
  }

  return template
}

export const encodePreventivoString = (input: string): string => {
  const encoded = btoa(encodeURI(input))
  return encoded
}

export const decodePreventivoString = (input: string): string => {
  try {
    let decoded = decodeURI(atob(input))
    decoded = decoded.replace(/<\/?p>/g, "")
    return decoded
  } catch (error) {
    console.log("Error decoding preventivo string", error)
    return input
  }
}

export const cleanPreventivoString = (input: string): string => {
  // Rimuove apici e virgolette
  input = input.replace(/['"]/g, "")

  // Rimuove tabulazioni e ritorni a capo
  input = input.replace(/[\t\r\n]+/g, " ")

  // Rimuove spazi multipli
  input = input.replace(/\s+/g, " ")

  // Divide il testo in sezioni basate su " - "
  let sections = input.split(" - ")
  let output = "<ul>"

  for (let i = 0; i < sections.length; i++) {
    if (i === 0 && !sections[i].trim()) continue // Salta la prima sezione se è vuota

    // Rimuove eventuali trattini rimanenti all'interno della sezione
    let cleanedSection = sections[i].replace(/-/g, "").trim()

    // Rimuove la virgola finale se presente
    cleanedSection = cleanedSection.replace(/,\s*$/, "")

    if (cleanedSection) {
      output += "<li>" + cleanedSection + "</li>"
    }
  }

  output += "</ul>"

  // Rimuove <ul></ul> se non ci sono elementi di lista
  if (output === "<ul></ul>") {
    output = input.trim()
  }

  return output
}

export const cleanAndEncodePreventivoString = (input: string): string => {
  let output = cleanPreventivoString(input)
  return encodePreventivoString(output)
}

//TODO: mettere questo filtro sul selector in apiSlice
export const optionalsUnique = (srcArray) => {
  const idSet = new Set() // Insieme per tracciare gli ID univoci
  const filteredArray = []

  for (const oggetto of srcArray) {
    if (!idSet.has(oggetto.id)) {
      idSet.add(oggetto.id) // Aggiungi l'ID all'insieme
      filteredArray.push(oggetto) // Aggiungi l'oggetto all'array filtrato
    }
  }

  return filteredArray // Ritorna l'array senza duplicati
}

export const getPrintOptionsHeading = (
  label: string,
  value: any,
  option: Option[],
  print_type: OPTIONS_PRINT_TYPE,
  isAftermarket?: boolean,
): string => {
  if (option.length === 0) return ""

  const optionMap = !isAftermarket
    ? option
        .map((opt: Option) =>
          print_type === "single_price"
            ? `${opt.label} (€ ${opt.price})`
            : opt.label,
        )
        .join(", ")
    : option
        .map((opt: Option) =>
          print_type === "single_price"
            ? `${opt.description} (€ ${opt.price})\n`
            : `${opt.description}\n`,
        )
        .join("")

  let optionValue = ""
  if (value && print_type === "total_price") {
    optionValue = `€ ${value[0].toLocaleString()}`
  }

  const returnedString = `${label}: ${optionValue}\n${optionMap}`
  return returnedString
}

export const changeColorByMarginSign = (margin: number): string => {
  const className =
    margin === 0
      ? "bg-slate-200"
      : Math.sign(margin) === -1
        ? "bg-error text-white"
        : "bg-cm-green text-white"

  return className
}
