import {
  Input,
  MultiSelect,
  NativeSelect,
  TextInput,
  Textarea,
} from "@mantine/core";
import { usePutAnagraficaMutation } from "../../features/api/apiSlice";
import { Controller, useForm } from "react-hook-form";
import ModalLayout from "../Modal/ModalLayout/ModalLayout";
import { notifications } from "@mantine/notifications";
import { Anagrafica } from "../../queries/anagrafica";
import { modals } from "@mantine/modals";
import { IconAddressBook } from "@tabler/icons-react";

export interface CustomerFormProps {
  onCloseClick: () => void;
  currentModalRecord?: Anagrafica;
  currentUserName?: string | undefined;
}

const CustomerForm = ({
  onCloseClick,
  currentModalRecord,
  currentUserName,
}: CustomerFormProps) => {
  const [putAnagrafica, result] = usePutAnagraficaMutation();

  const typeData = [
    { value: "P", label: "Persona" },
    { value: "A", label: "Azienda" },
  ];

  const referentsData = [
    { value: "react", label: "React" },
    { value: "ng", label: "Angular" },
    { value: "svelte", label: "Svelte" },
    { value: "vue", label: "Vue" },
    { value: "riot", label: "Riot" },
    { value: "next", label: "Next.js" },
    { value: "blitz", label: "Blitz.js" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm();

  const onSubmit = (data: any) => {
    const payload = { ...data };
    putAnagrafica(payload)
      .unwrap()
      .then((payload) => {
        console.log("fulfilled", payload);
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          position: "top-center",
          title: "Successo!",
          radius: "md",
          message: !currentModalRecord
            ? "Cliente creato con successo."
            : "Cliente modificato con successo.",
          color: "teal",
          loading: false,
        });
        modals.closeAll();
      })
      .catch((error: Error) => {
        console.log("rejected", error);
        notifications.show({
          withCloseButton: true,
          position: "top-center",
          title: "Errore",
          radius: "md",
          message: !currentModalRecord
            ? "Errore nel salvataggio del cliente. Riprova."
            : "Errore nella modifica del cliente. Riprova.",
          color: "pink",
          loading: false,
        });
      });
  };

  const modalTitle = currentModalRecord ? "Modifica cliente" : "Nuovo cliente";

  return (
    <ModalLayout
      title={
        <div className="item-center flex gap-1 font-medium">
          <IconAddressBook />
          {modalTitle}
        </div>
      }
      currentUserName={currentUserName}
      onCloseClick={onCloseClick}
      onConfirmClick={handleSubmit(onSubmit)}
    >
      <form>
        {currentModalRecord && (
          <TextInput
            {...register("_id")}
            placeholder="ID"
            className="mb-2 w-full"
            value={currentModalRecord ? currentModalRecord._id : ""}
            readOnly
          />
        )}
        <div className="mb-5 flex gap-1">
          <TextInput
            {...register("idAnagrafica")}
            placeholder="ID Anagrafica"
            className="w-full"
            defaultValue={
              currentModalRecord ? currentModalRecord.idAnagrafica : ""
            }
          />
          <TextInput
            {...register("codMaster")}
            placeholder="Codice Master"
            className="w-full"
            defaultValue={
              currentModalRecord ? currentModalRecord.codMaster : ""
            }
          />
        </div>
        <div className="mb-2 flex items-end gap-1">
          <TextInput
            {...register("nome", { required: false })}
            defaultValue={currentModalRecord ? currentModalRecord.nome : ""}
            placeholder="Nome"
            className="w-full"
          />
          <NativeSelect
            {...register("tipologia", { required: false })}
            data={typeData}
            defaultValue={
              currentModalRecord ? currentModalRecord.tipologia : "P"
            }
            withAsterisk
            className="w-full"
          />
        </div>
        <Input
          {...register("email", { required: false })}
          type="email"
          defaultValue={currentModalRecord ? currentModalRecord.email : ""}
          placeholder="Email"
          className="mb-2"
        />
        <Input
          {...register("pec", { required: false })}
          defaultValue={currentModalRecord ? currentModalRecord.pec : ""}
          type="email"
          placeholder="PEC"
          className="mb-2"
        />
        <div className="mb-2 flex gap-1">
          <TextInput
            {...register("cf", { required: false })}
            defaultValue={currentModalRecord ? currentModalRecord.cf : ""}
            placeholder="Codice Fiscale"
            className="w-full"
          />
          <TextInput
            {...register("piva", { required: false })}
            defaultValue={currentModalRecord ? currentModalRecord.piva : ""}
            placeholder="P.IVA"
            className="w-full"
          />
        </div>
        <TextInput
          {...register("tel", { required: false })}
          placeholder="Telefono"
          defaultValue={currentModalRecord ? currentModalRecord.tel : ""}
          className="mb-5"
        />
        <div className="mb-2 flex gap-1">
          <TextInput
            {...register("indirizzo", { required: false })}
            defaultValue={
              currentModalRecord ? currentModalRecord.indirizzo : ""
            }
            placeholder="indirizzo"
            className="w-1/2"
          />
          <TextInput
            {...register("civico", { required: false })}
            defaultValue={currentModalRecord ? currentModalRecord.civico : ""}
            placeholder="Civico"
            className="w-[25%]"
          />
          <TextInput
            {...register("cap", { required: false })}
            defaultValue={currentModalRecord ? currentModalRecord.cap : ""}
            placeholder="CAP"
            className="w-[25%]"
          />
        </div>
        <div className="mb-2 flex gap-1">
          <TextInput
            {...register("localita", { required: false })}
            defaultValue={currentModalRecord ? currentModalRecord.localita : ""}
            placeholder="Località"
            className="w-[75%]"
          />
          <TextInput
            {...register("prov", { required: false })}
            defaultValue={currentModalRecord ? currentModalRecord.prov : "BS"}
            placeholder="Provincia"
            className="w-[25%]"
          />
        </div>
        <div className="mb-5 flex items-end gap-1">
          <TextInput
            {...register("nazione", { required: false })}
            placeholder="Nazione"
            className="w-full"
            defaultValue={
              currentModalRecord ? currentModalRecord.nazione : "IT"
            }
          />
          <NativeSelect
            {...register("valuta", { required: false })}
            data={["EUR"]}
            defaultValue={
              currentModalRecord ? currentModalRecord.valuta : "EUR"
            }
            withAsterisk
            className="w-full"
          />
        </div>
        {
          //@ts-ignore
          <Controller
            name="referenti"
            control={control}
            render={({ field }) => (
              <MultiSelect
                {...field}
                data={referentsData}
                placeholder="Referenti"
                className="mb-2"
                searchable
                //nothingFound="Nessun risultato"
                defaultValue={[]}
              />
            )}
          />
        }
        <Textarea
          {...register("note")}
          placeholder="Note"
          name="note"
          className="mb-6"
          defaultValue={"Nota di test"}
        />
      </form>
    </ModalLayout>
  );
};

export default CustomerForm;
