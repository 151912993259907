import { Veicolo } from "../../../../queries/veicoli"
import { Button, Loader } from "@mantine/core"
import { IconDeviceFloppy } from "@tabler/icons-react"

export interface PreventivoSaveButtonProps {
  saveLabel: string
  isSaveLoading: boolean
  isSavingEnabled: boolean
  firstStepCompleted?: "" | Veicolo | undefined
  handleSavePreventivo: () => void
}

const PreventivoSaveButton = ({
  saveLabel,
  isSaveLoading,
  isSavingEnabled,
  handleSavePreventivo,
}: PreventivoSaveButtonProps) => {
  return (
    <Button
      radius={50}
      variant="filled"
      leftSection={
        isSaveLoading ? <Loader color="blue" size="xs" /> : <IconDeviceFloppy />
      }
      disabled={!isSavingEnabled}
      onClick={handleSavePreventivo}
    >
      {saveLabel}
    </Button>
  )
}

export default PreventivoSaveButton
