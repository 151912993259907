import { useMemo } from "react"
import {
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { Button, Tooltip } from "@mantine/core"
import {
  IconAlertCircleFilled,
  IconArrowBack,
  IconCircleCheck,
  IconCopy,
  IconEye,
} from "@tabler/icons-react"
import { MRT_Localization_IT } from "mantine-react-table/locales/it/index.esm.mjs"
import { Link, useLocation, useParams } from "react-router-dom"
import { modals } from "@mantine/modals"
import {
  useGetAnagraficheQuery,
  usePutNotificationMutation,
} from "../../features/api/apiSlice"
import {
  getNotificationPriority,
  transformTimestampToLocaleDateAndTime,
} from "../../utils/utils"
import { Notification } from "../../queries/notification"
import { notifications } from "@mantine/notifications"
import useGetNotifications from "../../hooks/useGetNotifications"
import NotificationForm from "../Form/NotificationForm"
import useAuthUserData from "../../hooks/useAuthUserData"
import { TS4Display } from "../../utils/formatters"

const NotificationsTable = () => {
  const location = useLocation()
  const { customerId } = useParams()
  const [putNotification] = usePutNotificationMutation()
  const isActiveNotifcation = location.pathname === "/notifiche"
  const { authUser, authUserId, isAdmin } = useAuthUserData()
  const {
    isLoading,
    activeNotifications,
    dismissedNotifications,
    filteredCustomerActiveNotifications,
  } = useGetNotifications({
    idAgente: authUserId,
    idCliente: customerId,
  })
  const notificationsToDisplay = isActiveNotifcation
    ? activeNotifications
    : customerId
      ? filteredCustomerActiveNotifications
      : dismissedNotifications

  const handleComplete = (notification: Notification) => {
    const isDismissed = notification.dismissed_at
    putNotification({
      ...notification,
      dismissed_at: isDismissed ? 0 : Date.now(),
    })
      .unwrap()
      .then(() => {
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: "Successo!",
          radius: "md",
          message: "Notifica aggiornata.",
          color: "teal",
          loading: false,
        })
      })
      .catch((error: Error) => {
        console.error(error)
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: "Successo!",
          radius: "md",
          message: "Errore nell'aggiornamento della notifica. Riprova.",
          color: "pink",
          loading: false,
        })
      })
  }

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        id: "priority",
        header: "",
        enableColumnFilterModes: false,
        enableHiding: false,
        size: 30,
        Cell: ({ row }: { row: MRT_Row<Notification> }) => (
          <IconAlertCircleFilled
            className={`h-5 w-5 ${getNotificationPriority(row.original)}`}
          />
        ),
      },
      {
        accessorFn: (row: Notification) => {
          let date = new Date(TS4Display(+row?.alert_at))
          return date
        },
        id: "alert_at",
        accessorKey: "alert_at",
        header: "Data",
        filterVariant: "date-range",
        enableColumnFilterModes: false,
        enableHiding: false,
        size: 50,
        Cell: ({ row }: { row: MRT_Row<Notification> }) => (
          <p>
            {transformTimestampToLocaleDateAndTime(
              row.original?.alert_at,
              true,
              true,
            )}
          </p>
        ),
      },
      {
        accessorKey: "text",
        header: "Testo",
        size: 250,
        enableHiding: false,
      },
      {
        accessorKey: "id_preventivo",
        header: "Preventivo",
        size: 80,
        enableHiding: false,
        Cell: ({ row }: { row: MRT_Row<Notification> }) => (
          <Tooltip label={row.original.id_preventivo}>
            <Link to={`/preventivi/visualizza/${row.original.id_preventivo}`}>
              {row.original.id_preventivo !== "null"
                ? `${row.original.id_preventivo?.substring(0, 8)}...`
                : ""}
            </Link>
          </Tooltip>
        ),
      },
      {
        accessorKey: "nome_cliente",
        header: "Cliente",
        size: 80,
        enableHiding: false,
        Cell: ({
          row,
          renderedCellValue,
        }: {
          row: MRT_Row<Notification>
          renderedCellValue: string
        }) => (
          <Link to={`/clienti/${row.original.id_cliente}/panoramica`}>
            {renderedCellValue}
          </Link>
        ),
      },
      {
        accessorKey: "status",
        header: "",
        size: 140,
        enableHiding: false,
        enableSorting: false,
        Cell: ({ row }: { row: MRT_Row<Notification> }) => (
          <Button
            variant="outline"
            radius={32}
            leftSection={
              !row.original.dismissed_at ? (
                <IconCircleCheck size={22} />
              ) : (
                <IconArrowBack size={22} />
              )
            }
            onClick={() => handleComplete(row.original)}
          >
            {!row.original.dismissed_at ? "Completa" : "Riattiva"}
          </Button>
        ),
      },
    ]

    if (isAdmin) {
      defaultColumns.splice(1, 0, {
        accessorKey: "nome_agente_from",
        header: "Creata da",
        enableHiding: false,
        size: 100,
      })
    }

    return defaultColumns
  }, [isAdmin])

  const table = useMantineReactTable({
    //@ts-ignore
    columns,
    data: notificationsToDisplay || [],
    createDisplayMode: "modal",
    editDisplayMode: "row",
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnPinning: true,
    enableRowActions: true,
    positionActionsColumn: "last",
    enableRowSelection: true,
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    globalFilterFn: "contains",
    enableFilterMatchHighlighting: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableFacetedValues: true,
    enableColumnResizing: false,
    enableStickyHeader: true,
    enableGlobalFilterModes: true,
    localization: MRT_Localization_IT,
    state: {
      isLoading: isLoading,
      density: "xs",
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    mantineSearchTextInputProps: {
      placeholder: "Cerca...",
      variant: "filled",
    },
    mantineTableHeadCellProps: {
      bg: "rgb(226,232,240,1)",
      frameBorder: "none",
    },
    mantinePaperProps: {
      withBorder: false,
      shadow: "none",
      radius: "md",
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "",
      },
    },
    renderDetailPanel: ({ row }) => <p>{row.original.text}</p>,
    renderRowActionMenuItems: ({ row }) => (
      <div className="flex items-center gap-3">
        <Tooltip label="Visualizza" position="bottom">
          <Button
            variant="transparent"
            className="flex h-8 w-8 items-center justify-center rounded-full p-0 hover:bg-slate-100"
            onClick={() => {
              modals.open({
                padding: 0,
                withCloseButton: false,
                size: "70%",
                radius: 26,
                centered: true,
                children: (
                  <NotificationForm
                    currentNotification={row.original}
                    authUser={authUser}
                  />
                ),
              })
            }}
          >
            <IconEye className="h-5 w-5" />
          </Button>
        </Tooltip>
        <Tooltip label="Duplica" position="bottom">
          <Button
            variant="transparent"
            className="flex h-8 w-8 items-center justify-center rounded-full p-0 hover:bg-slate-100"
            onClick={() => {
              modals.open({
                padding: 0,
                withCloseButton: false,
                size: "70%",
                radius: 26,
                centered: true,
                children: (
                  <NotificationForm
                    currentNotification={row.original}
                    authUser={authUser}
                    duplicate
                  />
                ),
              })
            }}
          >
            <IconCopy className="h-5 w-5" />
          </Button>
        </Tooltip>
        <Tooltip label="Completa" position="bottom">
          <Button
            variant="transparent"
            className="flex h-8 w-8 items-center justify-center rounded-full p-0 hover:bg-slate-100"
            onClick={() => handleComplete(row.original)}
          >
            <IconCircleCheck className="h-5 w-5" />
          </Button>
        </Tooltip>
      </div>
    ),
    renderTopToolbar: ({ table }) => {
      const isOneSelected =
        Object.keys(table.getState().rowSelection).length > 0
      const isManySelected =
        Object.keys(table.getState().rowSelection).length > 1

      const handleEdit = (duplicate: boolean) => {
        table.getSelectedRowModel().flatRows.map((row) => {
          modals.open({
            padding: 0,
            withCloseButton: false,
            size: "70%",
            radius: 26,
            centered: true,
            children: (
              <NotificationForm
                currentNotification={row.original}
                authUser={authUser}
                duplicate={duplicate}
              />
            ),
          })
        })
      }

      return (
        <div className="flex items-center justify-between bg-slate-200 p-4">
          {isOneSelected && (
            <div className="flex items-center gap-5">
              {!isManySelected && (
                <>
                  <Button
                    rightSection={<IconEye size={18} />}
                    onClick={() => handleEdit(false)}
                  >
                    Visualizza
                  </Button>

                  <Button
                    rightSection={<IconCopy size={18} />}
                    onClick={() => handleEdit(true)}
                  >
                    Duplica
                  </Button>
                </>
              )}
              <Button
                rightSection={<IconCircleCheck size={18} />}
                onClick={() =>
                  handleComplete(
                    table
                      .getSelectedRowModel()
                      .flatRows.map((row) => row.original)[0],
                  )
                }
              >
                Completa
              </Button>
            </div>
          )}
          <div className="ml-auto flex items-center gap-2">
            <div className="has-global-search flex items-center gap-2">
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToggleGlobalFilterButton table={table} />
            </div>
            <MRT_ToggleFiltersButton table={table} />
          </div>
        </div>
      )
    },
  })

  return <MantineReactTable table={table} />
}

export default NotificationsTable
