import { IconAlertSquareRoundedFilled } from "@tabler/icons-react"

type PreventivoAlertProps = {
  type: "error" | "warn"
  text: string
}

const PreventivoAlert = ({ type, text }: PreventivoAlertProps) => {
  const className = type === "error" ? "text-[#e64980]" : "text-warm"
  return (
    <div className="px-3 rounded-xl py-1 mb-3 border">
      <p className="font-light text-sm flex items-center gap-2">
        <IconAlertSquareRoundedFilled className={className} />
        {text}
      </p>
    </div>
  )
}

export default PreventivoAlert
