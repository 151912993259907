import * as Realm from "realm-web"
import Logo from "../Logo/Logo"
import useSignIn from "react-auth-kit/hooks/useSignIn"
import AuthWrapper from "../../Layout/AuthWrapper/AuthWrapper"
import { TextInput, PasswordInput, Button } from "@mantine/core"
import { useApp } from "../RealmApp"
import { useNavigate } from "react-router"
import { useState } from "react"
import { Link } from "react-router-dom"

export default function AuthForm() {
  const app = useApp()
  const signIn = useSignIn()
  const navigate = useNavigate()
  const [userName, setUserName] = useState("supporto@keyformat.com")
  const [password, setPassword] = useState("keyformat")
  const [error, setError] = useState("")

  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const credentials = Realm.Credentials.emailPassword(userName, password)

    try {
      // @ts-ignore
      const user = await app.logIn(credentials)

      if (
        signIn({
          auth: {
            token: user.accessToken,
            type: "Bearer",
          },
          refresh: user.refreshToken,
          userState: {
            ...user.customData,
          },
        })
      ) {
        setTimeout(() => {
          navigate("/")
        }, 100)
      } else {
        setError("Login fallito: qualcosa è andato storto")
        console.log("login failed")
      }
    } catch (err) {
      setError("Login fallito: dati mancanti o errati")
      console.error("Failed to log in", err)
    }
  }

  return (
    <AuthWrapper>
      <div className="mb-6 flex justify-center">
        <Logo isIconOnlyVersion={true} />
      </div>
      <TextInput
        label="Email"
        placeholder="you@mantine.dev"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        required
        radius={12}
        styles={{ input: { height: 44 } }}
      />
      <PasswordInput
        label="Password"
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Your password"
        value={password}
        required
        mt="md"
        radius={12}
        styles={{ input: { height: 44 } }}
      />
      <div className="mt-6 flex items-end justify-between">
        <Link to="/resetpassword" className="block w-full text-xs underline">
          Hai dimenticato la password?
        </Link>

        <Button className="w-20 rounded-full p-0" onClick={handleLogin}>
          Login
        </Button>
      </div>
    </AuthWrapper>
  )
}
