interface WidgetListProps {
  children: React.ReactNode;
  className?: string;
}

const WidgetList = ({ children, className }: WidgetListProps) => {
  return (
    <div
      className={`mb-2 flex w-full items-center text-sm gap-3 border-b border-slate-200 py-1 ${className}`}
    >
      {children}
    </div>
  );
};

export default WidgetList;
