import ModalLayout from "../Modal/ModalLayout/ModalLayout"
import TextStyle from "@tiptap/extension-text-style"
import StarterKit from "@tiptap/starter-kit"
import { TextInput } from "@mantine/core"
import { modals } from "@mantine/modals"
import { useForm } from "react-hook-form"
import { Testo } from "../../queries/testi"
import { usePutTestoMutation } from "../../features/api/apiSlice"
import { notifications } from "@mantine/notifications"
import { RichTextEditor } from "@mantine/tiptap"
import { useEditor } from "@tiptap/react"
import { Color } from "@tiptap/extension-color"
import { useState } from "react"
import { decodePreventivoString, encodePreventivoString } from "../../utils/utils"

const VariabileForm = ({
  currentModalRecord,
  currentUserName,
  onCloseClick,
}: {
  currentModalRecord?: any
  currentUserName?: string | undefined
  onCloseClick: () => void
}) => {
  const [putTesto] = usePutTestoMutation()
  const { register, handleSubmit } = useForm()
  const [editorText, setEditorText] = useState(
    currentModalRecord ? decodePreventivoString(currentModalRecord.testo) : "",
  )

  const onSubmit = (data: any) => {
    const variabile: Testo = {
      _id: currentModalRecord?._id,
      label: data.label,
      testo: encodePreventivoString(editorText),
    }

    putTesto(variabile)
      .unwrap()
      .then((payload) => {
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: "Successo!",
          radius: "md",
          message: !currentModalRecord
            ? "Variabile creata."
            : "Variabile modificata.",
          color: "teal",
          loading: false,
        })
        modals.closeAll()
      })
      .catch((error: Error) => {
        console.error(error)
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: "Successo!",
          radius: "md",
          message: !currentModalRecord
            ? "Errore nella creazione della variabile. Riprova."
            : "Errore nella modifica della variabile. Riprova.",
          color: "pink",
          loading: false,
        })
      })
    modals.closeAll()
  }

  const modalTitle = currentModalRecord
    ? "Modifica variabile"
    : "Crea nuova variabile"

  const editor = useEditor({
    extensions: [StarterKit, TextStyle, Color],
    content: editorText,
    onUpdate: ({ editor }) => {
      setEditorText(editor.getHTML())
    },
  })

  return (
    <ModalLayout
      title={modalTitle}
      currentUserName={currentUserName}
      onCloseClick={onCloseClick}
      onConfirmClick={handleSubmit(onSubmit)}
    >
      <form>
        <div className="mb-5 flex flex-col gap-2">
          <TextInput
            {...register("label", { required: true })}
            label={"Label"}
            defaultValue={currentModalRecord ? currentModalRecord.label : ""}
            className="w-full"
          />
          <div>
            <span className="text-sm font-medium">Testo</span>
            <RichTextEditor editor={editor}>
              <RichTextEditor.Toolbar sticky stickyOffset={60}>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Bold />
                  <RichTextEditor.Italic />
                </RichTextEditor.ControlsGroup>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Color color="#000000" />
                  <RichTextEditor.Color color="#0b5394" />
                  <RichTextEditor.Color color="#3d63c6" />
                  <RichTextEditor.Color color="#38761d" />
                  <RichTextEditor.Color color="#ea4335" />
                  <RichTextEditor.Color color="#ffff00" />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.UnsetColor />
              </RichTextEditor.Toolbar>

              <RichTextEditor.Content />
            </RichTextEditor>
          </div>
        </div>
      </form>
    </ModalLayout>
  )
}

export default VariabileForm
