import DetailRow from "./DetailRow/DetailRow";
import { Button } from "@mantine/core";
import { useState } from "react";
import { EScontoType } from "../../../../queries/preventivi";
import { useAppSelector } from "../../../../app/hooks";
import { DetailRowObject } from "./DetailRow/DetailRowTypes";
import { IconChevronDown } from "@tabler/icons-react";
import { changeColorByMarginSign } from "../../../../utils/utils";
import {
  selectAccessoriValues,
  selectOptionalsValues,
  selectMargineVeicolo,
  selectPriceFinal,
  selectSconti,
  selectServiziValues,
  selectTotalBeforeDiscount,
  selectTotaleVeicolo,
  selectVeicolo,
  selectMargineTotal,
  selectIncentiviValues,
  selectTotalPermute,
  selectPermuteIvaEsente,
  selectPermuteIvaEsclusa,
  selectIvaValue,
  selectTotLordo,
  selectImponibile,
} from "../../../../features/preventivo/preventivoSlice";

const TotalDetails = () => {
  const [showDetailPanel, setShowDetailPanel] = useState(false);
  const veicolo = useAppSelector(selectVeicolo);
  const totalBeforeDiscount = useAppSelector(selectTotalBeforeDiscount);
  const margineVeicolo = useAppSelector(selectMargineVeicolo);
  const optionalsValues = useAppSelector(selectOptionalsValues);
  const accessoriValues = useAppSelector(selectAccessoriValues);
  const incentiviValues = useAppSelector(selectIncentiviValues);
  const accessoriTotal = useAppSelector(selectAccessoriValues);
  const totaleVeicolo = useAppSelector(selectTotaleVeicolo);
  const serviziTotal = useAppSelector(selectServiziValues);
  const prezzoFinale = useAppSelector(selectPriceFinal);
  const margineTotal = useAppSelector(selectMargineTotal);
  const permuteTotal = useAppSelector(selectTotalPermute);
  const permuteIvaEsente = useAppSelector(selectPermuteIvaEsente);
  const permuteIvaEsclusa = useAppSelector(selectPermuteIvaEsclusa);
  const totImponibile = useAppSelector(selectImponibile);
  const ivaValue = useAppSelector(selectIvaValue);
  const totLordo = useAppSelector(selectTotLordo);
  const sconti = useAppSelector(selectSconti);

  const handleClick = () => {
    setShowDetailPanel((prevState) => !prevState);
  };

  const detailRows: Array<DetailRowObject> = Array.from([
    {
      label: "Veicolo Base",
      price: veicolo?.price || 0,
    },
    {
      label: "Optional Ford",
      price: optionalsValues[0],
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Totale listino",
      price: totalBeforeDiscount,
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Allestimenti after-market",
      price: accessoriTotal[0],
    },
    {
      label: "Servizi, messa su strada ecc. (IVA esclusa)",
      price: serviziTotal[0],
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Totale Veicolo",
      price: totaleVeicolo,
      rowClass: "pb-3 mb-3 border-b",
    },
    {
      label: "Sconti su listino",
      price:
        sconti.find(({ type }) => type === EScontoType.VEICOLO)?.price || 0,
      isNegative: true,
      priceClass: "bg-slate-200",
    },
    {
      label: "Sconti su after-market",
      price:
        sconti.find(({ type }) => type === EScontoType.ACCESSORI)?.price || 0,
      isNegative: true,
      priceClass: "bg-slate-200",
      rowClass:
        (sconti.find(({ type }) => type === EScontoType.ACCESSORI)?.price ||
          0) === 0
          ? "hidden"
          : "",
    },
    {
      label: "Sconti su servizi",
      price:
        sconti.find(({ type }) => type === EScontoType.SERVIZI)?.price || 0,
      isNegative: true,
      priceClass: "bg-slate-200",
    },
    /*
    {
      label: "Sconto quantità",
      price:
        sconti.find(({ type }) => type === EScontoType.ACQUISTI_MULTIPLI)
          ?.price || 0,
      isNegative: true,
      priceClass: "bg-slate-200",
    },
    */
    {
      label: "Sconto Finale",
      price:
        sconti.find(({ type }) => type === EScontoType.PREZZO_FINALE)?.price ||
        0,
      isNegative: true,
      priceClass: "bg-slate-200",
    },
    {
      label: "Bonus / Incentivi",
      price: -1 * incentiviValues[0],
      isNegative: true,
      priceClass: "bg-slate-200",
    },
    {
      label: "Prezzo su strada",
      price: prezzoFinale,
      priceClass: "!text-[1.15rem]",
      rowClass: "pb-3 mb-3 pt-3 mt-3 border-b border-t",
    },
    {
      label: "Permute Iva Esente",
      price: permuteIvaEsente,
      isNegative: true,
      priceClass: "bg-slate-200",
    },
    {
      label: "Permute Iva Esclusa",
      price: permuteIvaEsclusa,
      isNegative: true,
      priceClass: "bg-slate-200",
      rowClass: "pb-3 mb-3 border-b",
    },
    /*
    {
      label: "IVA",
      // TODO: Portare nello slice con selector
      price: ivaAmount,
      rowClass: "pb-3 mb-3 border-b border-b-white",
    },
    */
  ]);

  const marginRows: Array<DetailRowObject> = Array.from([
    {
      label: "IMPONIBILE",
      price: totImponibile,
      priceClass: "bg-white",
    },
    {
      label: "IVA",
      price: ivaValue,
      priceClass: "bg-white",
    },
    {
      label: "TOTALE IVA INCLUSA",
      price: totLordo,
      priceClass: "bg-white",
      rowClass: "mb-10",
    },
    {
      label: "Margine Veicolo+Optional",
      price: margineVeicolo,
      priceClass: changeColorByMarginSign(margineVeicolo),
    },
    {
      label: "Margine Accessori after-martket",
      price: accessoriValues[2],
      priceClass: changeColorByMarginSign(accessoriValues[2]),
    },
    {
      label: "Margine Totale",
      price: margineTotal,
      priceClass: changeColorByMarginSign(margineTotal),
    },
  ]);

  return (
    <div className="z-10 rounded-xl border bg-white px-6 py-4 text-slate-600">
      <span className="mb-2 block text-sm font-semibold uppercase">
        Dettagli prezzo
      </span>
      {detailRows.map((detail, index) => (
        <DetailRow
          key={index}
          label={detail.label}
          price={detail.price}
          rowClass={detail.rowClass}
          priceClass={detail.priceClass}
          isNegative={detail.isNegative}
        />
      ))}
      {showDetailPanel &&
        marginRows.map((margin, index) => (
          <DetailRow
            key={`${margin.label}-${index}`}
            label={margin.label}
            price={margin.price}
            priceClass={margin.priceClass}
          />
        ))}
      <div className="flex justify-end">
        <Button
          variant="transparent"
          p={0}
          h={16}
          className="text-[9px] uppercase text-slate-600"
          onClick={handleClick}
          rightSection={
            <IconChevronDown
              className={`h-4 w-4 ${showDetailPanel ? "rotate-180" : ""}`}
            />
          }
        >
          Dettagli
        </Button>
      </div>
    </div>
  );
};

export default TotalDetails;
