import { modals } from "@mantine/modals"
import { Permuta } from "../../../queries/preventivi"
import { IconTrash } from "@tabler/icons-react"
import { removePermuta } from "../../../features/preventivo/preventivoSlice"
import { useAppDispatch } from "../../../app/hooks"
import { deleteModalProps } from "../../Modal/ModalDelete/ModalDelete"
import { Button, NumberFormatter } from "@mantine/core"

interface PermutaSummaryProps {
  permute: Permuta[]
  isReadOnly?: boolean
}

const PermutaSummary = ({ permute, isReadOnly }: PermutaSummaryProps) => {
  const dispatch = useAppDispatch()
  return (
    <>
      {permute.map((permuta, index) => (
        <div
          className="relative mb-4 rounded-xl border bg-slate-100 px-4 py-2"
          key={index}
        >
          <div className="flex items-end justify-between gap-2">
            <div>
              <div className="flex items-center gap-2 mb-2">
                <span className="text-sm uppercase">{permuta.title}</span>
                <span className="block text-xs uppercase text-slate-600">
                  <strong>Targa: </strong>
                  {permuta.targa}
                </span>
              </div>

              <div className="mb-2 flex items-center gap-2">
                <span className="text-xs uppercase text-slate-600">
                  <strong>KM: </strong>
                  {permuta.km}
                </span>
                <span className="block text-xs uppercase text-slate-600">
                  <strong>Anno: </strong>
                  {permuta.year}
                </span>
              </div>

              <div className="mb-2 flex items-center gap-2">
                <span className="text-xs uppercase text-slate-600">
                  <strong>TIPOLOGIA IVA: </strong>
                  {permuta.iva_type}
                </span>
              </div>

              <p className="text-sm font-light text-slate-600">
                <strong className="text-xs font-bold uppercase">Note:</strong>{" "}
                {permuta.description}
              </p>
            </div>
            <div className="flex items-center gap-5">
              {!isReadOnly && (
                <Button
                  variant="transparent"
                  p={0}
                  onClick={() =>
                    modals.openConfirmModal({
                      ...deleteModalProps("permuta", "permute", [""]),
                      onConfirm: () => dispatch(removePermuta(permuta)),
                    })
                  }
                >
                  <IconTrash className="h-5 w-5" />
                </Button>
              )}
              <span className="rounded-full bg-slate-600 px-2 text-sm font-semibold text-white">
                -
                <NumberFormatter
                  thousandSeparator="."
                  decimalScale={2}
                  decimalSeparator=","
                  value={permuta.price.toFixed(2)}
                />{" "}
                €
              </span>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default PermutaSummary
