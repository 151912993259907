import { Button, NumberInput, Textarea, TextInput } from "@mantine/core"
import { useForm } from "react-hook-form"
import { usePutVeicoloMutation } from "../../features/api/apiSlice"
import { Veicolo } from "../../queries/veicoli"
import { useEffect } from "react"
import { IconCheck } from "@tabler/icons-react"
import { notifications } from "@mantine/notifications"

const AdminVeicoloForm = ({
  currentVeicolo,
}: {
  currentVeicolo: Veicolo | null
}) => {
  const [putVeicolo] = usePutVeicoloMutation()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm()

  useEffect(() => {
    if (currentVeicolo) {
      Object.entries(currentVeicolo).forEach(([key, value]) => {
        setValue(key as keyof Veicolo, value)
      })
    }
  }, [currentVeicolo, setValue])

  const onSubmit = (data: any) => {
    const veicoloData: Veicolo = {
      ...currentVeicolo,
      ...data,
    }

    putVeicolo(veicoloData)
      .unwrap()
      .then((payload) => {
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: "Successo!",
          radius: "md",
          message: "Veicolo modificato.",
          color: "teal",
          loading: false,
        })
      })
      .catch((error: Error) => {
        console.error(error)
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: "Successo!",
          radius: "md",
          message: "Errore nella modifica del veicolo. Riprova.",
          color: "pink",
          loading: false,
        })
      })
  }

  return (
    <>
      {currentVeicolo ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-5 text-md font-medium bg-slate-600 text-white py-6 px-6 w-[calc(100%+3rem)] -ml-6 rounded-t-xl">
            <span className="uppercase font-semibold">Caratteristiche veicolo</span>
          </div>
          <div className="mb-5 flex flex-col gap-2">
            <TextInput
              {...register("altezza")}
              label="Altezza"
              className="w-full"
            />
            <TextInput
              {...register("lunghezza")}
              label="Lunghezza"
              className="w-full"
            />
            <TextInput
              {...register("portata")}
              label="Portata"
              className="w-full"
            />
            <TextInput
              {...register("mm_vano")}
              label="mm Vano"
              className="w-full"
            />
            <TextInput {...register("cv")} label="cv" className="w-full" />
            <TextInput {...register("kw")} label="kW" className="w-full" />
            <TextInput {...register("ps")} label="ps" className="w-full" />
            <TextInput
              {...register("cambio")}
              label="Cambio"
              className="w-full"
            />
            <TextInput label="Dimensioni" className="w-full" />
            <Textarea
              {...register("dotazioni_assistenza_alla_guida")}
              label="Dotazioni Assistenza alla Guida"
              className="w-full"
            />
            <Textarea
              {...register("dotazioni_interne")}
              label="Dotazioni interne"
              className="w-full"
            />
            <Textarea
              {...register("dotazioni_sicurezza")}
              label="Dotazioni sicurezza"
              className="w-full"
            />
            <Textarea
              {...register("dotazioni_tecnologia")}
              label="Dotazioni tecnologia"
              className="w-full"
            />
            <TextInput
              {...register("modello_modelyear")}
              label="Model year"
              className="w-full"
            />
            <TextInput
              {...register("modello_tipologia")}
              label="Modello tipologia"
              className="w-full"
            />
            <TextInput
              {...register("modello_veicolo")}
              label="Modello veicolo"
              className="w-full"
            />
            <TextInput
              {...register("modello_versione")}
              label="Modello versione"
              className="w-full"
            />
            <TextInput
              {...register("motore")}
              label="Motore"
              className="w-full"
            />
            <TextInput
              {...register("pc_tipologia_hl")}
              label="pc tipologia hl"
              className="w-full"
            />
            <Textarea
              {...register("caratteristiche_versione")}
              label="Caratteristiche versione"
              className="w-full"
            />
            <NumberInput
              label="Margine base"
              min={0}
              max={100}
              decimalSeparator=","
              rightSection={<span>%</span>}
              value={watch("margine_base")}
              onChange={(value) => setValue("margine_base", value || 0)}
              w={"100%"}
            />
          </div>
          <div className="mt-6 flex justify-end">
            <Button
              type="submit"
              variant="filled"
              radius={50}
              leftSection={<IconCheck />}
            >
              Conferma
            </Button>
          </div>
        </form>
      ) : (
        <p className="text-center py-6">
          Seleziona un veicolo per visualizzare le caratteristiche.
        </p>
      )}
    </>
  )
}

export default AdminVeicoloForm
