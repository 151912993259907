import TableWrapper from "../../Layout/TableWrapper/TableWrapper"
import AccessoriTable from "../../components/Admin/AdminTable/AccessoriTable"
import AdminAccessoriForm from "../../components/Form/AdminAccessoriForm"
import TableActionsWrapper from "../../Layout/TableActionsWrapper/TableActionsWrapper"
import { modals } from "@mantine/modals"
import { Button, Flex } from "@mantine/core"
import { IconCirclePlus } from "@tabler/icons-react"

const AdminAccessori = () => {
  return (
    <>
      <TableActionsWrapper>
        <Flex gap={20}>
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconCirclePlus size={18} />}
            onClick={() => {
              modals.open({
                padding: 0,
                centered: true,
                withCloseButton: false,
                size: "70%",
                radius: 26,
                children: (
                  <AdminAccessoriForm onCloseClick={() => modals.closeAll()} />
                ),
              })
            }}
          >
            Nuovo accessorio
          </Button>
        </Flex>
      </TableActionsWrapper>
      <TableWrapper>
        <AccessoriTable />
      </TableWrapper>
    </>
  )
}

export default AdminAccessori
