import PdfProvider from "../../../components/Preventivo/PdfProvider/PdfProvider"
import { Link } from "react-router-dom"
import { Button, Flex } from "@mantine/core"
import {
  IconAlertCircle,
  IconCopy,
  IconEdit,
  IconEditCircle,
  IconPrinter,
  IconTrash,
} from "@tabler/icons-react"

type ViewPreventivoActionsProps = {
  data: any
  preventivoId: string | undefined
  handleScreenshotToPdf: () => void
  handleRemovePreventivo: () => void
  handleNotificationClick: () => void
}

const ViewPreventivoActions = ({
  data,
  preventivoId,
  handleScreenshotToPdf,
  handleRemovePreventivo,
  handleNotificationClick,
}: ViewPreventivoActionsProps) => {
  return (
    <div className="sticky top-0 z-30">
      <Flex
        justify="space-between"
        className="progress__header bg-[#f4f4f4] px-10 py-5"
        gap={32}
      >
        <div className="w-full flex items-center justify-between gap-5">
          <div className="flex items-center gap-5">
            <Link to={`/preventivi/duplica/${preventivoId}`}>
              <Button
                variant="outline"
                radius="xl"
                leftSection={<IconCopy size={18} />}
              >
                Duplica
              </Button>
            </Link>
            <Link to={`/preventivi/modifica/${preventivoId}`}>
              <Button
                variant="outline"
                radius="xl"
                leftSection={<IconEdit size={18} />}
              >
                Modifica
              </Button>
            </Link>
            <Button
              variant="outline"
              radius="xl"
              leftSection={<IconAlertCircle size={18} />}
              onClick={handleNotificationClick}
            >
              Notifica
            </Button>
            <Link to={`/preventivi/personalizza-stampa/${preventivoId}`}>
              <Button
                variant="outline"
                radius="xl"
                leftSection={<IconEditCircle size={18} />}
              >
                Personalizza stampa
              </Button>
            </Link>
            {data?.preventivi && (
              <PdfProvider
                preventivoId={preventivoId || "0000"}
                isIconOnly={false}
              />
            )}
            <Button
              variant="outline"
              radius="xl"
              leftSection={<IconPrinter size={18} />}
              onClick={handleScreenshotToPdf}
            >
              Stampa riepilogo
            </Button>
          </div>
          <div>
            <Button
              color="pink"
              radius="xl"
              leftSection={<IconTrash size={18} />}
              onClick={handleRemovePreventivo}
            >
              Elimina
            </Button>
          </div>
        </div>
      </Flex>
    </div>
  )
}

export default ViewPreventivoActions
