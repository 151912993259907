import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { Veicolo } from "../../queries/veicoli"

export interface UiState {
  isSidebarCollapsed: boolean
  activeStep: number
  adminCurrentVeicolo: Veicolo | null
}

const initialState: UiState = {
  isSidebarCollapsed: true,
  activeStep: 1,
  adminCurrentVeicolo: null,
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setSidebarCollapsed: (state) => {
      state.isSidebarCollapsed = !state.isSidebarCollapsed
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    setAdminCurrentVeicolo: (state, action: PayloadAction<Veicolo | null>) => {
      state.adminCurrentVeicolo = action.payload
    },
    reset: (state) => ({
      ...state,
      activeStep: 1,
    }),
  },
})

export const {
  setSidebarCollapsed,
  setActiveStep,
  setAdminCurrentVeicolo,
  reset,
} = uiSlice.actions

export const selectIsSidebarCollapsed = (state: RootState) =>
  state.ui.isSidebarCollapsed
export const selectActiveStep = (state: RootState) => state.ui.activeStep
export const selectAdminCurrentVeicolo = (state: RootState) =>
  state.ui.adminCurrentVeicolo

export default uiSlice.reducer
