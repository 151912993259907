import React from "react";

const CustomerDataContainer = ({
  containerLabel,
  children,
}: {
  containerLabel: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="mb-8">
      <div className="mb-2">
        <span className="text-sm">{containerLabel}</span>
      </div>
      <div className="flex flex-wrap items-center gap-x-14 gap-y-2">
        {children}
      </div>
    </div>
  );
};

export default CustomerDataContainer;
