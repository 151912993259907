import React from "react";
import * as Realm from "realm-web";
import settings from "../settings.json";
import { useAppDispatch } from "../app/hooks";
import { setToken } from "../features/settings/settingsSlice";
import { useNavigate } from "react-router-dom";

const { baseUrl } = settings;

function createApp(id: string) {
  return new Realm.App({ id, baseUrl });
}

const AppContext = React.createContext(null);

// @ts-ignore
export function AppProvider({ appId, children }) {
  const dispatch = useAppDispatch();

  // Store Realm.App in React state. If appId changes, all children will rerender and use the new App.
  const [app, setApp] = React.useState(createApp(appId));

  app.currentUser?.accessToken &&
    dispatch(
      setToken([
        app.currentUser?.accessToken,
        app.currentUser?.refreshToken || "",
      ]),
    );

  React.useEffect(() => {
    setApp(createApp(appId));
  }, [appId]);
  // Store the app's current user in state and wrap the built-in auth functions to modify this state

  // Wrap the base logIn function to save the logged in user in state
  const logIn = React.useCallback(
    // @ts-ignore
    async (credentials) => {
      try {
        const user = await app.logIn(credentials);
        dispatch(setToken([user.accessToken || "", user.refreshToken || ""]));
        return user;
      } catch (err) {
        throw err;
      }
    },
    [app.currentUser],
  );

  // Wrap the current user's logOut function to remove the logged out user from state
  const logOut = React.useCallback(async () => {
    try {
      const user = app.currentUser;
      await user?.logOut();
      // @ts-ignore
      await app.removeUser(user);
    } catch (err) {
      console.error(err);
    }
    // In this App there will only be one logged in user at a time, so
    // the new current user will be null. If you add support for
    // multiple simultaneous user logins, this updates to another logged
    // in account if one exists.
  }, [app]);

  // Override the App's currentUser & logIn properties + include the app-level logout function
  const appContext = React.useMemo(() => {
    return { ...app, logIn, logOut };
  }, [app, logIn, logOut]);

  return (
    // @ts-ignore
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
}

export function useApp() {
  const app = React.useContext(AppContext);
  if (!app) {
    throw new Error(
      `No App Services App found. Make sure to call useApp() inside of a <AppProvider />.`,
    );
  }
  return app;
}
