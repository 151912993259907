import { Option } from "../../queries/veicoli"
import { Textarea } from "@mantine/core"
import { updateOption } from "../../features/preventivo/preventivoSlice"
import { useAppDispatch } from "../../app/hooks"

type OptionDescriptionEditProps = {
  optional: Option
  isOptionReadOnly?: boolean
}

const OptionDescriptionEdit = ({
  optional,
  isOptionReadOnly,
}: OptionDescriptionEditProps) => {
  const dispatch = useAppDispatch()
  return (
    <div className="my-2 ml-8">
      <Textarea
        defaultValue={optional.description}
        disabled={isOptionReadOnly}
        placeholder="Inserisci una descrizione dell'after-market"
        autosize
        minRows={3}
        onChange={(e) =>
          dispatch(
            updateOption({
              ...optional,
              description: e.currentTarget.value,
            }),
          )
        }
      />
    </div>
  )
}

export default OptionDescriptionEdit
