import { Container } from "@mantine/core";
import WIP from "../../../components/WIP/WIP";

const TimelineCliente = () => {
  return (
    <Container size="100%" bg="white" px="40" py="20" mt={76}>
      <WIP />
    </Container>
  );
};

export default TimelineCliente;
