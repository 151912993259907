import { Container } from "@mantine/core"
import Step4 from "../NewPreventivo/Step4/Step4"
import { useEffect } from "react"

const EditPrintPreventivo = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container mb="40px" size="100%" px="40" py="40">
      <Step4 />
    </Container>
  )
}

export default EditPrintPreventivo
