const Logo = ({ isIconOnlyVersion }: { isIconOnlyVersion: boolean }) => {
  return (
    <div className="flex items-center gap-2">
      <span className="flex h-9 w-9 items-center justify-center rounded-full bg-slate-200 text-lg font-bold text-slate-500">
        CM
      </span>
      {!isIconOnlyVersion && (
        <span className="text-lg font-bold text-white">Carlo Mauri</span>
      )}
    </div>
  );
};

export default Logo;
