import { Anagrafica } from "../../../../queries/anagrafica"
import CustomerDataContainer from "./CustomerDataContainer/CustomerDataContainer"
import CustomerDataRow from "./CustomerDataRow/CustomerDataRow"

const CustomerData = ({ currentCustomer }: { currentCustomer: Anagrafica }) => {
  const dataMapping = [
    {
      containerLabel: "Dati generici",
      rows: [
        { rowLabel: "Nome", rowValue: currentCustomer?.nome },
        { rowLabel: "Codice fiscale", rowValue: currentCustomer?.cf },
        { rowLabel: "Tipologia", rowValue: currentCustomer?.tipologia },
      ],
    },
    {
      containerLabel: "Dati fiscali",
      rows: [
        { rowLabel: "Partita IVA", rowValue: currentCustomer?.piva },
        { rowLabel: "Valuta", rowValue: currentCustomer?.valuta },
      ],
    },
    {
      containerLabel: "Indirizzi",
      rows: [
        { rowLabel: "Indirizzo", rowValue: currentCustomer?.indirizzo },
        { rowLabel: "Civico", rowValue: currentCustomer?.civico },
        { rowLabel: "Località", rowValue: currentCustomer?.localita },
        { rowLabel: "Provincia", rowValue: currentCustomer?.prov },
        { rowLabel: "Nazione", rowValue: currentCustomer?.nazione },
      ],
    },
    {
      containerLabel: "Contatti",
      rows: [
        { rowLabel: "Email", rowValue: currentCustomer?.email },
        { rowLabel: "PEC", rowValue: currentCustomer?.pec },
        { rowLabel: "Telefono", rowValue: currentCustomer?.tel },
      ],
    },
    {
      containerLabel: "Referenti",
      rows:
        currentCustomer?.referenti?.map((referente) => ({
          rowLabel: "Referente",
          rowValue: referente,
        })) || [],
    },
    {
      containerLabel: "Note",
      rows: currentCustomer?.note
        ? [{ rowLabel: "Note", rowValue: currentCustomer?.note }]
        : [],
    },
  ]

  return (
    <>
      {dataMapping.map((section, index) => (
        <CustomerDataContainer
          key={index}
          containerLabel={section.containerLabel}
        >
          {section.rows.map((row, idx) => (
            <CustomerDataRow
              key={idx}
              rowLabel={row.rowLabel}
              rowValue={row.rowValue}
            />
          ))}
        </CustomerDataContainer>
      ))}
    </>
  )
}

export default CustomerData
