import { DateInput } from "@mantine/dates"

const DeliveryDate = ({ isReadOnly }: { isReadOnly: boolean }) => {
  // const deliveryDateValue = useAppSelector((state) => state.preventivo);

  return (
    <DateInput
      //defaultValue={new Date()}
      valueFormat="DD/MM/YYYY"
      label="Data di consegna"
      placeholder="Seleziona la data di consegna"
      className="w-full"
      disabled
      styles={{
        input: {
          width: "100%",
          height: 44,
          borderRadius: 12,
        },
      }}
      // value={value}
      // onChange={setValue}
    />
  )
}

export default DeliveryDate
