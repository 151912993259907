import SummaryBox from "../SummaryBox/SummaryBox";
import SummaryBoxLabel from "../SummaryBox/SummaryBoxLabel";
import { EEngines } from "../../../features/settings/settingsSlice";
import { Veicolo } from "../../../queries/veicoli";
import { formatListino } from "../../../utils/formatters";
import { IconCircleCheck } from "@tabler/icons-react";
import { Image, List, NumberFormatter } from "@mantine/core";

const VeicoloSummary = ({
  veicolo,
  children,
  isReadOnly,
}: {
  veicolo: Veicolo | undefined;
  children?: React.JSX.Element;
  isReadOnly?: boolean;
}) => {
  return (
    <SummaryBox label="Veicolo" withToggle>
      <div className="summary__children">
        {children}
        {veicolo && !isReadOnly && <SummaryBoxLabel text="Riepilogo" />}
        {veicolo && (
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-start gap-3">
              <Image
                className="h-auto w-20 rounded-lg object-cover"
                src={null}
                fallbackSrc="https://placehold.co/600x400?text=Placeholder"
              />
              <div className="summary__row">
                <span className="block text-sm text-black">
                  {veicolo.description}
                </span>
                <List
                  icon={<IconCircleCheck className="h-3 w-3" />}
                  className="mt-1"
                  styles={{
                    itemIcon: {
                      marginRight: 4,
                    },
                  }}
                >
                  <div>
                    <span className="text-xs font-semibold uppercase text-slate-700">
                      Listino {formatListino(veicolo.listino || "")} -{" "}
                    </span>
                    <span className="text-xs font-semibold uppercase text-slate-700">
                      {
                        //@ts-ignore
                        EEngines[veicolo?.engine]
                      }
                    </span>
                  </div>
                  {veicolo.optionals?.map((optional, index) => (
                    <List.Item
                      key={index}
                      className="mr-1 text-xs font-semibold text-black"
                    >
                      {optional}
                    </List.Item>
                  ))}
                </List>
              </div>
            </div>
            <div>
              <span className="block min-w-24 rounded-full bg-slate-700 px-3 py-1 text-right text-sm font-semibold text-white">
                <NumberFormatter
                  decimalScale={2}
                  value={veicolo.price?.toFixed(2)}
                  thousandSeparator="."
                  decimalSeparator=","
                />{" "}
                €
              </span>
            </div>
          </div>
        )}
      </div>
    </SummaryBox>
  );
};

export default VeicoloSummary;
