import SummaryBox from "../../../../components/Preventivo/SummaryBox/SummaryBox"
import SelectListini from "../../../../components/Preventivo/PreventivoInputs/SelectListini"
import SelectCliente from "../../../../components/Preventivo/PreventivoInputs/SelectCliente"
import SelectModello from "../../../../components/Preventivo/PreventivoInputs/SelectModello"
import SelectVeicolo from "../../../../components/Preventivo/PreventivoInputs/SelectVeicolo"
import VeicoloSummary from "../../../../components/Preventivo/Summaries/VeicoloSummary"
import SelectTemperatura from "../../../../components/Preventivo/PreventivoInputs/SelectTemperatura"
import SelectAlimentazione from "../../../../components/Preventivo/PreventivoInputs/SelectAlimentazione"
import { Veicolo } from "../../../../queries/veicoli"
import { EEngines } from "../../../../features/settings/settingsSlice"

interface Step1Props {
  model: string | undefined
  listino: string | undefined
  engine: EEngines | undefined
  veicolo: Veicolo | undefined
}

const Step1 = ({ veicolo }: Step1Props) => {
  const step1SelectMap = [
    SelectModello,
    SelectListini,
    SelectAlimentazione,
    SelectVeicolo,
  ]

  return (
    <>
      <div className="mb-10">
        <SummaryBox label="Cliente" withToggle>
          <SelectCliente />
          <SelectTemperatura />
        </SummaryBox>
      </div>
      <div className="mb-10">
        {
          //@ts-ignore
          <VeicoloSummary veicolo={veicolo}>
            <>
              {step1SelectMap.map((Select, index) => (
                <div key={index} className="mb-5">
                  <Select />
                </div>
              ))}
            </>
          </VeicoloSummary>
        }
      </div>
    </>
  )
}

export default Step1
