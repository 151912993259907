import { gql } from "@apollo/client";
import { BSON } from "realm-web";
import { TS4Save } from "../utils/formatters";
export interface Notification {
  _id?: string;
  id_agente_from: string;
  nome_agente_from?: string;
  id_agente_to: string;
  nome_agente_to?: string;
  id_preventivo?: string;
  id_cliente?: string;
  nome_cliente?: string;
  title?: string;
  text?: string;
  created_at?: number;
  alert_at: number;
  dismissed_at?: number;
  type?: string;
}

export interface NotificationsQuery {
  limit?: number;
  id_agente?: string;
  id_preventivo?: string;
  id_cliente?: string;
  nome_cliente?: string;
}

export interface NotificationsResponse {
  notifications: Notification[];
}

export const queryNotifications = ({
  limit,
  id_agente,
  id_preventivo,
  id_cliente,
  nome_cliente,
}: NotificationsQuery) => ({
  document: gql`
    query (
      $id_agente: String
      $id_preventivo: String
      $limit: Int
      $id_cliente: String
      $nome_cliente: String
    ) {
      notifications(
        limit: $limit
        sortBy: ALERT_AT_DESC
        query: {
          id_agente_to: $id_agente
          id_preventivo: $id_preventivo
          id_cliente: $id_cliente
          nome_cliente: $nome_cliente
        }
      ) {
        _id
        id_agente_from
        nome_agente_from
        id_agente_to
        nome_agente_to
        id_preventivo
        id_cliente
        nome_cliente
        text
        alert_at
        dismissed_at
      }
    }
  `,
  variables: {
    limit: limit || 500,
    id_agente: id_agente || null,
    id_preventivo: id_preventivo || null,
    id_cliente: id_cliente || null,
    nome_cliente: nome_cliente || null,
  },
});

export const mutationNotification = (notification: Notification) => ({
  document: gql`
    mutation ($dismissed_at: Int) {
      upsertOneNotification(
        data: {
          id_agente_from: "${notification.id_agente_from}",
          nome_agente_from: "${notification.nome_agente_from}",
          id_agente_to: "${notification.id_agente_to}",
          nome_agente_to: "${notification.nome_agente_to}",
          id_preventivo: "${notification.id_preventivo}",
          id_cliente: "${notification.id_cliente}",
          nome_cliente: "${notification.nome_cliente}",
          dismissed_at: $dismissed_at,
          title: "",
          text: "${notification.text}",
          created_at: ${notification.created_at ? TS4Save(notification.created_at) : TS4Save()},
          alert_at: ${notification.alert_at ? TS4Save(notification.alert_at) : TS4Save(Date.now() + 3e5)},
          type: "preventivo",
        },
        query: {
          _id: "${notification._id ? new BSON.ObjectID(notification._id) : new BSON.ObjectID()}"
        }) {
        _id
      }
    }
  `,
  variables: {
    dismissed_at: notification.dismissed_at
      ? TS4Save(notification.dismissed_at)
      : null,
  },
});
