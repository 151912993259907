import axios from "axios";
import * as Realm from "realm-web";

import { mongoDB, collections, appId } from "../../settings.json";
import { User } from "./UsersSlice";

const mongoQueryData = {
  collection: collections.users,
  database: mongoDB,
  dataSource: "Cluster0",
};

const {
  BSON: { ObjectId },
} = Realm;

export async function getUsers() {
  const app = new Realm.App({ id: appId });

  try {
    const users = await app.currentUser
      ?.mongoClient("mongodb-atlas")
      .db("PISTA")
      .collection("Users")
      .find({}, { projection: { _id: 0, data: 1, custom_data: 1 } });

    return users;
  } catch (err) {
    console.log(err);
  }
  // return users.filter((user: User) => !user.custom_data?.disabled);
}

export async function newUser(
  data: User,
): Promise<boolean | { error: string } | String> {
  const app = new Realm.App({ id: appId });
  const email = data.data.email;
  const before = email.split("@")[0];
  const password = `${before}@carlomauri`;
  const mongo = app.currentUser?.mongoClient("mongodb-atlas");
  const collection = mongo?.db("PISTA").collection("Users");

  try {
    await app.emailPasswordAuth.registerUser({
      email,
      password,
    });
    const result = await collection?.insertOne(data);
    await app.emailPasswordAuth.sendResetPasswordEmail({ email });
    console.log(result);
    return email;
  } catch (e) {
    //@ts-ignore
    return { error: e.errorCode };
  }
}

export async function suspendUser(email: string): Promise<boolean> {
  const app = new Realm.App({ id: appId });
  const mongo = app.currentUser?.mongoClient("mongodb-atlas");
  const collection = mongo?.db("Paparazzo").collection("Users");

  try {
    const result = await collection?.updateOne(
      { "data.email": email },
      { $set: { "custom_data.disabled": true } },
    );

    console.log(result);

    return true;
  } catch (e) {
    throw new Error(`Paparazzo: errore cancellazione utente`);
  }
}

export async function updateUser(userObj: User): Promise<String> {
  const app = new Realm.App({ id: appId });
  const mongo = app.currentUser?.mongoClient("mongodb-atlas");
  const collection = mongo?.db("Paparazzo").collection("Users");

  try {
    const result = await collection?.updateOne(
      { "data.email": userObj.data.email },
      { $set: { custom_data: userObj.custom_data } },
    );

    console.log(result);

    return userObj.data.email;
  } catch (e) {
    throw new Error(`Paparazzo: errore edit utente`);
  }
}
