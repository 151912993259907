import { AppProvider } from "./components/RealmApp";
import settings from "./settings.json";
import AppRouting from "./routing/AppRouting";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import AuthProvider from "react-auth-kit/AuthProvider";
import { rakStore } from "./app/store";

const { appId } = settings;

export default function ProvidedApp() {
  return (
    <AppProvider appId={appId}>
      <MantineProvider>
        <ModalsProvider>
          <Notifications position="top-center" />
          <AuthProvider store={rakStore}>
            <AppRouting />
          </AuthProvider>
        </ModalsProvider>
      </MantineProvider>
    </AppProvider>
  );
}
