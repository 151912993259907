import { useEffect, useState } from "react";
import * as Realm from "realm-web";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { appId } from "../../settings.json";
import AuthWrapper from "../../Layout/AuthWrapper/AuthWrapper";

const PasswordResetForm = () => {
  const app = new Realm.App({ id: appId });

  const [token, setToken] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [passResetted, setPassResetted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const tokenId = params.get("tokenId");
    if (token && tokenId) {
      setToken(token);
      setTokenId(tokenId);
    }
  }, []);

  const onSubmit = async (data: any) => {
    if (token && tokenId && data.password) {
      await app.emailPasswordAuth.resetPassword({
        password: data.password,
        token,
        tokenId,
      });
      setPassResetted(true);
    } else if (data.email) {
      await app.emailPasswordAuth.sendResetPasswordEmail({
        email: data.email,
      });
      setEmailSent(true);
    }
  };

  return (
    <AuthWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="pb-4 text-center text-xl font-bold">
          Reimposta password
        </h2>

        {token && tokenId && (
          <TextInput
            {...register("password", { required: true })}
            placeholder="Password"
            className="mb-3 w-full"
            radius={12}
            styles={{ input: { height: 44 } }}
          />
        )}

        {!token && !tokenId && (
          <TextInput
            {...register("email", { required: true })}
            label="Email"
            placeholder="Email"
            className="mb-3 w-full"
            radius={12}
            styles={{ input: { height: 44 } }}
          />
        )}

        <Button type="submit" className="w-full rounded-full">
          Resetta password
        </Button>

        {emailSent && (
          <div
            className="err--message"
            style={{ color: "red", marginTop: 20, textAlign: "center" }}
          >
            <p>Link per il reset della password inviato tramite email!</p>
          </div>
        )}

        {passResetted && (
          <div
            className="err--message"
            style={{ color: "red", marginTop: 20, textAlign: "center" }}
          >
            <p style={{ color: "#008200!important", fontSize: 16 }}>
              Password correttamente impostata
            </p>
          </div>
        )}

        <div
          className="err--message"
          style={{ color: "red", marginTop: 20, textAlign: "center" }}
        >
          <p className="text-sm text-slate-800">
            <Link to="/login">Clicca qui per tornare alla pagina di Login</Link>
          </p>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default PasswordResetForm;
