import { useMemo } from "react"
import {
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { Button, NumberFormatter } from "@mantine/core"
import { IconEdit, IconTrash } from "@tabler/icons-react"
import { MRT_Localization_IT } from "mantine-react-table/locales/it/index.esm.mjs"
import {
  useDeleteOptionsMutation,
  useGetOptionalsQuery,
} from "../../../features/api/apiSlice"
import { EOptionType, Option } from "../../../queries/veicoli"
import { modals } from "@mantine/modals"
import AdminAccessoriForm from "../../Form/AdminAccessoriForm"
import {
  deleteModalProps,
  onModalDelete,
} from "../../Modal/ModalDelete/ModalDelete"

const AccessoriTable = () => {
  const [deleteOption] = useDeleteOptionsMutation()
  const { data, isLoading } = useGetOptionalsQuery({
    limit: 100,
    type: EOptionType.ACCESSORI,
  })

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnFilterModes: false,
        enableSorting: false,
        enableHiding: false,
        size: 100,
      },
      {
        accessorKey: "label",
        header: "Label",
        enableHiding: false,
      },
      {
        accessorKey: "price",
        header: "Prezzo",
        enableHiding: false,
        Cell: ({ row }: { row: MRT_Row<Option> }) => (
          <>
            <NumberFormatter
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              value={row.original.price.toFixed(2)}
            />
            {" €"}
          </>
        ),
      },
      {
        accessorKey: "margine_base",
        header: "Margine",
        enableHiding: false,
        Cell: ({ row }: { row: MRT_Row<Option> }) => (
          <>
            {row.original.margine_base}
            {"%"}
          </>
        ),
      },
      {
        accessorKey: "categoria",
        header: "Categoria",
        enableHiding: false,
      },
      {
        accessorKey: "fornitore",
        header: "Fornitore",
        enableHiding: false,
      },
      {
        accessorKey: "is_updatable",
        header: "Prezzo modificabile",
        enableHiding: false,
        Cell: ({ row }: { row: MRT_Row<Option> }) => (
          <>{row.original.is_updatable ? "Sì" : "No"}</>
        ),
      },
      // {
      //   accessorKey: "listino",
      //   header: "Listino",
      //   enableHiding: false,
      //   Cell: ({ row }: { row: MRT_Row<Option> }) => (
      //     <>{formatListino(row.original.listino || "")}</>
      //   ),
      // },
    ],
    [],
  )

  const table = useMantineReactTable({
    //@ts-ignore
    columns,
    data: data?.optionals || [],
    createDisplayMode: "modal",
    editDisplayMode: "row",
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnPinning: true,
    enableRowActions: false,
    positionActionsColumn: "last",
    enableRowSelection: true,
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    globalFilterFn: "contains",
    enableFilterMatchHighlighting: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableFacetedValues: true,
    enableColumnResizing: false,
    enableStickyHeader: true,
    enableGlobalFilterModes: true,
    localization: MRT_Localization_IT,
    state: {
      isLoading: isLoading,
      density: "xs",
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    mantineSearchTextInputProps: {
      placeholder: "Cerca...",
      variant: "filled",
    },
    mantineTableHeadCellProps: {
      bg: "rgb(226,232,240,1)",
      frameBorder: "none",
    },
    mantinePaperProps: {
      withBorder: false,
      shadow: "none",
      radius: "md",
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "",
      },
    },

    renderTopToolbar: ({ table }) => {
      const isOneSelected =
        Object.keys(table.getState().rowSelection).length > 0
      const isManySelected =
        Object.keys(table.getState().rowSelection).length > 1

      const handleEdit = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          modals.open({
            padding: 0,
            withCloseButton: false,
            centered: true,
            size: "70%",
            radius: 26,
            children: (
              <AdminAccessoriForm
                currentAccessorio={row.original}
                onCloseClick={() => modals.closeAll()}
              />
            ),
          })
        })
      }

      const handleDelete = () => {
        const selectedIds = table
          .getSelectedRowModel()
          .flatRows.map((row) => row.original._id || "")
        modals.openConfirmModal({
          ...deleteModalProps(
            "accessorio after-market",
            "accessori after-market",
            selectedIds,
          ),
          onConfirm: () =>
            onModalDelete({
              selectedIds: selectedIds,
              deleteFunction: deleteOption,
              singularElement: "accessorio after-market",
              pluralElement: "accessori after-market",
              table: table,
            }),
        })
      }

      return (
        <div className="flex items-center justify-between bg-slate-200 p-4">
          {isOneSelected && (
            <div className="flex items-center gap-5">
              {!isManySelected && (
                <Button
                  leftSection={<IconEdit size={18} />}
                  onClick={handleEdit}
                >
                  Modifica
                </Button>
              )}
              <Button
                leftSection={<IconTrash size={18} />}
                onClick={handleDelete}
              >
                Elimina
              </Button>
            </div>
          )}
          <div className="ml-auto flex items-center gap-2">
            <div className="has-global-search flex items-center gap-2">
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToggleGlobalFilterButton table={table} />
            </div>
            <MRT_ToggleFiltersButton table={table} />
          </div>
        </div>
      )
    },
  })

  return <MantineReactTable table={table} />
}

export default AccessoriTable
