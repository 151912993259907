import OptionSelectComponent from "../../../../components/Preventivo/PreventivoInputs/SelectOptions"
import { EOptionType } from "../../../../queries/veicoli"

const Step2 = () => {
  interface IOptionSelect {
    type: EOptionType
    selectLabel: string
    summaryLabel: string
  }

  const optionSelectMapObject: IOptionSelect[] = [
    {
      type: EOptionType.OPTIONALS,
      selectLabel: "Seleziona gli optionals",
      summaryLabel: "Optionals",
    },
    {
      type: EOptionType.ACCESSORI,
      selectLabel: "Seleziona gli accessori after-market",
      summaryLabel: "Accessori after-market",
    },
    {
      type: EOptionType.SERVIZI,
      selectLabel: "Seleziona i servizi opzionali",
      summaryLabel: "Servizi",
    },
  ]

  return (
    <>
      {optionSelectMapObject.map((option, index) => (
        <OptionSelectComponent
          type={option.type}
          key={`${option.type}-${index}`}
          selectLabel={option.selectLabel}
          summaryLabel={option.summaryLabel}
        />
      ))}
    </>
  )
}

export default Step2
