import { NumberFormatter } from "@mantine/core";
import { DetailRowObject } from "./DetailRowTypes";

const DetailRow = ({
  label,
  price,
  rowClass,
  priceClass,
  isNegative,
}: DetailRowObject) => {
  return (
    <div
      className={`mb-3 flex items-center justify-between gap-2 ${rowClass && rowClass}`}
    >
      <span className="text-xs text-slate-600">{label}</span>
      <span
        className={`flex-none min-w-20 rounded-2xl px-3 py-1 text-right text-xs font-bold text-slate-600 ${priceClass || "text-white bg-slate-600"}`}
      >
        {isNegative && "-"}
        <NumberFormatter
          value={price.toFixed(2)}
          decimalScale={2}
          decimalSeparator=","
          thousandSeparator="."
        />{" "}
        €
      </span>
    </div>
  );
};

export default DetailRow;
