import { IconAlertTriangleFilled } from "@tabler/icons-react";

const WIP = () => {
  return (
    <div className="py-10">
      <h2 className="text-center text-3xl font-bold text-slate-800 flex items-center justify-center gap-2">
        <IconAlertTriangleFilled className="h-8 w-8" />
        Pagina in costruzione
      </h2>
    </div>
  );
};

export default WIP;
