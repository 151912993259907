import { modals } from "@mantine/modals"
import { User } from "../../../features/users/UsersSlice"
import { Button } from "@mantine/core"
import { useParams } from "react-router-dom"
import { IconAlertCircle } from "@tabler/icons-react"
import TableWrapper from "../../../Layout/TableWrapper/TableWrapper"
import useAuthUser from "react-auth-kit/hooks/useAuthUser"
import NotificationForm from "../../../components/Form/NotificationForm"
import NotificationsTable from "../../../components/Table/NotificationsTable"

const NotificheCliente = () => {
  const authUser: User | null = useAuthUser()
  const { customerId } = useParams()

  return (
    <>
      <section className="px-10 py-5">
        <div className="flex gap-5">
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconAlertCircle size={18} />}
            onClick={() => {
              modals.open({
                padding: 0,
                withCloseButton: false,
                size: "70%",
                radius: 26,
                centered: true,
                children: (
                  <NotificationForm
                    customerId={customerId}
                    authUser={authUser}
                  />
                ),
              })
            }}
          >
            Nuova notifica
          </Button>
        </div>
      </section>
      <TableWrapper>
        <NotificationsTable />
      </TableWrapper>
    </>
  )
}

export default NotificheCliente
