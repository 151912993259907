import { useGetNotificationsQuery } from "../features/api/apiSlice";

const useGetNotifications = ({
  limit,
  pollingInterval,
  idAgente,
  idCliente,
}: {
  limit?: number;
  pollingInterval?: number;
  idAgente?: string;
  idCliente?: string;
}) => {
  const { data, isLoading } = useGetNotificationsQuery(
    {
      id_agente: idAgente || undefined,
      id_cliente: idCliente || undefined,
      limit: limit || 500,
    },
    {
      pollingInterval: pollingInterval || 60e3,
      skipPollingIfUnfocused: true,
    },
  );

  const activeNotifications = data?.notifications.filter(
    (notification) => notification.dismissed_at === null,
  );

  const dismissedNotifications = data?.notifications.filter(
    (notification) => notification.dismissed_at !== null,
  );

  const filteredCustomerActiveNotifications = data?.notifications.filter(
    (notification) =>
      notification.dismissed_at === null &&
      notification.id_cliente === idCliente,
  );

  return {
    data,
    isLoading,
    activeNotifications,
    dismissedNotifications,
    filteredCustomerActiveNotifications,
  };
};

export default useGetNotifications;
