import WidgetBox from "../WidgetBox/WidgetBox";
import { Link, useParams } from "react-router-dom";
import WidgetList from "../WidgetList/WidgetList";
import { useGetPreventiviQuery } from "../../../features/api/apiSlice";
import { IconEye } from "@tabler/icons-react";
import { Button, Loader, NumberFormatter } from "@mantine/core";
import {
  transformTimestampToLocaleDateAndTime,
  getColorFromTemperature,
} from "../../../utils/utils";
import useAuthUserData from "../../../hooks/useAuthUserData";

const LatestPreventivi = () => {
  const { customerId } = useParams();
  const { authUserId, isAdmin } = useAuthUserData();
  const { data, isLoading } = useGetPreventiviQuery({
    limit: 6,
    idAgente: isAdmin ? undefined : authUserId,
  });
  const preventiviData = customerId
    ? data?.preventivis.filter(
        (preventivo) => preventivo.cliente?._id === customerId,
      )
    : data?.preventivis;

  const isPreventiviDataEmpty = preventiviData && preventiviData?.length > 0;
  const link = customerId ? `/clienti/${customerId}/preventivi` : "/preventivi";

  const displayLatestPreventivi = !isLoading ? (  
    isPreventiviDataEmpty ? (
      preventiviData?.map((preventivo, index) => (
        <WidgetList
          key={index}
          className={index === preventiviData.length - 1 ? "border-none" : ""}
        >
          <span
            //@ts-ignore
            className={`mx-auto block h-3 w-3 rounded-full ${getColorFromTemperature(preventivo.temperatura)}`}
          ></span>
          <span className="font-semibold">
            {transformTimestampToLocaleDateAndTime(
              preventivo.first_saved_at,
              false,
              true,
            )}
          </span>
          <span className="flex-1 overflow-hidden overflow-ellipsis whitespace-nowrap capitalize">
            <Link to={`/clienti/${preventivo?.cliente?._id}/panoramica`}>
              {preventivo?.cliente?.nome}
            </Link>
          </span>
          <span>
            <NumberFormatter
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              //@ts-ignore
              value={preventivo.price_total?.toFixed(2) || 0}
            />{" "}
            €
          </span>
          <Link to={`/preventivi/visualizza/${preventivo._id}`}>
            <Button
              variant="filled"
              radius={100}
              className="ml-0 flex h-7 w-7 items-center justify-center p-0"
            >
              <IconEye className="h-4 w-4" />
            </Button>
          </Link>
        </WidgetList>
      ))
    ) : (
      <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
        Nessun preventivo recente.
      </p>
    )
  ) : (
    <Loader
      color="blue"
      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      type="dots"
    />
  );

  return (
    <WidgetBox className="min-h-72 bg-white">
      <div className="mb-4 flex justify-between gap-2">
        <h2 className="font-bold uppercase">Ultimi preventivi</h2>
        <Link to={link} className="underline">
          <Button variant="outline" h={28} className="rounded-full !py-0 px-3">
            Vedi tutti
          </Button>
        </Link>
      </div>
      {displayLatestPreventivi}
    </WidgetBox>
  );
};

export default LatestPreventivi;
