import { gql } from "@apollo/client";
import { User } from "../features/users/UsersSlice";

export interface UsersResponse {
  users: User[];
}

export interface UsersQuery {
  email?: string;
  id?: string;
}

export const queryUsers = ({ email, id }: UsersQuery) => ({
  document: gql`
    query ($id: String) {
      users(query: { id: $id }) {
        _id
        id
        custom_data {
          role
          name
        }
        data {
          email
        }
      }
    }
  `,
  variables: { email, id },
});
