import { Anchor, Button, Loader } from "@mantine/core";
import { IconPrinter } from "@tabler/icons-react";

interface IPdfButtonProps {
  url: string | null;
  loading: boolean;
  error: Error | null;
  isIconOnly: boolean;
}

const PdfButton = ({ url, isIconOnly, error, loading }: IPdfButtonProps) => {
  return !loading ? (
    <Anchor href={`${url}`} target="_blank">
      <Button
        variant={isIconOnly ? "transparent" : "outline"}
        radius="xl"
        className={`${isIconOnly ? "flex h-8 w-8 items-center justify-center rounded-full p-0 hover:bg-slate-100" : ""}`}
        leftSection={!isIconOnly ? <IconPrinter size={18} /> : null}
        disabled={error ? true : false}
      >
        {!isIconOnly ? "Stampa PDF" : <IconPrinter className="h-5 w-5" />}
      </Button>
    </Anchor>
  ) : (
    <Loader color="blue" type="dots" />
  );
};

export default PdfButton;
