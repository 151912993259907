import { IconAlertSquareRoundedFilled } from "@tabler/icons-react"

type PreventivoModalAlertProps = {
  type: "error" | "warn"
  children: React.JSX.Element
}

const PreventivoModalAlert = ({
  type,
  children,
}: PreventivoModalAlertProps) => {
  const className = type === "error" ? "text-[#e64980]" : "text-warm"
  return (
    <div className="mb-8">
      <IconAlertSquareRoundedFilled
        className={`mx-auto mb-3 h-9 w-9 ${className}`}
      />
      {children}
    </div>
  )
}

export default PreventivoModalAlert
