import TableWrapper from "../../Layout/TableWrapper/TableWrapper"
import PreventiviTable from "../../components/Table/PreventiviTable"
import TableActionsWrapper from "../../Layout/TableActionsWrapper/TableActionsWrapper"
import { Link } from "react-router-dom"
import { Button } from "@mantine/core"
import { IconFilePlus } from "@tabler/icons-react"

const Preventivi = () => {
  return (
    <>
      <TableActionsWrapper>
        <Link to="/preventivi/nuovo">
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconFilePlus size={18} />}
          >
            Nuovo preventivo
          </Button>
        </Link>
      </TableActionsWrapper>
      <TableWrapper>
        <PreventiviTable />
      </TableWrapper>
    </>
  )
}

export default Preventivi
