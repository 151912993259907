import VeicoliTable from "../../components/Admin/AdminTable/VeicoliTable"
import SelectListini from "../../components/Preventivo/PreventivoInputs/SelectListini"
import SelectModello from "../../components/Preventivo/PreventivoInputs/SelectModello"
import SelectAlimentazione from "../../components/Preventivo/PreventivoInputs/SelectAlimentazione"
import AdminVeicoloForm from "../../components/Form/AdminVeicoloForm"
import { useState } from "react"
import { useGetVeicoliQuery } from "../../features/api/apiSlice"
import { CloseButton, Container, Input } from "@mantine/core"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  selectAdminCurrentVeicolo,
  setAdminCurrentVeicolo,
} from "../../features/ui/uiSlice"
import {
  selectEngine,
  selectListino,
  selectModel,
} from "../../features/preventivo/preventivoSlice"

const AdminVeicoli = () => {
  const dispatch = useAppDispatch()
  const currentVeicolo = useAppSelector(selectAdminCurrentVeicolo)
  const listino = useAppSelector(selectListino)
  const model = useAppSelector(selectModel)
  const engine = useAppSelector(selectEngine)
  const { data, isLoading } = useGetVeicoliQuery({
    limit: 10000,
    engine: engine,
    model: model,
    listino: listino,
  })
  const [value, setValue] = useState("")
  const isVeicoloDataEmpty = data?.veicolis && data?.veicolis.length === 0

  const filteredData =
    value !== "" && data?.veicolis
      ? data.veicolis.filter((veicolo) => {
          const searchValue = value.toLowerCase()
          return (
            (veicolo.id &&
              veicolo.id.toString().toLowerCase().includes(searchValue)) ||
            (veicolo.description &&
              veicolo.description.toLowerCase().includes(searchValue))
          )
        })
      : data?.veicolis || []

  const handleClearSearch = () => {
    setValue("")
    dispatch(setAdminCurrentVeicolo(null))
  }

  // useEffect(() => {
  //   setValue("")
  //   dispatch(setAdminCurrentVeicolo(null))
  //   dispatch(setListino(""))
  //   dispatch(setModel(""))
  // }, [])

  return (
    <>
      <Container size="100%" h={76} className="bg-cm-bg"></Container>
      <Container size="100%" className="bg-white px-10 py-5">
        <div className="grid grid-cols-[70%_30%] gap-6">
          <div>
            <div className="grid grid-cols-3 gap-6 mb-6">
              <SelectModello />
              <SelectListini />
              <SelectAlimentazione />
            </div>
            {!isVeicoloDataEmpty && (
              <>
                <div className="bg-slate-600 px-6 py-6 rounded-t-xl">
                  <Input
                    placeholder="Cerca per ID o descrizione"
                    value={value}
                    onChange={(event) => setValue(event.currentTarget.value)}
                    rightSectionPointerEvents="all"
                    styles={{
                      input: {
                        height: 44,
                        borderRadius: 12,
                      },
                    }}
                    rightSection={
                      <CloseButton
                        aria-label="Pulisci input"
                        onClick={handleClearSearch}
                        style={{ display: value ? undefined : "none" }}
                      />
                    }
                  />
                </div>
                <VeicoliTable
                  data={filteredData}
                  isLoading={isLoading}
                  adminCurrentVeicolo={currentVeicolo}
                />
              </>
            )}
          </div>
          {!isVeicoloDataEmpty && (
            <div className="rounded-xl shadow-md bg-white px-6 pb-10 border">
              <AdminVeicoloForm currentVeicolo={currentVeicolo || null} />
            </div>
          )}
        </div>
      </Container>
    </>
  )
}

export default AdminVeicoli
