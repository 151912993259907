import { TextInput } from "@mantine/core"
import { IconCircleCheckFilled } from "@tabler/icons-react"

const SelectAgente = ({ agente }: { agente: string | undefined }) => {
  return (
    <div>
      <TextInput
        disabled
        value={agente || ""}
        label="Venditore"
        radius={12}
        styles={{ input: { height: 44 } }}
        rightSection={
          (agente || "") && (
            <IconCircleCheckFilled size={18} className="text-cm-blue" />
          )
        }
      />
    </div>
  )
}

export default SelectAgente
