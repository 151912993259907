import PreventivoSaveButton from "../../../pages/Preventivi/NewPreventivo/PreventivoSaveButton/PreventivoSaveButton"
import { Link } from "react-router-dom"
import { Button, Flex } from "@mantine/core"
import { IconSquareRoundedX } from "@tabler/icons-react"
import { PreventivoSaveButtonProps } from "../../../pages/Preventivi/NewPreventivo/PreventivoSaveButton/PreventivoSaveButton"

interface PreventivoActionsProps extends PreventivoSaveButtonProps {
  preventivoId: string
  position?: "top" | "bottom"
}

const PreventivoActions = ({
  saveLabel,
  preventivoId,
  isSaveLoading,
  isSavingEnabled,
  position = "top",
  handleSavePreventivo,
}: PreventivoActionsProps) => {
  const wrapperClassname =
    position === "top" ? "sticky top-12 z-30 bg-[#f4f4f4]" : ""
  const wrapperPadding = position === "top" ? "px-10 py-5" : "py-10"
  const cancelLink = preventivoId
    ? `/preventivi/visualizza/${preventivoId}`
    : "/preventivi"

  return (
    <div className={wrapperClassname}>
      <Flex justify="end" className={wrapperPadding} gap={20}>
        <Link to={cancelLink}>
          <Button
            radius={50}
            variant="outline"
            color="gray"
            leftSection={<IconSquareRoundedX />}
          >
            Annulla
          </Button>
        </Link>
        <PreventivoSaveButton
          saveLabel={saveLabel}
          isSaveLoading={isSaveLoading}
          isSavingEnabled={isSavingEnabled}
          handleSavePreventivo={handleSavePreventivo}
        />
      </Flex>
    </div>
  )
}

export default PreventivoActions
