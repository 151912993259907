import NavigationItem from "./NavigationItem/NavigationItem"
import { NavigationItemType } from "./navigationTypes"
import {
  IconAddressBook,
  IconFile,
  IconHome,
  IconShieldLock,
  IconWriting,
} from "@tabler/icons-react"

interface NavigationProps {
  isIconOnlyVersion: boolean
  isAdmin: boolean
}

const Navigation = ({ isIconOnlyVersion, isAdmin }: NavigationProps) => {
  const iconClasses = isIconOnlyVersion
    ? "h-5 w-5 text-white"
    : "h-6 w-6 text-slate-400"

  const NAVIGATION_ITEMS: NavigationItemType[] = [
    {
      destination: "/",
      icon: <IconHome className={iconClasses} />,
      label: "Home",
    },
    {
      destination: "/preventivi",
      icon: <IconFile className={iconClasses} />,
      label: "Preventivi",
    },
    {
      destination: "/contratti",
      icon: <IconWriting className={iconClasses} />,
      label: "Contratti",
    },
    {
      destination: "/clienti",
      icon: <IconAddressBook className={iconClasses} />,
      label: "Clienti",
    },
    {
      destination: "/admin/",
      icon: <IconShieldLock className={iconClasses} />,
      label: "Area Admin",
    },
  ]
  const displayClass = isIconOnlyVersion ? "hidden" : "inline-block text-sm"
  const navigationItems = !isAdmin
    ? NAVIGATION_ITEMS.filter((item) => item.label !== "Area Admin")
    : NAVIGATION_ITEMS

  return (
    <nav>
      <ul className="flex flex-col gap-3">
        {navigationItems.map((navigationItem, index) => {
          return (
            <NavigationItem
              key={index}
              destination={navigationItem.destination}
              isSidebarCollapsed={isIconOnlyVersion}
              tooltipLabel={navigationItem.label}
              tooltipVisible={isIconOnlyVersion}
            >
              {navigationItem.icon}
              <span className={displayClass}>{navigationItem.label}</span>
            </NavigationItem>
          )
        })}
      </ul>
    </nav>
  )
}

export default Navigation
