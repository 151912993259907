import Step3 from "../NewPreventivo/Step3/Step3"
import TotalDetails from "../../../components/Preventivo/TotalBox/TotalDetails/TotalDetails"
import SelectAgente from "../../../components/Preventivo/PreventivoInputs/SelectAgente"
import SelectCliente from "../../../components/Preventivo/PreventivoInputs/SelectCliente"
import PreventivoAlert from "../../../components/Preventivo/PreventivoAlert/PreventivoAlert"
import useAuthUserData from "../../../hooks/useAuthUserData"
import NotificationForm from "../../../components/Form/NotificationForm"
import usePreventivoData from "../../../hooks/usePreventivoData"
import ViewPreventivoActions from "./ViewPreventivoActions"
import PreventivoNotificationItem from "../../../components/Preventivo/PreventivoNotificationItem/PreventivoNotificationItem"
import { saveAs } from "file-saver"
import { modals } from "@mantine/modals"
import { Container } from "@mantine/core"
//@ts-ignore
import { useScreenshot } from "use-react-screenshot"
import { useNavigate, useParams } from "react-router-dom"
import { getColorFromTemperature } from "../../../utils/utils"
import { Page, pdf, Document, Image } from "@react-pdf/renderer"
import { useEffect, useRef, useState } from "react"
import {
  useDeletePreventiviMutation,
  useLazyGetListiniForModelloQuery,
  useLazyGetVeicoloQuery,
} from "../../../features/api/apiSlice"
import {
  deleteModalProps,
  onModalDelete,
} from "../../../components/Modal/ModalDelete/ModalDelete"

const ViewPreventivo = () => {
  const ref = useRef(null)
  const navigate = useNavigate()
  let { preventivoId } = useParams()
  const { authUser } = useAuthUserData()
  const [getVeicolo] = useLazyGetVeicoloQuery()
  const [image, takeScreenShot] = useScreenshot()
  const [deletePreventivi] = useDeletePreventiviMutation()
  const [isMarginAlertShow, setIsMarginAlertShow] = useState(false)
  const [getListiniForModello] = useLazyGetListiniForModelloQuery()
  const [isListinoAlertShow, setIsListinoAlertShow] = useState(false)
  const {
    data,
    model,
    sconti,
    servizi,
    permute,
    veicolo,
    listino,
    accessori,
    incentivi,
    optionals,
    temperatura,
    permuteTotal,
    dataNotifications,
  } = usePreventivoData({ preventivoId })

  const handleRemovePreventivo = () => {
    modals.openConfirmModal({
      ...deleteModalProps("preventivo", "preventivi", [
        preventivoId,
      ] as string[]),
      onConfirm: () =>
        onModalDelete({
          selectedIds: [preventivoId] as string[],
          deleteFunction: deletePreventivi,
          singularElement: "preventivo",
          pluralElement: "preventivi",
          navigate: navigate,
          isIdFromParams: true,
        }),
    })
  }

  const handleNotificationClick = () => {
    modals.open({
      padding: 0,
      withCloseButton: false,
      size: "70%",
      radius: 26,
      centered: true,
      children: (
        <NotificationForm preventivoId={preventivoId} authUser={authUser} />
      ),
    })
  }

  const filterActiveNotifications = dataNotifications?.notifications.filter(
    (notification) => notification.dismissed_at === null,
  )

  const handleScreenshotToPdf = async () => {
    const img = await takeScreenShot(ref.current)

    if (!img) {
      alert("Screenshot non riuscito.")
      return
    }

    const imgWidth = 595.28
    const imgHeight =
      ref.current !== null &&
      //@ts-ignore
      (imgWidth / ref.current.clientWidth) * ref.current.clientHeight

    const doc = (
      <Document>
        <Page
          size={{ width: imgWidth, height: imgHeight || 0 }}
          style={{ padding: 0 }}
        >
          <Image src={img} style={{ width: "100%", height: "auto" }} />
        </Page>
      </Document>
    )

    //@ts-ignore
    const asPdf = pdf([])
    asPdf.updateContainer(doc)

    try {
      const blob = await asPdf.toBlob()
      saveAs(blob, `${preventivoId}.pdf`)
    } catch (error) {
      console.error("Errore nel generare il PDF:", error)
    }
  }

  const fetchVeicolo = async () => {
    try {
      const veicoloQuery = await getVeicolo({
        id: veicolo?.id || "",
        listino: veicolo?.listino || "",
      }).unwrap()

      const isVeicoloMarginDefined = veicolo?.margine_base
      const isVeicoloQueryMarginDefined = veicoloQuery?.veicoli?.margine_base

      if (
        isVeicoloMarginDefined &&
        isVeicoloQueryMarginDefined &&
        veicoloQuery.veicoli.margine_base !== veicolo?.margine_base
      ) {
        setIsMarginAlertShow(true)
      }
    } catch (error) {
      console.error("Error fetching veicolo data:", error)
    }
  }

  const fetchListiniForModello = async () => {
    try {
      const listiniForModelloQuery = await getListiniForModello({
        model: model,
      }).unwrap()

      const latestListino =
        listiniForModelloQuery.listini[
          listiniForModelloQuery.listini.length - 1
        ]

      if (latestListino !== listino) {
        setIsListinoAlertShow(true)
      }
    } catch (error) {
      console.error("Error fetching listino data:", error)
    }
  }

  useEffect(() => {
    if (preventivoId) {
      setTimeout(() => {
        fetchVeicolo()
        fetchListiniForModello()
      }, 501)
    }
  }, [model, listino])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <ViewPreventivoActions
        data={data}
        preventivoId={preventivoId}
        handleScreenshotToPdf={handleScreenshotToPdf}
        handleRemovePreventivo={handleRemovePreventivo}
        handleNotificationClick={handleNotificationClick}
      />
      <Container
        size="100%"
        px="40"
        pt="40"
        pb="80"
        className="flex items-start gap-11 bg-white"
        ref={ref}
      >
        <div className="w-full">
          {isMarginAlertShow && (
            <PreventivoAlert
              text="Il margine base del veicolo selezionato è cambiato e verrà aggiornato automaticamente."
              type="error"
            />
          )}
          {isListinoAlertShow && (
            <PreventivoAlert
              text="E' disponibile un nuovo listino per il modello selezionato."
              type="warn"
            />
          )}
          <div className="mb-7 flex items-start justify-between gap-3">
            <div className="flex items-start gap-5">
              <SelectCliente disabled={true} />
              <SelectAgente agente={data?.preventivi?.agente?.name} />
              {filterActiveNotifications && (
                <PreventivoNotificationItem
                  notifications={filterActiveNotifications}
                />
              )}
            </div>

            <div>
              <span className="block text-sm font-medium">Temperatura</span>
              <div className="flex h-11 items-center gap-3 rounded-xl bg-slate-200 px-3">
                <span
                  //@ts-ignore
                  className={`h-3 w-3 rounded-full ${getColorFromTemperature(temperatura)}`}
                ></span>
                <span>{temperatura}</span>
              </div>
            </div>
          </div>

          <Step3
            veicolo={veicolo}
            optionals={optionals}
            accessori={accessori}
            servizi={servizi}
            incentivi={incentivi}
            permuteTotal={permuteTotal}
            permute={permute}
            isReadOnly={true}
            sconti={sconti}
          />
        </div>
        <div className="sticky top-24 min-w-80">
          <TotalDetails />
        </div>
      </Container>
    </>
  )
}

export default ViewPreventivo
