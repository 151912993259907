import { Container } from "@mantine/core";
import WIP from "../../components/WIP/WIP";

const Contratti = () => {
  return (
    <Container size="100%" mt={76}>
      <WIP />
    </Container>
  );
};

export default Contratti;
