import { Button, Tooltip } from "@mantine/core"
import Navigation from "./Navigation/Navigation"
import { useApp } from "../../components/RealmApp"
import useSignOut from "react-auth-kit/hooks/useSignOut"
import { IconArrowBarLeft, IconFilePlus } from "@tabler/icons-react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import AccountMenu from "./AccountMenu/AccountMenu"
import {
  selectIsSidebarCollapsed,
  setSidebarCollapsed,
} from "../../features/ui/uiSlice"
import { Link, useNavigate } from "react-router-dom"
import useGetNotifications from "../../hooks/useGetNotifications"
import useAuthUserData from "../../hooks/useAuthUserData"

const Sidebar = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isSidebarCollapsed = useAppSelector(selectIsSidebarCollapsed)
  const app = useApp()
  const signOut = useSignOut()
  const { authUserName, authUserId, isAdmin } = useAuthUserData()
  const { activeNotifications } = useGetNotifications({ idAgente: authUserId })
  const handleLogout = async () => {
    //@ts-ignore
    await app.logOut()
    signOut()
    navigate("/login")
  }

  const handleOnBurgerClick = () => {
    dispatch(setSidebarCollapsed())
  }

  const sidebarWidth = isSidebarCollapsed ? "" : "w-[18%]"
  const newPreventivoBtnClasses = isSidebarCollapsed
    ? "w-9 h-9 rounded-full flex items-center justify-center p-0"
    : "text-md h-10 rounded-full px-5 w-full flex items-center justify-center"
  const collapsableMenuLabel = isSidebarCollapsed
    ? "Espandi menu"
    : "Comprimi menu"

  return (
    <aside
      className={`fixed left-0 top-0 z-50 h-screen bg-slate-700 px-3 py-3 shadow-md ${sidebarWidth}`}
    >
      <div className="flex h-full flex-col justify-between">
        <div>
          <div className="mb-10 flex flex-col gap-2">
            <Tooltip label={collapsableMenuLabel} position="right" className="">
              <IconArrowBarLeft
                className={`mb-0 ml-auto h-4 w-4 cursor-pointer text-slate-400 ${isSidebarCollapsed ? "mx-auto -scale-100" : ""}`}
                color="white"
                onClick={handleOnBurgerClick}
                aria-label="Toggle navigation"
              />
            </Tooltip>
            {/* <Logo isIconOnlyVersion={isSidebarCollapsed} /> */}
            <div className="mt-12">
              <Tooltip
                label="Nuovo preventivo"
                position="right"
                className={isSidebarCollapsed ? "" : "hidden"}
              >
                <Link to="/preventivi/nuovo" className="block">
                  <Button className={newPreventivoBtnClasses}>
                    {isSidebarCollapsed ? (
                      <IconFilePlus className="h-5 w-5" />
                    ) : (
                      <>
                        <IconFilePlus className="mr-1 h-5 w-5" />
                        Nuovo preventivo
                      </>
                    )}
                  </Button>
                </Link>
              </Tooltip>
            </div>
          </div>
          <Navigation
            isAdmin={isAdmin}
            isIconOnlyVersion={isSidebarCollapsed}
          />
        </div>

        <div className="mb-4 flex justify-center">
          <AccountMenu
            onClick={handleLogout}
            isSidebarCollapsed={isSidebarCollapsed}
            notificationsLength={activeNotifications?.length || 0}
            currentUserName={authUserName}
          />
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
