const CustomerDataRow = ({
  rowLabel,
  rowValue,
}: {
  rowLabel: string;
  rowValue: string | undefined;
}) => {
  return (
    <div>
      <span className="text-xs font-semibold">{rowLabel}: </span>
      <span className="text-xs">{rowValue}</span>
    </div>
  );
};

export default CustomerDataRow;
