import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { User } from "../features/users/UsersSlice";

const useAuthUserData = () => {
  const authUser: User | null = useAuthUser();
  const authUserId = authUser?._id;
  const authUserName = authUser?.custom_data.name;
  const isAdmin = authUser?.custom_data.role?.toLocaleLowerCase() === "admin";

  return { authUser, authUserName, authUserId, isAdmin };
};

export default useAuthUserData;
