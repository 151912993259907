import { Select } from "@mantine/core"
import { PAYMENTS_TYPES } from "../../../queries/preventivi"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  selectModalitaPagamento,
  setModalitaPagamento,
} from "../../../features/preventivo/preventivoSlice"

const SelectPaymentMethod = ({ isReadOnly }: { isReadOnly: boolean }) => {
  const dispatch = useAppDispatch()

  const handlePaymentMethodChange = (paymentMethod: string | null) => {
    paymentMethod && dispatch(setModalitaPagamento(paymentMethod))
  }

  const currentValue = useAppSelector(selectModalitaPagamento)

  return (
    <Select
      data={PAYMENTS_TYPES.map((p) => p.label)}
      label="Modalità di pagamento"
      placeholder="Modalità di pagamento"
      value={currentValue}
      onChange={(sel) => handlePaymentMethodChange(sel)}
      disabled={isReadOnly}
      className="mb-5"
      styles={{
        input: {
          height: 44,
          borderRadius: 12,
        },
      }}
    />
  )
}

export default SelectPaymentMethod
