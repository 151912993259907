import { Select } from "@mantine/core"
import { useGetAnagraficheQuery } from "../../../features/api/apiSlice"
import { IconCircleCheckFilled } from "@tabler/icons-react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  selectCliente,
  setCliente,
} from "../../../features/preventivo/preventivoSlice"

const SelectCliente = ({ disabled }: { disabled?: boolean }) => {
  const dispatch = useAppDispatch()
  const { data } = useGetAnagraficheQuery({ limit: 200 })
  const currentCliente = useAppSelector(selectCliente)
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const idCliente = params.get("id_cliente")
  return (
    <Select
      required
      label="Cliente"
      placeholder="Seleziona il cliente"
      searchable
      value={currentCliente?._id ?? idCliente ?? null}
      disabled={disabled}
      data={
        data?.anagraficas?.map(({ _id, nome }) => ({
          value: _id,
          label: `${nome}`,
        })) || []
      }
      rightSection={
        (currentCliente?._id || idCliente) && (
          <IconCircleCheckFilled size={18} className="text-cm-blue" />
        )
      }
      onChange={(sel) => {
        const cliente = data?.anagraficas.find(({ _id }) => _id === sel)
        cliente && dispatch(setCliente(cliente))
      }}
      styles={{
        input: { height: 44 },
      }}
      className="mb-5"
      radius={12}
    />
  )
}

export default SelectCliente
