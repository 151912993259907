import { Checkbox } from "@mantine/core"
import { Veicolo } from "../../../queries/veicoli"

interface IAdminVeicoloRowProps {
  veicolo: Veicolo
  selectedVeicolo: string
  onChange: () => void
}

const AdminVeicoloRow = ({
  veicolo,
  selectedVeicolo,
  onChange,
}: IAdminVeicoloRowProps) => {
  return (
    <div className="flex items-center gap-3 mb-4 pb-4 border-b">
      <span className="flex-none">
        <Checkbox
          size="md"
          checked={selectedVeicolo === veicolo._id ? true : false}
          onChange={onChange}
        />
      </span>
      <span>{veicolo.id}</span>
      <span>{veicolo.description}</span>
    </div>
  )
}

export default AdminVeicoloRow
