import { Textarea } from "@mantine/core"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  selectNote,
  setNote,
} from "../../../features/preventivo/preventivoSlice"

const TextAreaNotes = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const dispatch = useAppDispatch()
  const [notesText, setNotesText] = useState<string>("")
  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setNotesText(event.target.value)
  }

  const handleTextAreaBlur = () => {
    dispatch(setNote(notesText))
  }

  const currentNotes = useAppSelector(selectNote)

  return (
    <Textarea
      label="Note"
      autosize
      placeholder="Inserisci delle note sul preventivo"
      minRows={6}
      defaultValue={currentNotes}
      onChange={handleTextAreaChange}
      onBlur={handleTextAreaBlur}
      radius={12}
      disabled={isReadOnly}
    />
  )
}

export default TextAreaNotes
