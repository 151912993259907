import { Container } from "@mantine/core"

const TableWrapper = ({ children }: { children: React.JSX.Element }) => {
  return (
    <Container size="100%" className="px-10 pt-5 bg-white">
      <Container
        size="100%"
        className="rounded-xl bg-transparent p-0 shadow-md"
      >
        {children}
      </Container>
    </Container>
  )
}

export default TableWrapper
