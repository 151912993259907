import OptionPriceEdit from "./OptionPriceEdit"
import { Option } from "../../queries/veicoli"
import { updateOption } from "../../features/preventivo/preventivoSlice"
import { useAppDispatch } from "../../app/hooks"
import { OptionalsSummary } from "../Preventivo/Summaries/OptionsSummary"
import { NumberFormatter, NumberInput } from "@mantine/core"

interface OptionListItemProps extends Partial<OptionalsSummary> {
  optional: Option
  onEditPrice: (id: string) => void
  onConfirmPrice: () => void
}

const OptionPrice = ({
  isInputShown,
  optional,
  isOptionReadOnly,
  onEditPrice,
  onConfirmPrice,
}: OptionListItemProps) => {
  const dispatch = useAppDispatch()

  return (
    <>
      {isInputShown?.state && isInputShown?.id === optional.id ? (
        <NumberInput
          value={optional.price}
          onChange={(value) =>
            dispatch(
              updateOption({
                ...optional,
                price: +value || 0,
              }),
            )
          }
          className="block rounded-full px-3 py-1 text-right font-semibold"
          w={128}
          rightSection={<span>€</span>}
          min={0}
          decimalSeparator=","
          thousandSeparator="."
          step={0.01}
          autoFocus
        />
      ) : (
        <span className="block min-w-24 rounded-full bg-slate-700 px-3 py-1 text-right font-semibold text-white">
          <NumberFormatter
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            value={optional.price.toFixed(2)}
          />
          €
        </span>
      )}
      <OptionPriceEdit
        optional={optional}
        isOptionReadOnly={isOptionReadOnly}
        isInputShown={isInputShown || { id: "", state: false }}
        onEditPrice={onEditPrice}
        onConfirmPrice={onConfirmPrice}
      />
    </>
  )
}

export default OptionPrice
