import { gql } from "@apollo/client";
import { BSON } from "realm-web";

export interface Testo {
  _id?: string;
  label: string;
  testo?: string;
}

export interface GetTestiResponse {
  testis: Testo[];
}

export interface GetTestoResponse {
  testi: Testo;
}

export interface TestiQuery {}

export interface TestoQuery {
  id: string;
}

export const queryTesti = ({}: TestiQuery) => ({
  document: gql`
    query {
      testis {
        _id
        label
        testo
      }
    }
  `,
});

export const queryTesto = ({ id }: TestoQuery) => ({
  document: gql`
    query {
      preventivi(query: {_id: "${new BSON.ObjectID(id)}"}) {
        _id
        label,
        testo
      }
    }
  `,
});

export const mutationTesto = (testo: Testo) => ({
  document: gql`
    mutation {
      upsertOneTesti(
        data: {
          label: "${testo.label}",
          testo: "${testo.testo}",
        },
        query: {
          _id: "${new BSON.ObjectID(testo._id)}"
        }) {
        _id
      }
    }
  `,
});

export const deleteTesti = (ids: string[]) => ({
  document: gql`
    mutation {
      deleteManyTestis (
        query: {
          _id_in: [${ids.map((id) => `"${new BSON.ObjectID(id)}"`).join(",")}]
        }
      ) {deletedCount}
    } 
  `,
});
