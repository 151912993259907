import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface AnagraficaState {
  uselessValue: 0;
}

const initialState: AnagraficaState = {
  uselessValue: 0,
};

export const anagraficaSlice = createSlice({
  name: "anagrafica",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    uselessAction: (state) => {
      state.uselessValue = 0;
    },
  },
});

export const { uselessAction } = anagraficaSlice.actions;

export const selectUselessValue = (state: RootState) =>
  state.anagrafica.uselessValue;

export default anagraficaSlice.reducer;
