import { Loader } from "@mantine/core";
import { useGetPreventiviQuery } from "../../../features/api/apiSlice";
import WidgetBox from "../WidgetBox/WidgetBox";
import useAuthUserData from "../../../hooks/useAuthUserData";
import { getLast30DaysPreventivi } from "../../../utils/utils";

const ActivePreventivi = () => {
  const { authUserId, isAdmin } = useAuthUserData();
  const { data, isLoading } = useGetPreventiviQuery({
    idAgente: isAdmin ? undefined : authUserId,
  });

  const last30DaysPreventivi =
    !isLoading && data?.preventivis
      ? getLast30DaysPreventivi(data.preventivis)
      : 0;

  const activePreventiviToDisplay = !isLoading ? (
    <>
      <div className="mb-5 text-center">
        <span className="block text-6xl font-bold text-slate-800">
          {data?.preventivis.length || 0}
        </span>
        <span className="text-sm font-semibold text-slate-400">
          Preventivi attivi
        </span>
      </div>
      <div className="rounded-lg bg-slate-100 px-2 py-3">
        <div className="mb-5 text-center">
          <span className="mb-1 block text-sm font-semibold">
            Totale preventivi
          </span>
          <span className="block text-xs text-slate-400">
            {data?.preventivis.length || 0} preventivi
          </span>
        </div>
        <div className="text-center">
          <span className="mb-1 block text-sm font-semibold">
            Ultimi 30 giorni
          </span>
          <span className="block text-xs text-slate-400">
            {last30DaysPreventivi || 0} preventivi
          </span>
        </div>
      </div>
    </>
  ) : (
    <Loader
      color="blue"
      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      type="dots"
    />
  );

  return (
    <WidgetBox className="relative min-h-72 w-full bg-white">
      <div className="flex h-full flex-col justify-between">
        <span className="mb-5 block text-center font-bold uppercase">
          Preventivi
        </span>
        {activePreventiviToDisplay}
      </div>
    </WidgetBox>
  );
};

export default ActivePreventivi;
