import { Select } from "@mantine/core"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectModelli } from "../../../features/settings/settingsSlice"
import {
  selectModel,
  setModel,
} from "../../../features/preventivo/preventivoSlice"
import { IconCircleCheckFilled } from "@tabler/icons-react"

const SelectModello = () => {
  const dispatch = useAppDispatch()
  const modelliList = useAppSelector(selectModelli)
  const currentModel = useAppSelector(selectModel)

  return (
    <Select
      required
      label="Modello"
      placeholder="Seleziona il modello"
      value={currentModel ? currentModel : null}
      data={modelliList}
      onChange={(sel) => dispatch(setModel(sel || ""))}
      radius={12}
      styles={{ input: { height: 44 } }}
      rightSection={
        currentModel && (
          <IconCircleCheckFilled size={18} className="text-cm-blue" />
        )
      }
    />
  )
}

export default SelectModello
