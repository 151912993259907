import ModalLayout from "../Modal/ModalLayout/ModalLayout"
import {
  Group,
  NumberInput,
  Radio,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core"
import { EOptionType, Option } from "../../queries/veicoli"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import { usePutOptionMutation } from "../../features/api/apiSlice"
import { notifications } from "@mantine/notifications"
import { modals } from "@mantine/modals"

const AdminAccessoriForm = ({
  currentAccessorio,
  onCloseClick,
}: {
  currentAccessorio?: Option
  onCloseClick: () => void
}) => {
  const [putOption] = usePutOptionMutation()
  const { register, handleSubmit, watch, setValue } = useForm()

  useEffect(() => {
    if (currentAccessorio) {
      Object.entries(currentAccessorio).forEach(([key, value]) => {
        setValue(key as keyof Option, value)
      })
    }
  }, [currentAccessorio, setValue])

  const onSubmit = (data: any) => {
    const accessorio: Option = {
      ...currentAccessorio,
      id: data.id,
      label: data.label,
      description: data.description,
      price: data.price,
      margine_base: data.margine_base,
      categoria: data.categoria,
      fornitore: data.fornitore,
      cm_type: EOptionType.ACCESSORI,
      is_updatable: data.is_updatable === "true",
    }

    putOption(accessorio)
      .unwrap()
      .then(() => {
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: "Successo!",
          radius: "md",
          message: !currentAccessorio
            ? "Accessorio creato."
            : "Accessorio modificato.",
          color: "teal",
          loading: false,
        })
        modals.closeAll()
      })
      .catch((error: Error) => {
        console.error(error)
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: "Errore!",
          radius: "md",
          message: !currentAccessorio
            ? "Errore nella creazione dell'accessorio. Riprova."
            : "Errore nella modifica dell'accessorio. Riprova.",
          color: "pink",
          loading: false,
        })
      })
    modals.closeAll()
  }

  const modalTitle = currentAccessorio
    ? "Modifica accessorio"
    : "Crea nuovo accessorio"

  return (
    <ModalLayout
      title={modalTitle}
      onCloseClick={onCloseClick}
      onConfirmClick={handleSubmit(onSubmit)}
    >
      <form>
        <div className="mb-5 flex flex-col gap-2">
          <div className="grid grid-cols-[0.2fr_0.8fr] gap-3">
            <TextInput
              required
              {...register("id", { required: true })}
              label={"ID"}
              defaultValue={currentAccessorio ? currentAccessorio.id : ""}
              className="w-full"
              placeholder="Inserisci un ID"
              styles={{ input: { height: 44 } }}
            />
            <TextInput
              required
              {...register("label", { required: true })}
              label={"Label"}
              placeholder="Inserisci una label"
              defaultValue={currentAccessorio ? currentAccessorio.label : ""}
              styles={{ input: { height: 44 } }}
              className="w-full"
            />
          </div>
          <div className="w-full">
            <Textarea
              {...register("description")}
              label="Descrizione"
              placeholder="Inserisci una descrizione dell'after-market"
              defaultValue={
                currentAccessorio ? currentAccessorio.description : ""
              }
            />
          </div>
          <div className="grid grid-cols-[0.2fr_0.1fr_0.7fr] gap-3">
            <NumberInput
              required
              min={0}
              thousandSeparator="."
              decimalSeparator=","
              label={"Prezzo"}
              placeholder="Inserisci un prezzo"
              onChange={(value) => setValue("price", value || 0)}
              value={watch("price")}
              rightSection={<span>€</span>}
              styles={{ input: { height: 44 } }}
              className="w-full"
            />
            <NumberInput
              label={"Margine"}
              min={0}
              max={100}
              decimalSeparator=","
              styles={{ input: { height: 44 } }}
              placeholder="Inserisci un margine"
              value={watch("margine_base")}
              onChange={(value) => setValue("margine_base", value || 0)}
              rightSection={<span>%</span>}
              className="w-full"
            />
            <Select
              label="Categoria"
              placeholder="Seleziona una categoria"
              onChange={(value) => setValue("categoria", value)}
              data={["", "AUTO", "VCL CHASSI", "RANGER", "VCL", "VCL / AUTO"]}
              value={watch("categoria")}
              styles={{ input: { height: 44 } }}
            ></Select>
          </div>
          <div className="w-full">
            <TextInput
              {...register("fornitore")}
              placeholder="Inserisci un fornitore"
              label={"Fornitore"}
              styles={{ input: { height: 44 } }}
              defaultValue={
                currentAccessorio ? currentAccessorio.fornitore : ""
              }
            />
          </div>
          <div>
            <span className="text-sm font-medium">Prezzo modificabile:</span>
            <Radio.Group
              name="is_updatable"
              value={watch("is_updatable") ?? "false"}
              onChange={(value) => setValue("is_updatable", value)}
            >
              <Group mt="md">
                <Radio value="true" label="Sì" />
                <Radio value="false" label="No" />
              </Group>
            </Radio.Group>
          </div>
        </div>
      </form>
    </ModalLayout>
  )
}

export default AdminAccessoriForm
