import SummaryBox from "../../../../components/Preventivo/SummaryBox/SummaryBox"
import MargineForm from "../../../../components/Preventivo/PreventivoInputs/MargineForm"
import PermutaForm from "../../../../components/Preventivo/PreventivoInputs/PermutaForm"
import DeliveryDate from "../../../../components/Preventivo/PreventivoInputs/DeliveryDate"
import DiscountForm from "../../../../components/Preventivo/PreventivoInputs/DiscountForm"
import InputAnticipo from "../../../../components/Preventivo/PreventivoInputs/InputAnticipo"
import TextAreaNotes from "../../../../components/Preventivo/PreventivoInputs/TextAreaNotes"
import VeicoloSummary from "../../../../components/Preventivo/Summaries/VeicoloSummary"
import OptionsSummary from "../../../../components/Preventivo/Summaries/OptionsSummary"
import PermutaSummary from "../../../../components/Preventivo/Summaries/PermutaSummary"
import ExpirationDate from "../../../../components/Preventivo/PreventivoInputs/ExpirationDate"
import SummaryVerticalBox from "../../../../components/Preventivo/SummaryVerticalBox/SummaryVerticalBox"
import SelectPaymentMethod from "../../../../components/Preventivo/PreventivoInputs/SelectPaymentMethod"
import OptionSelectComponent from "../../../../components/Preventivo/PreventivoInputs/SelectOptions"
import { Permuta, Sconto } from "../../../../queries/preventivi"
import { EOptionType, Option, Veicolo } from "../../../../queries/veicoli"

export interface Step3Props {
  veicolo: Veicolo | undefined
  optionals: Option[]
  accessori: Option[]
  servizi: Option[]
  incentivi: Option[]
  permuteTotal: number
  scontoGrandTotal?: number
  permute: Permuta[]
  isReadOnly?: boolean
  sconti: Sconto[]
}

interface IOptionsSummary {
  type: EOptionType
  label: string
  items: Option[]
  isReadOnly?: boolean
}

const Step3 = ({
  veicolo,
  optionals,
  accessori,
  servizi,
  incentivi,
  // permuteTotal,
  permute,
  isReadOnly,
  sconti,
  // scontoGrandTotal,
}: Step3Props) => {
  const permuteExits = permute.length > 0
  const optionsSummaryMapObject: IOptionsSummary[] = [
    {
      type: EOptionType.OPTIONALS,
      items: optionals,
      label: "Optionals",
    },
    {
      type: EOptionType.ACCESSORI,
      items: accessori,
      label: "Accessori after-market",
      isReadOnly: isReadOnly,
    },
    {
      type: EOptionType.SERVIZI,
      items: servizi,
      label: "Servizi",
    },
    {
      type: EOptionType.INCENTIVI,
      items: incentivi,
      label: "Incentivi",
    },
  ]

  return (
    <>
      {isReadOnly && veicolo && (
        <>
          <VeicoloSummary veicolo={veicolo} isReadOnly={isReadOnly} />
          {optionsSummaryMapObject
            .filter((option) => option.type !== EOptionType.INCENTIVI)
            .map((option, index) => (
              <OptionSelectComponent
                key={`${option.type}-${index}`}
                type={option.type}
                isReadOnly={isReadOnly}
                summaryLabel={option.label}
                isOptionReadOnly={option.isReadOnly}
              />
            ))}
        </>
      )}

      <SummaryBox label="Margine" defaultCollapsed withToggle>
        <MargineForm isReadOnly={isReadOnly} />
      </SummaryBox>

      <SummaryBox label="Sconti" withToggle>
        {sconti &&
          sconti.length > 0 &&
          sconti?.map((sconto) => {
            return (
              <DiscountForm
                key={sconto.type}
                scontoType={sconto.type}
                isReadOnly={isReadOnly || false}
              />
            )
          })}
      </SummaryBox>

      {optionsSummaryMapObject
        .filter((option) => option.type === EOptionType.INCENTIVI)
        .map((option, index) => (
          <OptionSelectComponent
            key={`${EOptionType.INCENTIVI}-${index}`}
            type={option.type}
            isReadOnly={isReadOnly}
            summaryLabel={option.label}
            isOptionReadOnly={option.isReadOnly}
            selectLabel="Seleziona gli incentivi"
          />
        ))}

      {!isReadOnly && (
        <SummaryBox label="Permute" withToggle>
          <PermutaSummary permute={permute} isReadOnly={isReadOnly} />
          <PermutaForm />
        </SummaryBox>
      )}

      {isReadOnly && permuteExits && (
        <SummaryBox label="Permute" withToggle>
          <PermutaSummary permute={permute} isReadOnly={isReadOnly} />
        </SummaryBox>
      )}

      <SummaryBox label="Anticipo e date" withToggle>
        <InputAnticipo isReadOnly={isReadOnly || false} />
        <SelectPaymentMethod isReadOnly={isReadOnly || false} />
        <ExpirationDate isReadOnly={isReadOnly || false} />
        <DeliveryDate isReadOnly={isReadOnly || false} />
      </SummaryBox>

      <TextAreaNotes isReadOnly={isReadOnly} />
    </>
  )
}

export default Step3
