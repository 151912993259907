import { useState } from "react";
import { useGetAnagraficheQuery } from "../../api/apiSlice";

const useCustomPagination = ({
  direction,
  id,
}: {
  direction?: "g" | "l";
  id: string;
}) => {
  const [prevPage, setPrevPage] = useState(0);
  const [pageOptions, setPageOptions] = useState({
    id,
    direction,
  });
  const { data, isLoading, refetch } = useGetAnagraficheQuery({
    limit: 50,
    lastItemId: pageOptions.id,
    dir: pageOptions.direction,
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  return {
    data,
    isLoading,
    refetch,
    prevPage,
    pagination,
    pageOptions,
    setPagination,
    setPageOptions,
    setPrevPage,
  };
};

export default useCustomPagination;

// DA INSERIRE EVENTUALMENTE IN TABELLA
// useEffect(() => {
//   refetch();
// }, [token]);

// useEffect(() => {
//   if (prevPage < pagination.pageIndex) {
//     setPageOptions({
//       id: `${data?.anagraficas[data?.anagraficas.length - 1]._id}`,
//       direction: "g",
//     });
//   } else if (prevPage > pagination.pageIndex) {
//     setPageOptions({
//       id: `${data?.anagraficas[0]._id}`,
//       direction: "l",
//     });
//   } else {
//     setPageOptions({
//       id: "5ca4bbcea2dd94ee58162a8f",
//       direction: "g",
//     });
//   }
//   setPrevPage(pagination.pageIndex);
// }, [pagination.pageIndex]);
