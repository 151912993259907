import { Button } from "@mantine/core"
import { IconTrash } from "@tabler/icons-react"

const OptionDelete = ({ onOptionDelete }: { onOptionDelete: () => void }) => {
  const handleDeleteOption = () => {
    onOptionDelete()
  }
  return (
    <Button
      p={4}
      onClick={handleDeleteOption}
      radius={999}
      w={24}
      h={24}
      className="bg-hot"
    >
      <IconTrash className="size-4" />
    </Button>
  )
}

export default OptionDelete
