import { Container } from "@mantine/core";
import WIP from "../../../components/WIP/WIP";

const ContrattiCliente = () => {
  return (
    <Container mt={76} size="100%">
      <WIP />
    </Container>
  );
};

export default ContrattiCliente;
