import { Button, Container, Group, Title, Text } from "@mantine/core";
import { Link } from "react-router-dom";

const NotFound = () => (
  <Container className="flex min-h-screen flex-col items-center justify-center">
    <div className="mb-1 text-6xl font-bold">404</div>
    <Title className="mb-2">Pagina non trovata.</Title>
    <Text c="dimmed" size="lg" ta="center" className="max-w-xl">
      La pagina che stai cercando non è stata trovata, forse hai commesso un
      errore nella scrittura dell'URL, oppure non è stata ancora creata.
    </Text>
    <Group justify="center">
      <Link to="/">
        <Button variant="filled" size="md" radius={50} mt={20}>
          Torna alla home
        </Button>
      </Link>
    </Group>
  </Container>
);

export default NotFound;
