import { Select } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectIva,
  setIva,
} from "../../../features/preventivo/preventivoSlice";

const SelectIva = () => {
  const currentIva = useAppSelector(selectIva);
  const IvaMockArray = [
    { value: "22", label: "Aliquota 22%" },
    { value: "4", label: "Aliquota 4%" },
    { value: "0", label: "Esente" },
  ];

  const dispatch = useAppDispatch();
  const handleIvaChange = (iva: string | null) => {
    iva && dispatch(setIva(+iva));
  };

  return (
    <Select
      placeholder="IVA"
      data={IvaMockArray || []}
      className="rounded-xl shadow-md"
      value={`${currentIva}`}
      styles={{
        input: {
          width: 200,
          height: 56,
          borderRadius: 12,
          border: "none",
          paddingLeft: 40,
        },
      }}
      onChange={(sel) => handleIvaChange(sel)}
      checkIconPosition="right"
      leftSection={<span className="text-xs font-bold">IVA</span>}
    />
  );
};

export default SelectIva;
