import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { Button } from "@mantine/core";
import PreventivoPdfRenderer from "./PreventivoPdfRenderer/PreventivoPdfRenderer";

const PDFGenerator = () => {
  return (
    <div>
      <PDFViewer>
        <PreventivoPdfRenderer />
      </PDFViewer>
      <PDFDownloadLink document={<PreventivoPdfRenderer />} fileName="preventivo.pdf">
        <Button className="bg-green-900 mt-4">Scarica PDF</Button>
      </PDFDownloadLink>
    </div>
  );
};

export default PDFGenerator;
