import { DonutChart } from "@mantine/charts";
import WidgetBox from "../WidgetBox/WidgetBox";

const LatestContratti = () => {
  const data = [
    { name: "Chiusi", value: 500, color: "#228be6" },
    { name: "Aperti", value: 500, color: "#f2f2f2" },
  ];

  return (
    <WidgetBox className="relative min-h-72 w-full bg-white">
      <div className="flex h-full flex-col justify-between">
        <span className="mb-5 block text-center font-bold uppercase">
          Contratti
        </span>
        <div className="mb-6">
          <DonutChart
            data={data}
            strokeWidth={0}
            mx="auto"
            withTooltip={false}
          />
        </div>
        <div className="rounded-lg bg-slate-100 px-2 py-3 text-center">
          <span className="mb-1 block text-xs font-semibold">
            Totale nuovi contratti
          </span>
          <span className="block text-xs text-slate-400">0 contratti</span>
        </div>
      </div>
    </WidgetBox>
  );
};

export default LatestContratti;
