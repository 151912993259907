import { configureStore } from "@reduxjs/toolkit";
import settingsReducer, { setToken } from "../features/settings/settingsSlice";
import anagraficaReducer from "../features/anagrafica/anagraficaSlice";
import preventivoReducer from "../features/preventivo/preventivoSlice";
import uiReducer from "../features/ui/uiSlice";
import { apiSlice } from "../features/api/apiSlice";
import usersReducer from "../features/users/UsersSlice";
import createStore from "react-auth-kit/createStore";
import axios from "axios";
import createRefresh from "react-auth-kit/createRefresh";
import settings from "../settings.json";

interface IUserData {
  name: string;
  uuid: string;
}

const { baseUrl } = settings;

export async function getNewTokenWithRefresh(refreshToken?: string) {
  if (refreshToken === undefined) throw new Error("No refresh token");

  try {
    const headers = {
      Authorization: `Bearer ${refreshToken}`,
    };
    const endpoint = `${baseUrl}/api/client/v2.0/auth/session`;

    const config = {
      url: endpoint,
      method: "POST",
      headers,
    };
    // @ts-ignore
    const response = await axios(config);
    store.dispatch(setToken([response.data.access_token]));
    localStorage.setItem("tokenTS", Date.now().toString());
    return response.data.access_token;
  } catch (error) {
    throw error;
  }
}

export const store = configureStore({
  reducer: {
    settings: settingsReducer,
    anagrafica: anagraficaReducer,
    preventivo: preventivoReducer,
    ui: uiReducer,
    users: usersReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export const rakStore = createStore<IUserData>({
  authName: "_auth",
  authType: "localstorage",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
  refresh: createRefresh({
    interval: 5,
    // @ts-ignore
    refreshApiCallback: async (param) => {
      try {
        const newToken = await getNewTokenWithRefresh(param.refreshToken);

        store.dispatch(setToken([newToken, param.refreshToken]));

        return {
          isSuccess: true,
          newAuthToken: newToken,
          newAuthTokenExpireIn: 6000,
          newRefreshTokenExpiresIn: 6000,
        };
      } catch (error) {
        console.error(error);
        return {
          isSuccess: false,
        };
      }
    },
  }),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
