import { PieChart } from "@mantine/charts";
import { useGetPreventiviQuery } from "../../../features/api/apiSlice";
import useAuthUserData from "../../../hooks/useAuthUserData";
import WidgetBox from "../WidgetBox/WidgetBox";
import { Loader } from "@mantine/core";

const TemperatureWidget = () => {
  const { authUserId, isAdmin } = useAuthUserData();
  const { data, isLoading } = useGetPreventiviQuery({
    idAgente: isAdmin ? undefined : authUserId,
  });
  const notEmptyPreventivi = data && data?.preventivis.length > 0;

  const temperatureColors = {
    Fredda: "#f4da27",
    Tiepida: "#ecb85e",
    Calda: "#dc5b5b",
  };

  const temperatureCounts = {};
  data?.preventivis.forEach((preventivo) => {
    const temperatura = preventivo?.temperatura;
    //@ts-ignore
    temperatureCounts[temperatura] = (temperatureCounts[temperatura] || 0) + 1;
  });

  const temperaturesObj = Object.keys(temperatureCounts).map((temperatura) => ({
    name: temperatura,
    //@ts-ignores
    value: temperatureCounts[temperatura],
    //@ts-ignore
    color: temperatureColors[temperatura] || "",
  }));

  const displayTemperatureChart = !isLoading ? (
    notEmptyPreventivi ? (
      <div className="absolute left-1/2 top-1/2 mx-auto flex w-full max-w-[80%] -translate-x-1/2 -translate-y-1/2 items-center justify-between gap-3">
        <PieChart size={200} data={temperaturesObj} strokeWidth={0} />
        <div className="w-1/2 rounded-lg bg-slate-100 p-3">
          <span className="mb-3 block text-sm font-bold uppercase">
            Temperatura
          </span>
          {temperaturesObj.map((temperature) => (
            <div key={temperature.name} className="mb-3">
              <div className="flex items-center gap-2">
                <span
                  style={{ background: temperature.color }}
                  className="block h-3 w-3 rounded-full"
                ></span>
                <span className="text-xs text-slate-400">
                  {temperature.name}
                </span>
                <span className="text-xs text-slate-700">
                  {temperature.value}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : (
      <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
        Nessun preventivo trovato.
      </p>
    )
  ) : (
    <Loader
      color="blue"
      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      type="dots"
    />
  );

  return (
    <WidgetBox className="relative min-h-72 w-full bg-white">
      <div className="mb-6 flex justify-between gap-2">
        <h2>
          <span className="font-bold uppercase">Temperatura preventivi</span>{" "}
          <span className="pl-1 text-sm font-normal text-slate-400">
            Totale: {data?.preventivis.length}
          </span>
        </h2>
      </div>
      {displayTemperatureChart}
    </WidgetBox>
  );
};

export default TemperatureWidget;
