import useAuthUser from "react-auth-kit/hooks/useAuthUser"
import TableWrapper from "../../Layout/TableWrapper/TableWrapper"
import NotificationForm from "../../components/Form/NotificationForm"
import NotificationsTable from "../../components/Table/NotificationsTable"
import TableActionsWrapper from "../../Layout/TableActionsWrapper/TableActionsWrapper"
import { User } from "../../features/users/UsersSlice"
import { modals } from "@mantine/modals"
import { Button, Flex } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons-react"

const Notifiche = () => {
  const authUser: User | null = useAuthUser()
  return (
    <>
      <TableActionsWrapper>
        <Flex gap={20}>
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconAlertCircle size={18} />}
            onClick={() => {
              modals.open({
                padding: 0,
                withCloseButton: false,
                size: "70%",
                radius: 26,
                centered: true,
                children: <NotificationForm authUser={authUser} />,
              })
            }}
          >
            Nuova notifica
          </Button>
        </Flex>
      </TableActionsWrapper>
      <TableWrapper>
        <NotificationsTable />
      </TableWrapper>
    </>
  )
}

export default Notifiche
