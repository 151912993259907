import TableWrapper from "../../../Layout/TableWrapper/TableWrapper"
import PreventiviTable from "../../../components/Table/PreventiviTable"
import { IconFilePlus } from "@tabler/icons-react"
import { Link, useParams } from "react-router-dom"
import { Button, Container } from "@mantine/core"

const PreventiviCliente = () => {
  const { customerId } = useParams()

  return (
    <>
      <Container size="100%" px="40" py="20">
        <Link to={`/preventivi/nuovo?id_cliente=${customerId}`}>
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconFilePlus size={18} />}
          >
            Nuovo preventivo
          </Button>
        </Link>
      </Container>
      <TableWrapper>
        <PreventiviTable />
      </TableWrapper>
    </>
  )
}

export default PreventiviCliente
