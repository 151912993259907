import { useState } from "react"
import { addPermuta } from "../../../features/preventivo/preventivoSlice"
import { useAppDispatch } from "../../../app/hooks"
import { YearPickerInput } from "@mantine/dates"
import { EIvaType, Permuta } from "../../../queries/preventivi"
import { IconCirclePlus, IconSquareRoundedX } from "@tabler/icons-react"
import { Button, Group, Radio, TextInput, Textarea } from "@mantine/core"

const PermutaForm = () => {
  const dispatch = useAppDispatch()
  const [permutaObject, setPermutaObject] = useState<Permuta>({
    title: "",
    targa: "",
    km: "",
    year: `${new Date().getFullYear()}`,
    price: 0,
    description: "",
    iva_type: EIvaType.ESENTE_IVA,
  })

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setPermutaObject((prevState) => {
      return {
        ...prevState,
        [event.target.id]: event.target.value,
      }
    })
  }

  const handleYearChange = (date: Date | null) => {
    setPermutaObject((prevState) => ({
      ...prevState,
      year: date ? date.getFullYear().toString() : "",
    }))
  }

  const handleIvaTypeChange = (value: string) => {
    setPermutaObject((prevState) => ({
      ...prevState,
      iva_type: value as EIvaType,
    }))
  }

  const handleCancel = () => {
    setPermutaObject({
      title: "",
      targa: "",
      km: "",
      year: `${new Date().getFullYear()}`,
      price: 0,
      description: "",
      iva_type: EIvaType.ESENTE_IVA,
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(
      addPermuta({
        ...permutaObject,
        price: +permutaObject.price,
      }),
    )
    handleCancel()
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3 grid grid-cols-[1fr_0.5fr] gap-3">
        <TextInput
          label="Nome veicolo"
          placeholder="Custom Transit Van Trend"
          onChange={handleChange}
          value={permutaObject.title}
          id="title"
          radius={12}
          styles={{ input: { height: 44, borderColor: "#e5e7eb" } }}
          className="rounded-xl"
          required
        />
        <TextInput
          label="Targa"
          placeholder="TA123SC"
          onChange={handleChange}
          value={permutaObject.targa}
          id="targa"
          radius={12}
          styles={{ input: { height: 44, borderColor: "#e5e7eb" } }}
          className="rounded-xl"
          required
        />
      </div>
      <div className="mb-3 grid grid-cols-2 gap-3">
        <TextInput
          placeholder="100.000"
          label="Chilometri"
          onChange={handleChange}
          value={permutaObject.km}
          id="km"
          radius={12}
          styles={{ input: { height: 44, borderColor: "#e5e7eb" } }}
          className="rounded-xl"
          required
        />
        <YearPickerInput
          label="Anno immatricolazione"
          id="year"
          maxDate={new Date()}
          value={permutaObject.year ? new Date(permutaObject.year) : new Date()}
          radius={12}
          styles={{ input: { height: 44, borderColor: "#e5e7eb" } }}
          className="rounded-xl"
          onChange={handleYearChange}
          required
        />
      </div>
      <div className="mb-3 grid grid-cols-[0.5fr_1fr] gap-3">
        <TextInput
          label="Valore permuta"
          placeholder="5000,00"
          id="price"
          value={permutaObject.price === 0 ? "" : permutaObject.price}
          onChange={handleChange}
          rightSection={<span>€</span>}
          radius={12}
          styles={{ input: { height: 44, borderColor: "#e5e7eb" } }}
          className="rounded-xl"
          required
        />
      </div>
      <Textarea
        label="Note"
        placeholder="Inserisci una nota per la permuta"
        id="description"
        value={permutaObject.description}
        onChange={handleChange}
        radius={12}
        styles={{ input: { height: 64, borderColor: "#e5e7eb" } }}
        className="rounded-xl mb-3"
      />
      <div>
        <span className="text-sm font-medium">Tipologia IVA</span>
        <Radio.Group
          name="iva_type"
          value={permutaObject.iva_type}
          onChange={handleIvaTypeChange}
        >
          <Group mt="md">
            <Radio value="ESENTE" label="Esente IVA" />
            <Radio value="ESCLUSA" label="Esclusa IVA" />
          </Group>
        </Radio.Group>
      </div>
      <div className="flex justify-end gap-3">
        <Button
          mt="lg"
          variant="outline"
          radius={50}
          onClick={handleCancel}
          leftSection={<IconSquareRoundedX />}
        >
          Annulla
        </Button>
        <Button
          mt="lg"
          type="submit"
          radius={50}
          leftSection={<IconCirclePlus />}
        >
          Aggiungi
        </Button>
      </div>
    </form>
  )
}

export default PermutaForm
