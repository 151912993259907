import { NumberInput, TextInput } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  selectAccessoriValues,
  selectPriceFinal,
  selectSconti,
  selectServiziValues,
  selectTotalBeforeDiscount,
  selectTotaleVeicolo,
  setSconto,
} from "../../../features/preventivo/preventivoSlice";
import { useState } from "react";
import { EScontoType } from "../../../queries/preventivi";

interface IDiscountFormProps {
  scontoType: EScontoType;
  isReadOnly: boolean;
}

const DiscountForm = ({ scontoType, isReadOnly }: IDiscountFormProps) => {
  const dispatch = useAppDispatch();
  const priceFinal = useAppSelector(selectPriceFinal);
  const [accessoriTotal] = useAppSelector(selectAccessoriValues);
  const [serviziTotal] = useAppSelector(selectServiziValues);
  const veicoloTotal = useAppSelector(selectTotaleVeicolo);
  const [percentage, setPercentage] = useState(0);
  const sconti = useAppSelector(selectSconti);

  const currentSconto = sconti.find(({ type }) => type === scontoType);

  const handleChangeDescription = (value: string) => {
    currentSconto &&
      dispatch(
        setSconto({
          ...currentSconto,
          description: value,
        }),
      );
  };

  const handleChangePercentage = (value: string | number) => {
    const currentValue = +value;
    setPercentage(currentValue);
    let price = 0;

    switch (currentSconto?.type) {
      case EScontoType.ACCESSORI:
        price = (accessoriTotal * currentValue) / 100;
        break;
      case EScontoType.SERVIZI:
        price = (serviziTotal * currentValue) / 100;
        break;
      case EScontoType.VEICOLO:
        price = (veicoloTotal * currentValue) / 100;
        break;
      case EScontoType.VEICOLO:
        price = (veicoloTotal * currentValue) / 100;
        break;
      case EScontoType.PREZZO_FINALE:
        const scontiTot = sconti
          .filter(
            ({ type }) =>
              type === EScontoType.VEICOLO ||
              type === EScontoType.ACCESSORI ||
              type === EScontoType.SERVIZI,
          )
          .reduce((acc, { price }) => acc + price, 0);

        price = ((veicoloTotal - scontiTot) * currentValue) / 100;

        break;
      /*
      case EScontoType.ACQUISTI_MULTIPLI:
        price = (priceFinal * currentValue) / 100;
        break;
        */
    }

    currentSconto &&
      dispatch(
        setSconto({
          ...currentSconto,
          price,
          type: currentSconto.type,
          label: currentSconto.label,
        }),
      );
  };

  const handleChangeDiscount = (value: string | number) => {
    if (!currentSconto) return;

    const currentValue = +value;

    let percentageValue = 0;

    switch (currentSconto.type) {
      case EScontoType.ACCESSORI:
        percentageValue = (currentValue / accessoriTotal) * 100;
        break;
      case EScontoType.SERVIZI:
        percentageValue = (currentValue / serviziTotal) * 100;
        break;
      case EScontoType.VEICOLO:
        percentageValue = (currentValue / veicoloTotal) * 100;
        break;
      case EScontoType.VEICOLO:
        percentageValue = (currentValue / veicoloTotal) * 100;
        break;
      case EScontoType.PREZZO_FINALE:
        const scontiTot = sconti
          .filter(
            ({ type }) =>
              type === EScontoType.VEICOLO ||
              type === EScontoType.ACCESSORI ||
              type === EScontoType.SERVIZI,
          )
          .reduce((acc, { price }) => acc + price, 0);

        percentageValue = (veicoloTotal - scontiTot / priceFinal) * 100;
        break;
      /*
      case EScontoType.ACQUISTI_MULTIPLI:
        percentageValue = (currentValue / priceFinal) * 100;
        break;
        */
    }

    setPercentage(percentageValue);

    dispatch(
      setSconto({
        ...currentSconto,
        price: currentValue,
        type: currentSconto.type,
        label: currentSconto.label,
      }),
    );
  };

  return (
    <form>
      <div className="mb-5 flex items-center justify-between gap-6">
        <span className="w-60 flex-none text-sm font-medium">
          {currentSconto && currentSconto.label}
        </span>
        <TextInput
          placeholder="Descrizione"
          value={currentSconto?.description ?? ""}
          onChange={(e) => handleChangeDescription(e.target.value)}
          w={"66.67%"}
          radius={12}
          styles={{ input: { height: 44, border: "none" } }}
          className="rounded-xl border"
          disabled={isReadOnly}
        />

        <div className="flex items-center justify-between gap-2">
          <span className="block text-sm font-medium">Importo</span>
          <div className="flex gap-2">
            <NumberInput
              min={0}
              max={100}
              decimalScale={2}
              value={percentage ?? 0}
              onChange={handleChangePercentage}
              allowNegative={false}
              rightSection={<span>%</span>}
              decimalSeparator=","
              radius={12}
              styles={{ input: { height: 44, border: "none" } }}
              className="rounded-xl border"
              disabled={isReadOnly}
            />
            <NumberInput
              min={0}
              value={currentSconto?.price ?? 0}
              onChange={handleChangeDiscount}
              allowNegative={false}
              decimalScale={2}
              rightSection={<span>€</span>}
              decimalSeparator=","
              radius={12}
              styles={{ input: { height: 44, border: "none" } }}
              className="rounded-xl border"
              disabled={isReadOnly}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default DiscountForm;
