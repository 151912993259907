import SummaryBoxWrapper from "./SummaryBoxWrapper"
import { useState } from "react"
import { IconChevronDown } from "@tabler/icons-react"

interface SummaryBoxProps {
  label: string
  totalItems?: number
  children?: React.ReactNode
  // totalPrice?: number;
  // totalDiscount?: number;
  // withoutPriceCol?: boolean;
  defaultCollapsed?: boolean
  withToggle?: boolean
}

const SummaryBox = ({
  label,
  totalItems,
  children,
  defaultCollapsed,
  withToggle,
}: SummaryBoxProps) => {
  const [collapsed, setIsCollapsed] = useState(defaultCollapsed || false)

  const handleToggleClick = () => {
    withToggle && setIsCollapsed((prevState) => !prevState)
  }

  return (
    <div className="summary mb-7 flex flex-col rounded-xl bg-white border">
      <div
        className={`flex w-full flex-wrap items-start justify-between gap-x-3 gap-y-1 bg-slate-600 px-8 py-4 ${!collapsed ? "rounded-t-xl" : "rounded-xl"}`}
      >
        <div className="flex items-center gap-1" onClick={handleToggleClick}>
          {withToggle && (
            <IconChevronDown
              className={`h-5 w-5 text-white ${!collapsed ? "rotate-180" : ""}`}
            />
          )}
          <span className="block px-2 font-semibold uppercase first-line:w-full text-white">
            {label}
          </span>
        </div>
        {totalItems && (
          <div>
            <span className="text-white text-sm">N° Totale: {totalItems}</span>
          </div>
        )}
      </div>
      <div
        className={`summary__children ${collapsed ? "summary__children--collapsed p-0" : "p-8"}`}
      >
        {children && <SummaryBoxWrapper>{children}</SummaryBoxWrapper>}
      </div>
    </div>
  )
}

export default SummaryBox
