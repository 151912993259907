import { IconAlertSquareRoundedFilled } from "@tabler/icons-react"
import { notifications } from "@mantine/notifications"
import { Preventivo } from "../../../queries/preventivi"
import { MRT_TableInstance } from "mantine-react-table"
import { Anagrafica } from "../../../queries/anagrafica"
import { Testo } from "../../../queries/testi"
import { Option } from "../../../queries/veicoli"

export const deleteModalProps = (
  singularElement: string,
  pluralElement: string,
  selectedElements: Array<string>,
) => {
  return {
    classNames: {
      body: "bg-error",
    },
    groupProps: {
      className: "justify-center",
    },
    overlayProps: {
      backgroundOpacity: 0.55,
      blur: 3,
    },
    color: "white",
    centered: true,
    withCloseButton: false,
    shadow: "md",
    radius: 26,
    children: (
      <div className="mb-6">
        <IconAlertSquareRoundedFilled className="mx-auto mb-3 h-9 w-9 text-white" />
        <p className="mx-auto max-w-52 text-center font-semibold leading-5 text-white">
          Sei sicuro di voler eliminare{" "}
          {selectedElements.length > 1
            ? `${selectedElements.length} ${pluralElement}`
            : `questo ${singularElement}`}
          ?
        </p>
      </div>
    ),
    labels: { confirm: "Elimina", cancel: "Annulla" },
    confirmProps: { variant: "white", color: "#e84e4e", radius: 12 },
    cancelProps: { variant: "outline", color: "white", radius: 12 },
  }
}

interface OnModalDeleteArgs {
  selectedIds: Array<string>
  deleteFunction: (ids: string[]) => any
  singularElement: string
  pluralElement: string
  isIdFromParams?: boolean
  navigate?: (route: string) => void
  table?:
    | MRT_TableInstance<Anagrafica>
    | MRT_TableInstance<Preventivo>
    | MRT_TableInstance<Testo>
    | MRT_TableInstance<Option>
}

export const onModalDelete = ({
  selectedIds,
  deleteFunction,
  singularElement,
  pluralElement,
  isIdFromParams,
  navigate,
  table,
}: OnModalDeleteArgs) => {
  deleteFunction(selectedIds)
    .then((payload: any) => {
      notifications.show({
        withCloseButton: true,
        autoClose: 4000,
        position: "top-center",
        title: "Successo!",
        radius: "md",
        message: `${selectedIds.length > 1 ? `${pluralElement} selezionati sono stati eliminati` : `Il ${singularElement} selezionato è stato eliminato`}.`,
        color: "teal",
        loading: false,
      })
      isIdFromParams && navigate && navigate(`/${pluralElement}`)
      table?.toggleAllRowsSelected(false)
    })
    .catch((error: Error) => {
      console.error("rejected", error)
      notifications.show({
        withCloseButton: true,
        position: "top-center",
        title: "Errore.",
        radius: "md",
        message: `Errore nell'eliminazione ${selectedIds.length > 1 ? `dei ${pluralElement}` : `del ${singularElement}`}. Riprova.`,
        color: "pink",
        loading: false,
      })
    })
}
