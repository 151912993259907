import ActivePreventivi from "../../components/widgets/ActivePreventivi/ActivePreventivi";
import LatestContratti from "../../components/widgets/LatestContratti/LatestContratti";
import LatestNotifications from "../../components/widgets/LatestNotifications/LatestNotifications";
import LatestPreventivi from "../../components/widgets/LatestPreventivi/LatestPreventivi";
import TemperatureWidget from "../../components/widgets/TemperatureWidget/TemperatureWidget";

const Home = () => {
  return (
    <section className="mx-auto max-w-6xl py-12">
      <div className="mb-5 grid grid-cols-2 gap-5">
        <LatestPreventivi />
        <TemperatureWidget />
      </div>
      <div className="grid grid-cols-2 gap-5">
        <div className="flex gap-5">
          <ActivePreventivi />
          <LatestContratti />
        </div>
        <LatestNotifications />
      </div>
    </section>
  );
};

export default Home;
