const WidgetBox = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={`relative rounded-xl px-5 py-5 shadow-md border ${className}`}>
      {children}
    </div>
  );
};

export default WidgetBox;
