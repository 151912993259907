export function formatListino(listino: string) {
  return listino.split("%")[0];
}

export function TS4Save(ts?: number | undefined): number {
  let _ts = ts || Date.now();

  if (_ts > 2e9) {
    return Math.floor(_ts / 1e3);
  }

  return _ts;
}

export function TS4Display(ts: number): number {
  if (ts < 2e11) {
    return ts * 1e3;
  }

  return ts;
}
