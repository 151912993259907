const NotificationBadge = ({
  notificationsLength,
}: {
  notificationsLength: number
}) => {
  const notificationNumber =
    notificationsLength > 99 ? "99" : notificationsLength

  return (
    <div className="absolute -right-1 -top-2 z-10 flex h-5 w-5 items-center justify-center rounded-full bg-error text-xs font-semibold text-white">
      {notificationNumber}
    </div>
  )
}

export default NotificationBadge
