import { DateInput } from "@mantine/dates"
import { TS4Display } from "../../../utils/formatters"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import {
  selectDataValidita,
  setDataValidita,
} from "../../../features/preventivo/preventivoSlice"

const ExpirationDate = ({ isReadOnly }: { isReadOnly: boolean }) => {
  const dispatch = useAppDispatch()
  const currentExpirationDate = useAppSelector(selectDataValidita)

  const getScadenzaDefault = () => {
    const now = new Date()
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)
    return lastDayOfMonth.getTime()
  }

  const handleDateChange = (date: Date | null) => {
    if (date) {
      dispatch(setDataValidita(date.getTime()))
    }
  }

  const expirationDate =
    currentExpirationDate !== 0
      ? new Date(TS4Display(currentExpirationDate || 0))
      : new Date(getScadenzaDefault())

  return (
    <DateInput
      label="Data scadenza preventivo"
      disabled={isReadOnly}
      defaultValue={expirationDate}
      className="w-full mb-5"
      placeholder="Seleziona la data di scadenza del preventivo"
      valueFormat="DD/MM/YYYY"
      onChange={handleDateChange}
      styles={{
        input: {
          width: "100%",
          height: 44,
          borderRadius: 12,
        },
      }}
    />
  )
}

export default ExpirationDate
