import { Loader } from "@mantine/core";
import WidgetBox from "../WidgetBox/WidgetBox";
import { Anagrafica } from "../../../queries/anagrafica";

export interface AnagraficaWidgetProps {
  isLoading: boolean;
  customerData: Anagrafica | undefined;
}

const AnagraficaWidget = ({
  isLoading,
  customerData,
}: AnagraficaWidgetProps) => {
  const displayUser = isLoading ? (
    <Loader
      color="white"
      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      type="dots"
    />
  ) : customerData ? (
    <div className="flex gap-5">
      <div className="flex-1 rounded-xl bg-slate-700 px-4 py-2">
        <span className="mb-1 block font-medium">Dati generici</span>
        <span className="mb-1 block text-xs">{customerData.nome}</span>
        <span className="mb-1 block text-xs">
          {customerData.indirizzo}, {customerData.localita}, {customerData.prov}
        </span>
      </div>
      <div className="flex-1 rounded-xl bg-slate-700 px-4 py-2">
        <span className="mb-1 font-medium">Dati di contatto</span>
        <span className="mb-1 block text-xs">{customerData.email}</span>
        <span className="mb-1 block text-xs">{customerData.tel}</span>
      </div>
    </div>
  ) : null;

  return (
    <WidgetBox className="min-h-44 bg-slate-800 text-white">
      <span className="mb-3 block font-bold uppercase">Anagrafica cliente</span>
      {displayUser}
    </WidgetBox>
  );
};

export default AnagraficaWidget;
