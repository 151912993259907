import VariabiliTable from "../../components/Admin/AdminTable/VariabiliTable"
import VariabileForm from "../../components/Form/VariabileForm"
import TableWrapper from "../../Layout/TableWrapper/TableWrapper"
import TableActionsWrapper from "../../Layout/TableActionsWrapper/TableActionsWrapper"
import { modals } from "@mantine/modals"
import { Button, Flex } from "@mantine/core"
import { IconCirclePlus } from "@tabler/icons-react"

const AdminVariabili = () => {
  return (
    <>
      <TableActionsWrapper>
        <Flex gap={20}>
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconCirclePlus size={18} />}
            onClick={() => {
              modals.open({
                padding: 0,
                centered: true,
                withCloseButton: false,
                size: "70%",
                radius: 26,
                children: (
                  <VariabileForm onCloseClick={() => modals.closeAll()} />
                ),
              })
            }}
          >
            Nuova variabile
          </Button>
        </Flex>
      </TableActionsWrapper>
      <TableWrapper>
        <VariabiliTable />
      </TableWrapper>
    </>
  )
}

export default AdminVariabili
