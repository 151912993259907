import { List } from "@mantine/core"
import { Notification } from "../../../queries/notification"
import { IconAlertCircle } from "@tabler/icons-react"
import { transformTimestampToLocaleDateAndTime } from "../../../utils/utils"

const PreventivoNotificationItem = ({
  notifications,
}: {
  notifications: Notification[]
}) => {
  return (
    <div>
      <span className="text-sm font-medium">Notifiche</span>
      <List
        spacing="xxs"
        size="sm"
        center
        icon={<IconAlertCircle className="text-slate-900" />}
      >
        {notifications.map((notification) => (
          <List.Item key={notification._id}>
            <div className="flex items-center gap-3">
              <span className="font-semibold">
                {transformTimestampToLocaleDateAndTime(
                  notification.alert_at,
                  true,
                  true,
                )}
              </span>
              <span>{notification.text}</span>
            </div>
          </List.Item>
        ))}
      </List>
    </div>
  )
}

export default PreventivoNotificationItem
