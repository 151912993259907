import { Link, useParams } from "react-router-dom";
import WidgetBox from "../WidgetBox/WidgetBox";
import WidgetList from "../WidgetList/WidgetList";
import { IconAlertCircleFilled, IconEye } from "@tabler/icons-react";
import { Button, Loader } from "@mantine/core";
import {
  getNotificationPriority,
  transformTimestampToLocaleDateAndTime,
} from "../../../utils/utils";
import useGetNotifications from "../../../hooks/useGetNotifications";
import NotificationForm from "../../Form/NotificationForm";
import useAuthUserData from "../../../hooks/useAuthUserData";
import { modals } from "@mantine/modals";
import { Notification } from "../../../queries/notification";
import { Tooltip } from "@mantine/core";

const LatestNotifications = () => {
  const { authUser, authUserId } = useAuthUserData();
  const { customerId } = useParams();
  const {
    isLoading,
    activeNotifications,
    filteredCustomerActiveNotifications,
  } = useGetNotifications({
    pollingInterval: 300e3,
    limit: 6,
    idAgente: authUserId,
    idCliente: customerId,
  });

  const notificationsToDisplay = customerId
    ? filteredCustomerActiveNotifications
    : activeNotifications;

  const handleEditNotification = (notification: Notification) => {
    modals.open({
      padding: 0,
      withCloseButton: false,
      size: "70%",
      radius: 26,
      centered: true,
      children: (
        <NotificationForm
          currentNotification={notification}
          authUser={authUser}
        />
      ),
    });
  };

  const isNotificationsDataEmpty =
    activeNotifications && activeNotifications?.length === 0;

  const displayLatestNotifications = !isLoading ? (
    !isNotificationsDataEmpty ? (
      notificationsToDisplay?.map((notification, index) => (
        <WidgetList
          key={index}
          className={
            index === notificationsToDisplay.length - 1 ? "border-none" : ""
          }
        >
          <span className="flex flex-1 items-center gap-1 overflow-hidden overflow-ellipsis whitespace-nowrap font-semibold capitalize">
            <IconAlertCircleFilled
              className={`h-5 w-5 ${getNotificationPriority(notification)}`}
            />
            {notification.text}
          </span>
          <span>
            {transformTimestampToLocaleDateAndTime(
              notification.alert_at,
              true,
              true,
            )}
          </span>
          {notification.id_preventivo !== "null" ? (
            <span className="w-16 ">
              <Tooltip label={notification.id_preventivo}>
                <Link
                  to={`/preventivi/visualizza/${notification.id_preventivo}`}
                >
                  {notification.id_preventivo !== "null"
                    ? `${notification.id_preventivo?.substring(0, 6)}...`
                    : ""}
                </Link>
              </Tooltip>
            </span>
          ) : (
            <span className="min-w-16">
              <Tooltip label={notification.nome_cliente}>
                <Link to={`/clienti/${notification.id_cliente}/panoramica`}>
                  {`${notification.nome_cliente?.substring(0, 6)}...`}
                </Link>
              </Tooltip>
            </span>
          )}
          <Button
            variant="filled"
            radius={100}
            className="ml-3 flex h-7 w-7 items-center justify-center p-0"
            onClick={() => handleEditNotification(notification)}
          >
            <IconEye className="h-4 w-4" />
          </Button>
        </WidgetList>
      ))
    ) : (
      <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
        Nessuna notifica recente programmata.
      </p>
    )
  ) : (
    <Loader
      color="blue"
      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      type="dots"
    />
  );

  const link = customerId ? `/clienti/${customerId}/notifiche` : "/notifiche";

  return (
    <WidgetBox className="min-h-72 bg-white">
      <div className="mb-4 flex justify-between gap-2">
        <h2 className="font-bold uppercase">Ultime notifiche</h2>
        <Link to={link} className="underline">
          <Button variant="outline" h={28} className="rounded-full !py-0 px-3">
            Vedi tutte
          </Button>
        </Link>
      </div>
      {displayLatestNotifications}
    </WidgetBox>
  );
};

export default LatestNotifications;
