import { Paper, Container } from "@mantine/core";

const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container
      size={420}
      my={40}
      className="mb-0 mt-0 flex h-[calc(100vh_-_100px)] flex-col justify-center"
    >
      <Paper p={30} mt={30} radius="lg" className="border shadow-md">
        {children}
      </Paper>
    </Container>
  );
};

export default AuthWrapper;
