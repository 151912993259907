import { useEffect, useMemo } from "react";
import {
  MRT_GlobalFilterTextInput,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  disableUser,
  fetchUsers,
  selectUsersList,
} from "../../features/users/UsersSlice";
import { MRT_Localization_IT } from "mantine-react-table/locales/it/index.esm.mjs";
import { Button } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { deleteModalProps } from "../Modal/ModalDelete/ModalDelete";
import { modals } from "@mantine/modals";
import UserForm from "../Form/UserForm";
import useAuthUserData from "../../hooks/useAuthUserData";

const UsersTable = () => {
  const { authUserName } = useAuthUserData();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "custom_data.name",
        header: "Nome",
        enableHiding: false,
      },
      {
        accessorKey: "data.email",
        header: "Email",
        enableHiding: false,
      },
      {
        accessorKey: "custom_data.role",
        header: "Ruolo",
        enableHiding: false,
      },
    ],
    [],
  );

  const users = useAppSelector(selectUsersList);

  const table = useMantineReactTable({
    columns,
    data: users || [],
    createDisplayMode: "modal",
    editDisplayMode: "row",
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnPinning: true,
    enableRowActions: false,
    positionActionsColumn: "last",
    enableRowSelection: true,
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    globalFilterFn: "contains",
    enableFilterMatchHighlighting: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableFacetedValues: true,
    enableColumnResizing: false,
    enableStickyHeader: true,
    enableGlobalFilterModes: true,
    localization: MRT_Localization_IT,
    state: {
      density: "xs",
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    mantineSearchTextInputProps: {
      placeholder: "Cerca...",
      variant: "filled",
    },
    mantineTableHeadCellProps: {
      bg: "rgb(226,232,240,1)",
      frameBorder: "none",
    },
    mantinePaperProps: {
      withBorder: false,
      shadow: "none",
      radius: "md",
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "",
      },
    },
    renderTopToolbar: ({ table }) => {
      const isOneSelected =
        Object.keys(table.getState().rowSelection).length > 0;
      const isManySelected =
        Object.keys(table.getState().rowSelection).length > 1;
      const handleEdit = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          modals.open({
            padding: 0,
            withCloseButton: false,
            centered: true,
            size: "70%",
            radius: 26,
            children: (
              <UserForm
                currentUserName={authUserName}
                currentModalRecord={row.original}
                onCloseClick={() => modals.closeAll()}
              />
            ),
          });
        });
      };
      const handleDelete = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          console.log([row.original?.data.email]);
          modals.openConfirmModal({
            ...deleteModalProps("utente", "utenti", [row.original?.data.email]),
            onConfirm: () => dispatch(disableUser(row.original?.data.email)),
          });
        });
      };
      return (
        <div className="flex items-center justify-between bg-slate-200 p-4">
          {isOneSelected && (
            <div className="flex items-center gap-5">
              {!isManySelected && (
                <Button
                  rightSection={<IconEdit size={18} />}
                  onClick={handleEdit}
                >
                  Modifica
                </Button>
              )}
              <Button
                rightSection={<IconTrash size={18} />}
                onClick={handleDelete}
              >
                Elimina
              </Button>
            </div>
          )}
          <div className="ml-auto flex items-center gap-2">
            <div className="has-global-search flex items-center gap-2">
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToggleGlobalFilterButton table={table} />
            </div>
            <MRT_ToggleFiltersButton table={table} />
          </div>
        </div>
      );
    },
  });

  return <MantineReactTable table={table} />;
};

export default UsersTable;
