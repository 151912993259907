import { Link, RichTextEditor } from "@mantine/tiptap"
import { useEditor } from "@tiptap/react"
import Highlight from "@tiptap/extension-highlight"
import StarterKit from "@tiptap/starter-kit"
import TextStyle from "@tiptap/extension-text-style"
import Underline from "@tiptap/extension-underline"
import TextAlign from "@tiptap/extension-text-align"
import {
  decodePreventivoString,
  encodePreventivoString,
} from "../../../../../utils/utils"
import { useAppDispatch } from "../../../../../app/hooks"
import { Color } from "@tiptap/extension-color"
import { UnknownAction } from "@reduxjs/toolkit"

export interface IPreventivoEditorProps {
  field?: string
  callback: (arg0: string) => UnknownAction
  label?: string
  hasToolbar?: boolean
}

const PreventivoEditor = ({
  field,
  callback,
  hasToolbar = true,
}: IPreventivoEditorProps) => {
  const dispatch = useAppDispatch()
  const editor = useEditor({
    extensions: [
      TextStyle,
      StarterKit,
      Underline,
      Link,
      Highlight,
      Color,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content: decodePreventivoString(field || ""),
    onUpdate: ({ editor }) => {
      dispatch(callback(encodePreventivoString(editor.getHTML())))
    },
  })

  return (
    <RichTextEditor editor={editor}>
      {hasToolbar && (
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Undo />
            <RichTextEditor.Redo />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Color color="#000000" />
            <RichTextEditor.Color color="#0b5394" />
            <RichTextEditor.Color color="#3d63c6" />
            <RichTextEditor.Color color="#38761d" />
            <RichTextEditor.Color color="#ea4335" />
            <RichTextEditor.Color color="#ffff00" />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.UnsetColor />
        </RichTextEditor.Toolbar>
      )}

      <RichTextEditor.Content />
    </RichTextEditor>
  )
}

export default PreventivoEditor
