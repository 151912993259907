import { useMemo } from "react";
import {
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import useCustomPagination from "../../features/anagrafica/anagraficaHooks/useCustomPagination";
import { Button } from "@mantine/core";
import { IconAddressBook, IconEdit, IconTrash } from "@tabler/icons-react";
import { MRT_Localization_IT } from "mantine-react-table/locales/it/index.esm.mjs";
import { Link } from "react-router-dom";
import { Anagrafica } from "../../queries/anagrafica";
import { modals } from "@mantine/modals";
import { useDeleteAnagraficheMutation } from "../../features/api/apiSlice";
import {
  deleteModalProps,
  onModalDelete,
} from "../Modal/ModalDelete/ModalDelete";
import CustomerForm from "../Form/CustomerForm";
import useAuthUserData from "../../hooks/useAuthUserData";

const CustomersTable = () => {
  const [deleteAnagrafiche] = useDeleteAnagraficheMutation();
  const { authUserName } = useAuthUserData();

  const { data, isLoading } = useCustomPagination({
    direction: "g",
    id: "5ca4bbcea2dd94ee58162a8f",
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "nome",
        header: "Nome",
        muiTableHeadCellProps: { style: { color: "green" } },
        enableHiding: false,
        Cell: ({ row, renderedCellValue }: { row: MRT_Row<Anagrafica>, renderedCellValue: string }) => (
          <Link to={`/clienti/${row.original._id}/panoramica`}>
            {renderedCellValue}
          </Link>
        ),
      },
      {
        accessorKey: "indirizzo",
        header: "Indirizzo",
        muiTableHeadCellProps: { style: { color: "green" } },
        enableHiding: false,
        Cell: ({ row }: { row: MRT_Row<Anagrafica> }) => (
          <p>
            {row.original.indirizzo}, {row.original.civico}
          </p>
        ),
      },
      {
        accessorKey: "cap",
        header: "CAP",
        muiTableHeadCellProps: { style: { color: "green" } },
        enableHiding: false,
      },
      {
        accessorKey: "localita",
        header: "Località",
        muiTableHeadCellProps: { style: { color: "green" } },
        enableHiding: false,
      },
      {
        accessorKey: "prov",
        header: "Provincia",
        muiTableHeadCellProps: { style: { color: "green" } },
        enableHiding: false,
      },
      {
        accessorKey: "email",
        header: "Email",
        muiTableHeadCellProps: { style: { color: "green" } },
        enableHiding: false,
      },
      {
        accessorKey: "tel",
        header: "Telefono",
        muiTableHeadCellProps: { style: { color: "green" } },
        enableHiding: false,
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    //@ts-ignore
    columns,
    data: data?.anagraficas || [],
    createDisplayMode: "modal",
    editDisplayMode: "row",
    enableColumnDragging: false,
    enableColumnActions: false,
    enableColumnPinning: true,
    enableRowActions: false,
    positionActionsColumn: "last",
    enableRowSelection: true,
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    globalFilterFn: "contains",
    enableFilterMatchHighlighting: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableFacetedValues: true,
    enableColumnResizing: false,
    enableStickyHeader: true,
    enableGlobalFilterModes: true,
    localization: MRT_Localization_IT,
    state: {
      isLoading: isLoading,
      density: "xs",
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    mantineSearchTextInputProps: {
      placeholder: "Cerca...",
      variant: "filled",
    },
    mantineTableHeadCellProps: {
      bg: "rgb(226,232,240,1)",
      frameBorder: "none",
    },
    mantinePaperProps: {
      withBorder: false,
      shadow: "none",
      radius: "md",
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "",
      },
    },
    renderTopToolbar: ({ table }) => {
      const isOneSelected =
        Object.keys(table.getState().rowSelection).length > 0;
      const isManySelected =
        Object.keys(table.getState().rowSelection).length > 1;

      const handleEdit = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          modals.open({
            padding: 0,
            withCloseButton: false,
            size: "70%",
            radius: 26,
            children: (
              <CustomerForm
                currentUserName={authUserName}
                currentModalRecord={row.original}
                onCloseClick={() => modals.closeAll()}
              />
            ),
          });
        });
      };

      const handleDelete = () => {
        const selectedIds = table
          .getSelectedRowModel()
          .flatRows.map((row) => row.original._id || "");
        modals.openConfirmModal({
          ...deleteModalProps("cliente", "clienti", selectedIds),
          onConfirm: () =>
            onModalDelete({
              selectedIds: selectedIds,
              deleteFunction: deleteAnagrafiche,
              singularElement: "cliente",
              pluralElement: "clienti",
              table: table,
            }),
        });
      };

      return (
        <div className="flex items-center justify-between bg-slate-200 p-4">
          {isOneSelected && (
            <div className="flex items-center gap-5">
              {!isManySelected && (
                <>
                  <Link
                    to={`/clienti/${table.getSelectedRowModel().flatRows.map((row) => row.original._id)}/panoramica`}
                  >
                    <Button rightSection={<IconAddressBook size={18} />}>
                      Visualizza scheda
                    </Button>
                  </Link>
                  <Button
                    rightSection={<IconEdit size={18} />}
                    onClick={handleEdit}
                  >
                    Modifica
                  </Button>
                </>
              )}
              <Button
                rightSection={<IconTrash size={18} />}
                onClick={handleDelete}
              >
                Elimina
              </Button>
            </div>
          )}
          <div className="ml-auto flex items-center gap-2">
            <div className="has-global-search flex items-center gap-2">
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToggleGlobalFilterButton table={table} />
            </div>
            <MRT_ToggleFiltersButton table={table} />
          </div>
        </div>
      );
    },
  });

  return <MantineReactTable table={table} />;
};

export default CustomersTable;
