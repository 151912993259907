import { NativeSelect, TextInput } from "@mantine/core";
import { useForm } from "react-hook-form";
import { User, createUser, editUser } from "../../features/users/UsersSlice";
import { useAppDispatch } from "../../app/hooks";
import ModalLayout from "../Modal/ModalLayout/ModalLayout";
import { modals } from "@mantine/modals";

const UserForm = ({
  currentModalRecord,
  currentUserName,
  onCloseClick,
}: {
  currentModalRecord?: User;
  currentUserName?: string | undefined;
  onCloseClick: () => void;
}) => {
  const dispatch = useAppDispatch();

  const typeData = [
    { value: "admin", label: "Admin" },
    { value: "agente", label: "Agente" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm();

  const onSubmit = (data: any) => {
    const user = {
      custom_data: {
        name: data.nome,
        role: data.ruolo,
      },
      data: {
        email: data.email,
      },
    };
    if (!currentModalRecord) {
      dispatch(createUser(user));
    } else {
      dispatch(editUser(user));
    }
    modals.closeAll();
  };

  const modalTitle = currentModalRecord
    ? "Modifica utente"
    : "Crea nuovo utente";

  return (
    <ModalLayout
      title={modalTitle}
      currentUserName={currentUserName}
      onCloseClick={onCloseClick}
      onConfirmClick={handleSubmit(onSubmit)}
    >
      <form>
        <div className="mb-5 flex flex-col gap-2">
          <TextInput
            {...register("nome", { required: true })}
            defaultValue={
              currentModalRecord ? currentModalRecord.custom_data.name : ""
            }
            placeholder="Nome"
            className="w-full"
          />
          <TextInput
            {...register("email", { required: true })}
            defaultValue={
              currentModalRecord ? currentModalRecord.data.email : ""
            }
            placeholder="Email"
            className="w-full"
          />
          <NativeSelect
            {...register("ruolo", { required: true })}
            data={typeData}
            defaultValue={
              currentModalRecord
                ? currentModalRecord.custom_data.role
                : "Agente"
            }
            withAsterisk
            className="w-full"
          />
        </div>
      </form>
    </ModalLayout>
  );
};

export default UserForm;
