import { useParams } from "react-router-dom";
import LatestPreventivi from "../../../components/widgets/LatestPreventivi/LatestPreventivi";
import AnagraficaWidget from "../../../components/widgets/AnagraficaWidget/AnagraficaWidget";
import useGetCurrentCustomer from "../../../hooks/useGetCurrentCustomer";
import LatestNotifications from "../../../components/widgets/LatestNotifications/LatestNotifications";

const PanoramicaCliente = () => {
  const { customerId } = useParams();
  const { currentCustomer, isLoading } = useGetCurrentCustomer({ customerId });

  return (
    <section className="mx-auto max-w-6xl pt-24">
      <div className="mb-4 flex gap-4">
        <div className="w-1/2">
          <AnagraficaWidget
            isLoading={isLoading}
            customerData={currentCustomer}
          />
        </div>
      </div>
      <div className="grid  grid-cols-2 gap-4">
        <LatestPreventivi />
        <LatestNotifications />
      </div>
    </section>
  );
};

export default PanoramicaCliente;
