import WidgetBox from "../../widgets/WidgetBox/WidgetBox"
import { Link } from "react-router-dom"
import {
  IconCarGarage,
  IconCirclePlus,
  IconCodePlus,
  IconDiscountCheckFilled,
  IconUser,
} from "@tabler/icons-react"

const AdminDashboard = () => {
  return (
    <section className="mx-auto max-w-6xl py-12">
      <div className="mb-5 grid grid-cols-2 gap-5">
        <WidgetBox className="relative min-h-48 bg-slate-200 overflow-hidden">
          <Link to="/admin/accessori/" className="block min-h-48">
            <span className="uppercase font-semibold text-xl block">
              Gestione accessori
            </span>
            <span className="absolute -right-6 -bottom-12">
              <IconCirclePlus className="text-white size-48" />
            </span>
          </Link>
        </WidgetBox>
        <WidgetBox className="relative min-h-48 bg-slate-200 overflow-hidden">
          <Link to="/admin/utenti/" className="block min-h-48">
            <span className="uppercase font-semibold text-xl block">
              Gestione utenti
            </span>
            <span className="absolute -right-12 -bottom-12">
              <IconUser className="text-white size-48" />
            </span>
          </Link>
        </WidgetBox>
      </div>
      <div className="mb-5 grid grid-cols-2 gap-5">
        <WidgetBox className="relative min-h-48 bg-slate-200 overflow-hidden">
          <Link to="/admin/variabili/" className="block min-h-48">
            <span className="uppercase font-semibold text-xl block">
              Gestione variabili
            </span>
            <span className="absolute -right-12 -bottom-12">
              <IconCodePlus className="text-white size-48" />
            </span>
          </Link>
        </WidgetBox>
        <WidgetBox className="relative min-h-48 bg-slate-200 overflow-hidden">
          <Link to="/admin/veicoli/" className="block min-h-48">
            <span className="uppercase font-semibold text-xl block">
              Gestione veicoli
            </span>
            <span className="absolute -right-12 -bottom-12">
              <IconCarGarage className="text-white size-48" />
            </span>
          </Link>
        </WidgetBox>
        <WidgetBox className="relative min-h-48 bg-slate-200 overflow-hidden">
          <Link to="/admin/margini/" className="block min-h-48">
            <span className="uppercase font-semibold text-xl block">
              Gestione margini
            </span>
            <span className="absolute -right-12 -bottom-12">
              <IconDiscountCheckFilled className="text-white size-48" />
            </span>
          </Link>
        </WidgetBox>
      </div>
    </section>
  )
}

export default AdminDashboard
