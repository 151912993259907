import { useLocation, useParams } from "react-router-dom"
import PageNavigation from "../../components/PageNavigation/PageNavigation"
import useGetCurrentCustomer from "../../hooks/useGetCurrentCustomer"

const Header = ({ children }: { children?: React.ReactNode }) => {
  const location = useLocation()
  const { customerId } = useParams()
  const { currentCustomer } = useGetCurrentCustomer({ customerId })
  const isNewPreventivoLocation = location.pathname === "/preventivi/nuovo"
  const isAdminLocation = location.pathname.match("/admin/")
  const isNotificationLocation =
    location.pathname === "/notifiche" ||
    location.pathname === "/storico-notifiche"
  const headerClasses =
    customerId || isNotificationLocation || isAdminLocation
      ? "min-h-20"
      : "h-20"
  const headerTitleClasses = customerId ? "pt-4" : "h-20"

  let pageTitle: string
  if (isNewPreventivoLocation) {
    pageTitle = "Nuovo preventivo"
  } else if (location.pathname.match("/clienti/")) {
    pageTitle = currentCustomer?.nome || "Scheda cliente"
  } else if (isAdminLocation) {
    if (location.pathname !== "/admin/") {
      pageTitle = location.pathname
        .replace("admin", "gestione")
        .replace(/[\/-]/g, " ")
    } else {
      pageTitle = "Area admin"
    }
  } else {
    pageTitle = location.pathname.replace(/[\/-]/g, " ")
  }

  if (location.pathname === "/") {
    return
  }

  return (
    <header
      className={`relative items-center justify-center bg-white px-8 shadow-md z-40 ${headerClasses}`}
    >
      <div className={`flex items-center justify-center ${headerTitleClasses}`}>
        <h1 className="text-lg font-bold uppercase">{pageTitle}</h1>
      </div>
      {customerId && (
        <PageNavigation navigationType="customers" customerId={customerId} />
      )}
      {isNotificationLocation && (
        <PageNavigation navigationType="notification" />
      )}
      {isAdminLocation && location.pathname !== "/admin/" && (
        <PageNavigation navigationType="admin" />
      )}
      {children}
    </header>
  )
}

export default Header
