import { Flex } from "@mantine/core"
import { NavLink } from "react-router-dom"

type NavigationObject = {
  label: string
  path: string
}

type PageNavigationProps = {
  customerId?: string
  navigationType: "customers" | "admin" | "notification"
}

const PageNavigation = ({
  customerId,
  navigationType,
}: PageNavigationProps) => {
  const customersNavigation = [
    { label: "Panoramica", path: `/clienti/${customerId}/panoramica` },
    { label: "Anagrafica", path: `/clienti/${customerId}/anagrafica` },
    { label: "Preventivi", path: `/clienti/${customerId}/preventivi` },
    { label: "Contratti", path: `/clienti/${customerId}/contratti` },
    { label: "Notifiche", path: `/clienti/${customerId}/notifiche` },
  ]

  const adminNavigation = [
    { label: "Gestione accessori", path: `/admin/accessori` },
    { label: "Gestione utenti", path: `/admin/utenti` },
    { label: "Gestione variabili", path: "/admin/variabili" },
    { label: "Gestione veicoli", path: "/admin/veicoli" },
    { label: "Gestione margini", path: "/admin/margini" },
  ]

  const notificationNavigation = [
    { label: "Notifiche attive", path: `/notifiche` },
    { label: "Storico notifiche", path: `/storico-notifiche` },
  ]

  const customersNavigationClasses = {
    active:
      "relative text-sm text-cm-blue font-semibold pb-2 before:w-[calc(100%+12px)] before:h-[3px] before:bg-cm-blue before:absolute before:-left-[6px] before:bottom-0 before:rounded-full",
    idle: "text-sm text-slate-600 pb-2",
  }

  const navigationObject: NavigationObject[] =
    navigationType === "customers"
      ? customersNavigation
      : navigationType === "admin"
        ? adminNavigation
        : notificationNavigation

  return (
    <Flex justify="center" gap={80} className="pt-4">
      {navigationObject?.map((navigation) => (
        <NavLink
          key={navigation.label}
          to={navigation.path}
          className={({ isActive }) =>
            isActive
              ? customersNavigationClasses.active
              : customersNavigationClasses.idle
          }
        >
          {navigation.label}
        </NavLink>
      ))}
    </Flex>
  )
}

export default PageNavigation
