/*
Per la logica usata nei selectors si veda
https://redux.js.org/usage/deriving-data-selectors
e
https://react-redux.js.org/api/hooks
*/

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import { suspendUser, getUsers, newUser, updateUser } from "./UsersAPI";
import UsersTable from "../../components/Table/UsersTable";
import { notifications } from "@mantine/notifications";

export interface User {
  _id?: string;
  id?: string;
  custom_data: {
    role?: string;
    name: string;
    disabled?: boolean;
  };
  data: {
    email: string;
  };
  mongo?: any;
}

export interface UserState {
  list: Array<any>;
  status: string;
  error: string;
  currentUser?: User;
}

const initialState: UserState = {
  list: [],
  status: "idle",
  error: "",
};

export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  const response = await getUsers();
  return response;
});

export const createUser = createAsyncThunk(
  "users/createUser",
  async (data: User) => {
    try {
      const response = await newUser(data);
      notifications.show({
        withCloseButton: true,
        autoClose: 4000,
        position: "top-center",
        title: "Successo!",
        radius: "md",
        message: "Nuovo utente creato.",
        color: "teal",
        loading: false,
      });
      return response;
    } catch (e) {
      notifications.show({
        withCloseButton: true,
        autoClose: 4000,
        position: "top-center",
        title: "Errore.",
        radius: "md",
        message: "Problemi nella crezione del nuovo utente. Riprova.",
        color: "pink",
        loading: false,
      });
      throw new Error("Error creating user");
    }
  },
);

export const disableUser = createAsyncThunk(
  "users/disableUser",
  async (email: string) => {
    try {
      const response = await suspendUser(email);
      notifications.show({
        withCloseButton: true,
        autoClose: 4000,
        position: "top-center",
        title: "Successo!",
        radius: "md",
        message: "L'utente selezionato è stato eliminato.",
        color: "teal",
        loading: false,
      });
      return response;
    } catch (e) {
      notifications.show({
        withCloseButton: true,
        autoClose: 4000,
        position: "top-center",
        title: "Errore.",
        radius: "md",
        message: "Problemi nell'eliminazione dell'utente selezionato. Riprova.",
        color: "pink",
        loading: false,
      });
      throw new Error("Error deleting user");
    }
  },
);

export const editUser = createAsyncThunk(
  "users/editUser",
  async (userObj: User) => {
    try {
      const response = await updateUser(userObj);
      notifications.show({
        withCloseButton: true,
        autoClose: 4000,
        position: "top-center",
        title: "Successo!",
        radius: "md",
        message: "Utente selezionato aggiornato.",
        color: "teal",
        loading: false,
      });
      return response;
    } catch (e) {
      notifications.show({
        withCloseButton: true,
        autoClose: 4000,
        position: "top-center",
        title: "Errore.",
        radius: "md",
        message: "Problemi nella modifica dell'utente selezionato. Riprova.",
        color: "pink",
        loading: false,
      });
      throw new Error("Error updating user");
    }
  },
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "idle";
        //@ts-ignore
        state.list = action.payload;
        UsersTable;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createUser.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = "idle";
        //TODO: FARLO CON IL ROUTER!!
        //@ts-ignore
        if (action.payload.error) {
          //@ts-ignore
          console.log(action.payload.error);
          //@ts-ignore
          state.error = action.payload.error;
        }
      })
      .addCase(createUser.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(editUser.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.status = "idle";
        //TODO: FARLO CON IL ROUTER!!
        //@ts-ignore
        if (action.payload.error) {
          //@ts-ignore
          console.log(action.payload.error);
          //@ts-ignore
          state.error = action.payload.error;
        }
      })
      .addCase(editUser.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(disableUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(disableUser.fulfilled, (state, action) => {
        state.status = "idle";
        //TODO: FARLO CON IL ROUTER!!
        window.location.href = "/admin/utenze";
      })
      .addCase(disableUser.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectUsersList = (state: RootState) => state.users.list;

const selectUserId = (state: RootState, userId: number) => userId;
export const selectStatus = (state: RootState) => state.users.status;
export const selectError = (state: RootState) => state.users.error;

export const selectUser = createSelector(
  [selectUsersList, selectUserId],
  (usersList, userId) => usersList.filter((user) => user.id === userId),
);

export const selectCurrentUser = (state: RootState) => state.users.currentUser;

export default usersSlice.reducer;
