import { NumberFormatter, Select, Textarea } from "@mantine/core";
import ModalLayout from "../Modal/ModalLayout/ModalLayout";
import {
  useGetAnagraficheQuery,
  useGetPreventiviQuery,
  useGetUsersQuery,
  usePutNotificationMutation,
} from "../../features/api/apiSlice";
import { Preventivo } from "../../queries/preventivi";
import { DateTimePicker } from "@mantine/dates";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import {
  IconAlertCircle,
  IconArrowBack,
  IconCircleCheck,
} from "@tabler/icons-react";
import { Notification } from "../../queries/notification";
import { User } from "../../features/users/UsersSlice";
import { Controller, useForm } from "react-hook-form";
import { TS4Display } from "../../utils/formatters";
import { transformTimestampToLocaleDateAndTime } from "../../utils/utils";
import { Anagrafica } from "../../queries/anagrafica";

interface NotificationFormProps {
  currentNotification?: Notification;
  authUser: User | null;
  preventivoId?: string;
  customerId?: string;
  duplicate?: boolean;
}

const NotificationForm = ({
  currentNotification,
  authUser,
  preventivoId,
  customerId,
  duplicate,
}: NotificationFormProps) => {
  const { data: preventiviData } = useGetPreventiviQuery({});
  const { data: usersData } = useGetUsersQuery({});
  const { data: customersData } = useGetAnagraficheQuery({ limit: 200 });
  const [putNotification] = usePutNotificationMutation();
  const dateTime = currentNotification
    ? TS4Display(currentNotification.alert_at)
    : Date.now();

  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      id_preventivo: currentNotification
        ? currentNotification?.id_preventivo
        : preventivoId
          ? preventivoId
          : null,
      id_cliente: currentNotification
        ? currentNotification?.id_cliente
        : customerId
          ? customerId
          : null,
      nome_cliente: currentNotification && currentNotification.nome_cliente,
      alert_at: new Date(dateTime),
      text: currentNotification ? currentNotification.text : "",
      id_agente_to: authUser?._id,
    },
  });

  const selectedPreventivoId = watch("id_preventivo");
  const selectedCustomerId = watch("id_cliente");
  const selectCustomer =
    preventiviData?.preventivis.find(
      (preventivo) => preventivo._id === selectedPreventivoId,
    )?.cliente?._id || selectedCustomerId;

  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      _id:
        currentNotification && !duplicate ? currentNotification._id : undefined,
      alert_at: data.alert_at.getTime(),
      nome_agente_from: authUser?.custom_data.name,
      id_agente_from: authUser?._id,
      nome_agente_to: usersData?.users.find(
        (user: User) => user._id === data.id_agente_to,
      )?.custom_data.name,
      id_preventivo: selectedPreventivoId || null,
      id_cliente: selectedPreventivoId
        ? selectCustomer
        : selectedCustomerId || null,
      nome_cliente: selectedPreventivoId
        ? preventiviData?.preventivis.find(
            (preventivo) => preventivo._id === selectedPreventivoId,
          )?.cliente?.nome
        : customersData?.anagraficas.find(
            (customer) => customer._id === selectedCustomerId,
          )?.nome || null,
    };

    putNotification(payload)
      .unwrap()
      .then((payload) => {
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: "Successo!",
          radius: "md",
          message:
            !currentNotification && !duplicate
              ? "Notifica creata."
              : duplicate
                ? "Notifica duplicata"
                : "Notifica modificata.",
          color: "teal",
          loading: false,
        });
        modals.closeAll();
      })
      .catch((error: Error) => {
        console.error(error);
        notifications.show({
          withCloseButton: true,
          autoClose: 4000,
          title: "Successo!",
          radius: "md",
          message:
            !currentNotification && !duplicate
              ? "Errore nella creazione della notifica. Riprova."
              : duplicate
                ? "Errore nella duplicazione della notifica. Riprova."
                : "Errore nella modifica della notifica. Riprova.",
          color: "pink",
          loading: false,
        });
      });
  };

  const notificationIsDismissed = currentNotification?.dismissed_at !== null;

  const notificationFormTitle = currentNotification
    ? "Modifica notifica"
    : "Nuova notifica";

  const handleComplete = () => {
    if (currentNotification) {
      const isDismissed = currentNotification.dismissed_at;
      putNotification({
        ...currentNotification,
        dismissed_at: isDismissed ? 0 : Date.now(),
      })
        .unwrap()
        .then(() => {
          notifications.show({
            withCloseButton: true,
            autoClose: 4000,
            title: "Successo!",
            radius: "md",
            message: "Notifica aggiornata.",
            color: "teal",
            loading: false,
          });
          modals.closeAll();
        })
        .catch((error: Error) => {
          console.error(error);
          notifications.show({
            withCloseButton: true,
            autoClose: 4000,
            title: "Successo!",
            radius: "md",
            message: "Errore nell'aggiornamento della notifica. Riprova.",
            color: "pink",
            loading: false,
          });
        });
    }
  };

  const currentPreventivo = preventiviData?.preventivis.find(
    (preventivo) => preventivo._id === currentNotification?.id_preventivo,
  );

  return (
    <ModalLayout
      title={
        <div className="item-center flex gap-1 font-medium">
          <IconAlertCircle />
          {notificationFormTitle}
        </div>
      }
      notificationButtonLabel={
        !notificationIsDismissed ? "Completa" : "Riattiva"
      }
      notificationButtonLeftSection={
        !notificationIsDismissed ? (
          <IconCircleCheck size={18} />
        ) : (
          <IconArrowBack size={18} />
        )
      }
      toggleNotificationStatus={handleComplete}
      currentUserName={authUser?.custom_data.name}
      onCloseClick={() => modals.closeAll()}
      onConfirmClick={handleSubmit(onSubmit)}
    >
      <>
        {currentPreventivo && (
          <div className="mb-4 rounded-xl bg-slate-100 px-4 py-2">
            <p className="mb-2 text-sm font-semibold">Dettagli preventivo</p>
            <div className="flex items-start justify-between gap-2">
              <div>
                <span className="mb-1 block text-xs">
                  <strong>Data: </strong>
                  {transformTimestampToLocaleDateAndTime(
                    currentPreventivo.first_saved_at,
                    false,
                  )}
                </span>
                <span className="mb-1 block text-xs">
                  <strong>Cliente: </strong>
                  {currentPreventivo.cliente?.nome}
                </span>
                <span className="block text-xs">
                  <strong>Veicolo: </strong>
                  {currentPreventivo.veicolo?.description}
                </span>
              </div>
              <div>
                <span className="text-xs">
                  <strong>Totale: </strong>
                  <NumberFormatter
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    value={currentPreventivo.price_total}
                  />
                  €
                </span>
              </div>
            </div>
          </div>
        )}
        <form>
          <div className="flex items-center gap-3">
            <Controller
              name="id_preventivo"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Select
                  {...field}
                  clearable
                  label="Preventivo"
                  className="mb-5 w-full"
                  placeholder="Seleziona un preventivo"
                  disabled={
                    (selectedCustomerId && !selectedPreventivoId) ||
                    (selectedCustomerId && selectedPreventivoId === "null")
                      ? true
                      : false
                  }
                  data={
                    preventiviData?.preventivis?.map(
                      (preventivo: Preventivo) => {
                        return {
                          value: preventivo._id || "",
                          label: `${preventivo._id}`,
                        };
                      },
                    ) || []
                  }
                  styles={{ input: { height: 44 } }}
                  radius={12}
                />
              )}
            />
            <Controller
              name="id_cliente"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Cliente"
                  className="mb-5 w-full"
                  placeholder="Seleziona un cliente"
                  disabled={
                    selectedPreventivoId && selectedPreventivoId !== "null"
                      ? true
                      : false
                  }
                  clearable
                  data={
                    customersData?.anagraficas?.map(
                      (anagrafica: Anagrafica) => {
                        return {
                          value: anagrafica._id || "",
                          label: `${anagrafica.nome}`,
                        };
                      },
                    ) || []
                  }
                  value={
                    selectedPreventivoId ? selectCustomer : selectedCustomerId
                  }
                  styles={{ input: { height: 44 } }}
                  radius={12}
                />
              )}
            />
          </div>
          <div className="flex items-center gap-3">
            <Controller
              name="alert_at"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  label="Data e ora"
                  placeholder="Data e ora"
                  error={errors.alert_at ? true : false}
                  className="mb-5 w-full"
                  styles={{ input: { height: 44 } }}
                  radius={12}
                />
              )}
            />
            <Controller
              name="id_agente_to"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Destinatario"
                  placeholder="Destinatario"
                  className="mb-5 w-full"
                  data={
                    usersData?.users.map((user: User) => {
                      return {
                        value: user._id || "",
                        label: user.custom_data.name,
                      };
                    }) || []
                  }
                  styles={{ input: { height: 44 } }}
                  radius={12}
                />
              )}
            />
          </div>
          <Controller
            name="text"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Textarea
                autosize={true}
                minRows={5}
                {...field}
                label="Note"
                placeholder="Note"
                error={errors.text ? true : false}
                radius={12}
              />
            )}
          />
        </form>
      </>
    </ModalLayout>
  );
};

export default NotificationForm;
