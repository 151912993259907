import TableWrapper from "../../Layout/TableWrapper/TableWrapper"
import CustomerForm from "../../components/Form/CustomerForm"
import CustomersTable from "../../components/Table/CustomersTable"
import useAuthUserData from "../../hooks/useAuthUserData"
import TableActionsWrapper from "../../Layout/TableActionsWrapper/TableActionsWrapper"
import { modals } from "@mantine/modals"
import { Button, Flex } from "@mantine/core"
import {
  IconAddressBook,
  IconFileExport,
  IconFileImport,
} from "@tabler/icons-react"

export default function Clienti() {
  const { authUserName } = useAuthUserData()
  return (
    <>
      <TableActionsWrapper>
        <Flex gap={20} justify="start" className="w-full">
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconAddressBook size={18} />}
            onClick={() =>
              modals.open({
                padding: 0,
                centered: true,
                withCloseButton: false,
                size: "70%",
                radius: 26,
                children: (
                  <CustomerForm
                    currentUserName={authUserName}
                    onCloseClick={() => modals.closeAll()}
                  />
                ),
              })
            }
          >
            Nuovo cliente
          </Button>
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconFileExport size={18} />}
          >
            Esporta CSV
          </Button>
          <Button
            variant="outline"
            radius="xl"
            leftSection={<IconFileImport size={18} />}
          >
            Importa CSV
          </Button>
        </Flex>
      </TableActionsWrapper>
      <TableWrapper>
        <CustomersTable />
      </TableWrapper>
    </>
  )
}
