import { Button } from "@mantine/core"
import { Option } from "../../queries/veicoli"
import { OptionalsSummary } from "../Preventivo/Summaries/OptionsSummary"
import { IconCheck, IconEdit } from "@tabler/icons-react"

interface OptionPriceEdit extends Partial<OptionalsSummary> {
  optional: Option
  onEditPrice: (id: string) => void
  onConfirmPrice: () => void
}

const OptionPriceEdit = ({
  optional,
  isInputShown,
  isOptionReadOnly,
  onEditPrice,
  onConfirmPrice,
}: OptionPriceEdit) => {
  const handleConfirmClick = () => {
    onConfirmPrice()
  }

  const handleEditClick = (id: string) => {
    onEditPrice(id)
  }

  return (
    <span>
      {isInputShown?.state &&
      isInputShown?.id === optional.id &&
      optional.is_updatable &&
      !isOptionReadOnly ? (
        <Button
          p={4}
          radius={999}
          w={24}
          h={24}
          color="green"
          onClick={handleConfirmClick}
        >
          <IconCheck className="size-4" />
        </Button>
      ) : (
        !isOptionReadOnly &&
        optional.is_updatable && (
          <Button
            p={4}
            onClick={() => handleEditClick(optional.id)}
            radius={999}
            w={24}
            h={24}
          >
            <IconEdit className="size-4" />
          </Button>
        )
      )}
    </span>
  )
}

export default OptionPriceEdit
