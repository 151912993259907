import { modals } from "@mantine/modals"
import { Button } from "@mantine/core"
import { IconUserPlus } from "@tabler/icons-react"
import UsersTable from "../../components/Table/UsersTable"
import UserForm from "../../components/Form/UserForm"
import TableWrapper from "../../Layout/TableWrapper/TableWrapper"
import useAuthUserData from "../../hooks/useAuthUserData"
import TableActionsWrapper from "../../Layout/TableActionsWrapper/TableActionsWrapper"

const Users = () => {
  const { authUserName } = useAuthUserData()

  return (
    <>
      <TableActionsWrapper>
        <Button
          variant="outline"
          radius="xl"
          leftSection={<IconUserPlus size={18} />}
          onClick={() => {
            modals.open({
              padding: 0,
              centered: true,
              withCloseButton: false,
              size: "70%",
              radius: 26,
              children: (
                <UserForm
                  currentUserName={authUserName}
                  onCloseClick={() => modals.closeAll()}
                />
              ),
            })
          }}
        >
          Nuovo utente
        </Button>
      </TableActionsWrapper>
      <TableWrapper>
        <UsersTable />
      </TableWrapper>
    </>
  )
}

export default Users
