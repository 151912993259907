import SummaryBox from "../SummaryBox/SummaryBox";
import OptionsSummary from "../Summaries/OptionsSummary";
import { useState } from "react";
import { Autocomplete } from "@mantine/core";
import { useGetOptionalsQuery } from "../../../features/api/apiSlice";
import { EOptionType, OptionalsQuery } from "../../../queries/veicoli";
import { optionalsUnique, optionsFilter } from "../../../utils/utils";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  addOption,
  selectListino,
  selectOptions,
  selectVeicolo,
} from "../../../features/preventivo/preventivoSlice";

interface ComponentProps {
  type: EOptionType;
  isReadOnly?: boolean;
  selectLabel?: string;
  summaryLabel: string;
  summaryPrice?: number;
  isOptionReadOnly?: boolean;
}

const OptionSelectComponent = ({
  type,
  isReadOnly,
  selectLabel,
  summaryLabel,
  isOptionReadOnly,
}: ComponentProps) => {
  const dispatch = useAppDispatch();
  const veicolo = useAppSelector(selectVeicolo);
  const listino = useAppSelector(selectListino);
  const currentOptions = useAppSelector(selectOptions);
  const currentItems = currentOptions.filter(({ cm_type }) => cm_type === type);
  const [inputValue, setInputValue] = useState("");
  const disabled = !veicolo || !listino;

  let optionalsQuery: OptionalsQuery = { limit: 2000, type };
  if (
    type !== EOptionType.ACCESSORI &&
    type !== EOptionType.SERVIZI &&
    type !== EOptionType.INCENTIVI
  ) {
    optionalsQuery = {
      ...optionalsQuery,
      veicolo: veicolo?.id || "",
      listino: listino || "",
    };
  }

  const { data, isLoading } = useGetOptionalsQuery(optionalsQuery);

  const availableOptions = data?.optionals
    ? optionalsUnique(data.optionals)
        .filter(
          ({ cm_type, obbligatorio, id }) =>
            cm_type === type &&
            obbligatorio !== "true" &&
            !currentItems.some((item) => item.id === id),
        )
        .map(({ label, id, price }) => ({
          value: id,
          label: `${label} (${price} €)`,
        }))
    : [];

  const handleOptionSubmit = (selectedItem: string) => {
    const selected = data?.optionals.find((opt) => opt.id === selectedItem);

    if (selected) {
      dispatch(
        addOption({
          ...selected,
        }),
      );
    }

    setTimeout(() => {
      setInputValue("");
    });
  };

  return (
    <SummaryBox withToggle label={summaryLabel}>
      <div className="preventivo-optionals relative mb-8 w-full">
        {!isReadOnly && (
          <Autocomplete
            mb={20}
            radius={12}
            className="preventivo-optionals__select bg-slate-100 w-[calc(100%+64px)] -ml-8 p-8 -mt-8"
            label={selectLabel}
            placeholder={selectLabel}
            data={availableOptions}
            value={inputValue}
            onChange={setInputValue}
            onOptionSubmit={handleOptionSubmit}
            styles={{
              input: { minHeight: 44 },
              dropdown: { maxHeight: 455 },
            }}
            filter={optionsFilter}
            disabled={disabled}
          />
        )}
        <OptionsSummary
          type={type}
          items={currentItems}
          isReadOnly={isReadOnly}
          isOptionReadOnly={isOptionReadOnly}
        />
      </div>
    </SummaryBox>
  );
};

export default OptionSelectComponent;
