import { gql } from "@apollo/client";
import { BSON } from "realm-web";
import { TS4Save } from "../utils/formatters";
import { Anagrafica } from "./anagrafica";
import { Option, Veicolo } from "./veicoli";
import { EPreventivoStatus } from "../features/preventivo/preventivoSlice";

export interface Permuta {
  title: string;
  targa: string;
  km: string;
  year: string;
  price: number;
  description?: string;
  iva_type?: EIvaType;
}

export interface PaymentType {
  label: string;
  description?: string;
  sconti?: Array<Sconto>;
  servizi?: Array<Option>;
}

export enum EScontoType {
  VEICOLO = "F",
  ACCESSORI = "A",
  SERVIZI = "S",
  PREZZO_FINALE = "G",
  //ACQUISTI_MULTIPLI = "M",
}
export enum EIvaType {
  IVA_ESCLUSA = "ESCLUSA",
  ESENTE_IVA = "ESENTE",
}

export const PAYMENTS_TYPES = [
  {
    label: "Bonifico",
    description: "Bonifico",
  },
  {
    label: "IDEA FORD (Sconto 1k)",
    description: "IDEA FORD (Sconto 1k)",
    sconti: [
      {
        label: "Sconto Finanziamento",
        price: Math.round(1000 * 100) / 100,
        type: EScontoType.VEICOLO,
        rif_pagamento: "IDEA FORD (Sconto 1k)",
      },
    ],
    servizi: [
      {
        id: "0",
        label: "Gestione pratica fin.",
        price: Math.round(200 * 100) / 100,
        description: "Gestione pratica fin.",
        cm_type: "S",
        rif_pagamento: "IDEA FORD (Sconto 1k)",
      },
    ],
  },
];

export interface Sconto {
  label: string;
  price: number;
  type: EScontoType;
  description?: string;
  rif_pagamento?: string;
}

export interface Agente {
  _id: string;
  name: string;
  email?: string;
  role?: string;
  tel?: string;
}

export enum OPTIONS_PRINT_TYPE {
  TP = "total_price",
  SP = "single_price",
  NP = "no_price",
}

export interface PrintOptions {
  show_iva: string;
  print_type: OPTIONS_PRINT_TYPE;
}
export interface Preventivo {
  _id?: string;
  temperatura?: string;
  veicolo?: Veicolo;
  agente?: Agente;
  cliente?: Anagrafica;
  options: Array<Option>;
  permute: Array<Permuta>;
  price_total?: number;
  sconto_temp: Sconto;
  sconti: Array<Sconto>;
  status: EPreventivoStatus;
  anticipo?: number | string;
  started_at: number;
  last_modified_at?: number;
  first_saved_at: number;
  data_validita?: number;
  last_printed_at?: number;
  last_sent_at?: number;
  front_date?: number;
  print_options: PrintOptions;
  pagamento: string;
  notes?: string;
  iva: number;
  oggetto?: string;
  modello?: string;
  motore?: string;
  dimensioni?: string;
  dotazioni_guida?: string;
  dotazioni_sicurezza?: string;
  dotazioni_interne?: string;
  dotazioni_tecnologia?: string;
  caratteristiche_versione?: string;
  custom_text_1?: string;
}
export interface GetPreventiviResponse {
  preventivis: Preventivo[];
}

export interface GetPreventivoResponse {
  preventivi: Preventivo;
}

export interface PreventiviQuery {
  limit?: number;
  idCliente?: string;
  idAgente?: string;
}

export interface PreventivoQuery {
  id: string;
}
export interface PreventivoPdfProps {
  preventivoId?: string;
  preventivo: Preventivo;
  optionals?: Option[];
  accessori?: Option[];
  servizi?: Option[];
  optionalTotal: number[];
  accessoriTotal: number[];
  serviziTotal: number[];
  isIconOnly?: boolean;
}

export const queryPreventivi = ({
  limit,
  idCliente,
  idAgente,
}: PreventiviQuery) => ({
  document: gql`
    query (
      $cliente: PreventiviClienteQueryInput
      $id_agente: String
      $limit: Int
    ) {
      preventivis(
        limit: $limit
        sortBy: FIRST_SAVED_AT_DESC
        query: { cliente: $cliente, agente: { _id: $id_agente } }
      ) {
        _id
        cliente {
          _id
          nome
          email
          prov
          tel
        }
        agente {
          name
        }
        veicolo {
          id
          description
          model
          margine_extra
          margine_agg
          margine_fin
          margine_perm
        }
        print_options {
          show_iva
          print_type
        }
        price_total
        started_at
        first_saved_at
        temperatura
        data_validita
        notes
        oggetto
        modello
      }
    }
  `,
  variables: {
    limit: limit || 500,
    cliente: idCliente && {
      _id: new BSON.ObjectID(idCliente),
    },
    id_agente: idAgente || null,
  },
});

export const queryPreventivo = ({ id }: PreventivoQuery) => ({
  document: gql`
    query {
      preventivi(query: {_id: "${new BSON.ObjectID(id)}"}) {
        _id
        anticipo
        pagamento
        notes
        oggetto
        modello
        started_at
        first_saved_at
        data_validita
        front_date
        temperatura
        motore
        dotazioni_guida
        dotazioni_sicurezza
        dotazioni_interne
        dotazioni_tecnologia
        caratteristiche_versione
        custom_text_1
        dimensioni
        agente {
          _id
          name
          email
        }
        cliente {
          _id
          cap
          cf
          civico
          cod_master
          email
          id_anagrafica
          indirizzo
          localita
          nazione
          nome
          pec
          piva
          prov
          tel
          tipologia
          valuta
          cortese_attenzione
        }
        veicolo {
          id
          description
          listino
          model
          optionals
          price
          engine
          portata
          altezza
          lunghezza
          cv
          kw
          ps
          cambio
          dimensioni
          dotazioni_assistenza_alla_guida
          dotazioni_interne
          dotazioni_sicurezza
          dotazioni_tecnologia
          modello_modelyear
          modello_tipologia
          modello_veicolo
          modello_versione
          motore
          dimensioni
          pc_tipologia_hl
          caratteristiche_versione
          margine_base
          margine_extra
          margine_agg
          margine_fin
          margine_perm
        }
        permute {
          description
          price
          title
          targa
          km
          year
          iva_type
        }
        price_total
        model {
          label
          value
        }
        options {
          id
          label
          cm_type
          veicolo
          listino
          price
          rif_pagamento
          margine_base
          description
          fornitore
          categoria
          obbligatorio
          SoggettoAdIva
          is_updatable
        }
        sconti {
          description
          label
          price
          type
          rif_pagamento
        }
        print_options {
          show_iva
          print_type
        }
      }
    }
  `,
});

export const mutationPreventivo = (preventivo: Preventivo) => ({
  document: gql`
    mutation (
      $veicolo: PreventiviVeicoloInsertInput, 
      $agente: PreventiviAgenteInsertInput, 
      $cliente: PreventiviClienteInsertInput, 
      $permute: [PreventiviPermuteInsertInput],
      $options: [PreventiviOptionInsertInput],
      $sconti: [PreventiviScontiInsertInput],
      $date_start: Int,
      $date_save: Int,
      $print_options: PreventiviPrint_optionInsertInput
    ) {
      upsertOnePreventivi(
        data: {
          price_total: ${preventivo.price_total},
          veicolo: $veicolo,
          cliente: $cliente,
          agente: $agente,
          permute: $permute,
          options: $options,
          print_options: $print_options,
          sconti: $sconti,
          front_date: $date_save,
          date_sale: 0,
          data_validita: ${TS4Save(preventivo.data_validita)},
          date_delivery: 0,
          anticipo: ${preventivo.anticipo},
          pagamento: "${preventivo.pagamento}",
          status: "${preventivo.status}",
          notes: "${preventivo.notes}",
          oggetto: "${preventivo.oggetto}",
          modello: "${preventivo.modello}",
          started_at: $date_start,
          first_saved_at: $date_save,
          last_modified_at: ${TS4Save(Date.now())},
          iva: ${preventivo.iva},
          temperatura: "${preventivo.temperatura}",
          motore: "${preventivo.motore}",
          dimensioni: "${preventivo.dimensioni}",
          dotazioni_guida: "${preventivo.dotazioni_guida}",
          dotazioni_sicurezza: "${preventivo.dotazioni_sicurezza}",
          dotazioni_interne: "${preventivo.dotazioni_interne}",
          dotazioni_tecnologia: "${preventivo.dotazioni_tecnologia}",
          caratteristiche_versione: "${preventivo.caratteristiche_versione}",
          custom_text_1: "${preventivo.custom_text_1}",
        },
        query: {
          _id: "${preventivo._id ? new BSON.ObjectID(preventivo._id) : new BSON.ObjectID()}"
        }) {
        _id
      }
    }
  `,
  variables: {
    model: preventivo.veicolo?.model,
    veicolo: {
      id: preventivo.veicolo?.id,
      description: preventivo.veicolo?.description,
      listino: preventivo.veicolo?.listino,
      price: preventivo.veicolo?.price,
      engine: preventivo.veicolo?.engine,
      optionals: preventivo.veicolo?.optionals,
      model: preventivo.veicolo?.model,
      portata: preventivo.veicolo?.portata,
      altezza: preventivo.veicolo?.altezza,
      lunghezza: preventivo.veicolo?.lunghezza,
      cv: preventivo.veicolo?.cv,
      kw: preventivo.veicolo?.kw,
      ps: preventivo.veicolo?.kw,
      cambio: preventivo.veicolo?.cambio,
      dimensioni: preventivo.veicolo?.dimensioni,
      dotazioni_assistenza_alla_guida:
        preventivo.veicolo?.dotazioni_assistenza_alla_guida,
      dotazioni_interne: preventivo.veicolo?.dotazioni_interne,
      dotazioni_sicurezza: preventivo.veicolo?.dotazioni_sicurezza,
      dotazioni_tecnologia: preventivo.veicolo?.dotazioni_tecnologia,
      modello_modelyear: preventivo.veicolo?.modello_modelyear,
      modello_tipologia: preventivo.veicolo?.modello_tipologia,
      modello_veicolo: preventivo.veicolo?.modello_veicolo,
      modello_versione: preventivo.veicolo?.modello_versione,
      motore: preventivo.veicolo?.motore,
      pc_tipologia_hl: preventivo.veicolo?.pc_tipologia_hl,
      caratteristiche_versione: preventivo.veicolo?.caratteristiche_versione,
      margine_base: preventivo.veicolo?.margine_base,
      margine_extra: preventivo.veicolo?.margine_extra,
      margine_agg: preventivo.veicolo?.margine_agg,
      margine_fin: preventivo.veicolo?.margine_fin,
      margine_perm: preventivo.veicolo?.margine_perm,
    },
    cliente: preventivo.cliente,
    agente: preventivo.agente,
    permute: preventivo.permute,
    options: preventivo.options,
    print_options: preventivo.print_options,
    sconti: preventivo.sconti,
    date_start: preventivo.started_at ? TS4Save(preventivo.started_at) : 0,
    date_save: preventivo.first_saved_at
      ? TS4Save(preventivo.first_saved_at)
      : TS4Save(Date.now()),
    show_iva: Boolean(preventivo.print_options.show_iva),
  },
});

export const deletePreventivi = (ids: string[]) => ({
  document: gql`
    mutation {
      deleteManyPreventivis (
        query: {
          _id_in: [${ids.map((id) => `"${new BSON.ObjectID(id)}"`).join(",")}]
        }
      ) {deletedCount}
    } 
  `,
});
