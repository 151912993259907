import { BlobProvider } from "@react-pdf/renderer"
import PreventivoPdfRenderer from "../../PDFGenerator/PreventivoPdfRenderer/PreventivoPdfRenderer"
import usePreventivoData from "../../../hooks/usePreventivoData"
import PdfButton from "../PdfButton/PdfButton"

const PdfProvider = ({
  isIconOnly,
  preventivoId,
}: {
  isIconOnly: boolean
  preventivoId: string
}) => {
  const preventivoData = usePreventivoData({
    preventivoId,
  })

  return (
    <BlobProvider
      document={<PreventivoPdfRenderer preventivoData={preventivoData} />}
    >
      {({ url, loading, error }) => (
        <PdfButton
          url={url}
          error={error}
          isIconOnly={isIconOnly}
          loading={loading}
        />
      )}
    </BlobProvider>
  )
}

export default PdfProvider
